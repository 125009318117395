<template>
  <div>
    <div class="page">
      <div class="title">B2B Order</div>
      <div class="orderTime"
        >Order Time: <div>{{ arr.orderTime }}</div>
      </div>
      <div class="header">
        <h2>Employee Details </h2>
        <div class="info">Sales Associate Name : {{ arr.name }}</div>
        <div class="info">Employee Id : {{ arr.Id }}</div>
        <div class="info">Customer Mobile : {{ arr.customerMobile }}</div>
        <div class="info">Customer Name : {{ arr.customerName }}</div>
        <div class="info">Customer Address : {{ arr.customerAddress }}</div>
        <div class="info">GST Number : {{ arr.gstNumber }}</div>
      </div>
      <div class="table-container">
      <table class="ctr ">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Products</th>
            <th>Article Code</th>
            <th>Quantity</th>
            <th>&nbsp;&nbsp; Mrp&nbsp;&nbsp; </th>
            <th>&nbsp;&nbsp; SP&nbsp;&nbsp; </th>
            <th>Cart Value</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(product, index) in products" :key="index">
            <td class="item">{{ index + 1 }}</td>
            <td>
              <div class="products">
                <div class="productitem">
                  {{ product }}
                </div>
              </div>
            </td>
            <td>
              <div class="articleCode">
                <div class="item">{{ String(articleCode[index]).slice(-7) }}</div>
              </div>
            </td>
            <td>
              <div class="quantity">
                <div class="item">{{ quantity[index] }}</div>
              </div>
            </td>
            <td>
              <div class="amount">
                <div class="sellingPriceItem">{{ mrp[index] }}</div>
              </div>
            </td>
            <td>
              <div class="sellingPrice">
                <div class="sellingPriceItem">{{ sellingPrice[index] }}</div>
              </div>
            </td>
            <td>
              <div class="cartValue">
                <div class="item">{{ cartValue[index].toFixed(1) }}</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PromoTemplateA4',
  props: {
    data: Object
  },
  data() {
    return {
      arr: {},
      products: [],
      posItems: [],
      quantity: [],
      articleCode: [],
      mrp: [],
      cartValue: [],
      sellingPrice: []
    };
  },
  watch: {
    data(newValue) {
      this.posItems = newValue.posItems;
      this.arr.name = newValue.salesAssociateName;
      this.arr.Id = newValue.employeeId;
      this.arr.customerMobile = newValue.customerMobile;
      this.arr.customerName = newValue.customerName;
      this.arr.customerAddress = newValue.address;
      this.arr.gstNumber = newValue.gstNumber;
      this.arr.orderTime = newValue.createdAt;
      this.products = newValue.products.split(',');
      this.quantity = newValue.quantity.split(',');
      this.articleCode = newValue.articleCode.split(',');
      this.mrp = newValue.mrp.split(',');
      this.sellingPrice = newValue.sellingPrice.split(',');
      this.cartValue = this.sellingPrice?.map((sellingPrice, index) => sellingPrice * this.quantity[index]);
      // this.quantity = newValue.quantity.split(',');
    }
  }
};
</script>
<style></style>
