<template>
  <div class="page-class page-user-list">
    <h1 class="page-title">{{ title }}</h1>
    <outbound-blocked-list-box
      :columns="columns"
      :showUpload="ifAdmin()"
      :rows="blackListNumber"
      :loading="loading"
      :searchItems="searchItems"
      :showDownload="true"
      :pagination="pagination"
      :showPagination="true"
      :showFilter="false"
      :showToolbar="true"
      :showRemoveNumber="true"
      emptyText="No Data found."
      downloadText="Download List"
      uploadText="Upload Numbers"
      listText="Remove Numbers From Outbound Callers List"
      listUrl="/outboundCallerList"
      @delete="onDelete"
      @show-upload="onUpload"
      @download="onExportCSV"
      @remove-numbers="removeNumbers"
      @single-number="addSingleNumber"
    >
    </outbound-blocked-list-box>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// import moment from 'moment';
import router from '@/router';
import Vue from 'vue';
import csv from 'csvtojson';

import _ from 'lodash';
import OutboundBlockedListBox from '../../components/OutboundBlockedListBox.vue';
// import moment from 'moment';

export default {
  name: 'BlockedNumberList',
  components: {
    OutboundBlockedListBox
  },
  metaInfo() {
    return {
      title: 'Blocked Number List',
      meta: [
        {
          name: 'description',
          content: `Manage Blocked Caller List`
        }
      ]
    };
  },
  data() {
    return {
      searchItems: ['Mobile'],
      title: 'Blocked Number List',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'rowNum',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Mobile number',
          class: { 'text-center': true },
          textKey: 'mobile',
          width: '30%'
        },
        {
          type: 'string',
          headerText: 'Uploaded By',
          class: { 'text-center': true },
          textKey: 'createdByName',
          width: '20%'
        },
        {
          type: 'string',
          headerText: 'Uploaded At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '20%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '20%',
          functions: {
            delete: true
          }
        }
      ]
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.blacklist({ query: to.query });
    next();
  },
  mounted() {
    this.listStore({ router });
    if (_.isEmpty(this.$route.query)) {
      this.blacklist({
        query: {
          ...this.$route.query
        }
      });
    } else {
      this.blacklist({ query: this.$route.query });
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('outboundCaller', [
      'outboundCallerList',
      'pagination',
      'loading',
      'downloadBlackListNumbers',
      'baseUrl',
      'uploadLoading',
      'blackListNumber'
    ])
  },
  methods: {
    ...mapActions('outboundCaller', [
      'blacklist',
      'uploadBlacklist',
      'deleteOne',
      'downloadBlacklistNumber',
      'removeFromList'
    ]),
    ...mapActions('store', ['listStore']),
    ifAdmin() {
      const isAdmin = this.user.role === 99;
      if (isAdmin) {
        return true;
      }
      return false;
    },
    removeNumbers() {
      this.removeFromList();
    },

    onDelete({ row }) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',

        preConfirm: () => {
          this.deleteOne({
            id: row.id
          });
        }
      });
    },
    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query)) {
        this.downloadBlacklistNumber({ query: { ...this.$route.query } });
      } else {
        this.downloadBlacklistNumber({
          query: {
            ...this.$route.query
          }
        });
      }
    },
    createCsv() {
      const csv = `Mobile,\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'outboundBlackListNumbers.csv';
      anchor.click();
    },
    async onUpload() {
      await Vue.swal({
        title: 'Upload file',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close'
      }).then(result => {
        if (result.isDenied) {
          this.createCsv();
        }
        if (result.value) {
          if (result.value.name === 'outboundBlackListNumbers.csv') {
            const reader = new FileReader();
            reader.onload = async () => {
              csv({
                noheader: true,
                output: 'csv'
              })
                .fromString(reader.result)
                .then(async csvRow => {
                  const inputs = csvRow.slice(1, csvRow.length).map(i => {
                    const values = i.reduce((acc, curr, index) => {
                      if (index === 0) {
                        if (i[0].length !== 10) {
                          Vue.swal({
                            title: 'Access Denied',
                            text: `Mobile Number ${i[0]} must be 10 digits`,
                            type: 'error',
                            confirmButtonText: 'Ok',
                            icon: 'error'
                          });
                          return null;
                        }
                        acc.mobile = curr;
                      }

                      return acc;
                    }, {});
                    return values;
                  });
                  this.uploadBlacklist({ data: inputs, router });
                });
            };
            reader.readAsBinaryString(result.value);
          } else {
            Vue.swal({
              title: 'Access Denied',
              text: 'Please upload correct file',
              type: 'error',
              confirmButtonText: 'Ok',
              icon: 'error'
            });
          }
        }
      });
    },
    async addSingleNumber(mobile) {
      this.uploadBlacklist({ data: mobile, router });
    }
  },
  // outboundBlocked/removeFromCallerList
  watch: {
    downloadBlackListNumbers(newValue) {
      if (newValue.length) {
        let csv = 'Row Num,Mobile,Uploaded By\n';
        newValue.forEach(row => {
          csv += `${row.rowNum},${row.mobile},${row.createdByName}\n`;
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'BlackListed Numbers.csv';
        anchor.click();
      }
    }
  }
};
</script>

<style scoped></style>
