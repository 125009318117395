<template>
  <div class="page-class page-user-list">
    <h1 class="page-title">{{ title }}</h1>
    <table-box-order
      :columns="columnsToShow"
      :rows="ecomOrderList"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="true"
      :showDownloadOnline="true && !ifCallCenterExecutive()"
      :showUpload="false"
      :showToolbar="true"
      :showPagination="true"
      :pagination="pagination"
      :filterOptions="filterOptions"
      :showOtherFilter="true"
      :sourceOptions="sourceOptions"
      :isTabsRequired="handleTabsRequired"
      :searchItems="searchItems"
      addText="Create new order"
      downloadTextOnline="Download Orders List"
      emptyText="No order found. Please add new order."
      @add="onAdd"
      @edit="onEdit"
      @acknowledge="onAcknowledge"
      @delete="onDelete"
      @download-online="onExportCSVfile"
      @show-upload="onShowUpload"
      @fetch-online="onFetchOnline"
    >
      <template v-slot:emailAddressFormatted="slotProps">
        <span class="span-text span-block">
          {{ slotProps.row.email }}
        </span>
        <template v-if="slotProps.row.confirmedAtFormatted">
          <span class="text-success span-help-text" :title="slotProps.row.confirmedAt">
            ({{ slotProps.row.confirmedAtFormatted }})
          </span>
        </template>
        <template v-if="slotProps.row.confirmedAtFormatted === null">
          <span class="text-warning span-help-text"> (Not confirmed) </span>
        </template>
      </template>
      <template v-slot:lastLoginAtFormatted="slotProps">
        <template v-if="slotProps.row.lastLoginAtFormatted">
          <span class="text-success">{{ slotProps.row.lastLoginAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.lastLoginAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
      <template v-slot:blockedAtFormatted="slotProps">
        <template v-if="slotProps.row.blockedAtFormatted">
          <span class="text-success">{{ slotProps.row.blockedAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.blockedAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
    </table-box-order>
    <div>
      <b-modal ref="order-Modal" id="modal-md" size="md" title="Store Authentication">
        <b-form data-v-step="2" inline class="float-left">
          <b-row>
            <b-col cols="12" class="d-flex justify-content-between align-items-center">
              <b-form-group id="group-products" label-for="input-products">
                <template v-slot:label> Store Id <span class="text-danger">*</span> </template>
                <multiselect
                  id="store-code"
                  v-model="selectedStoreId"
                  ref="multiselect"
                  placeholder="Select Store Ids"
                  :options="storeOptions"
                  class="ml-2 mb-2"
                  style="display: inline-block;"
                ></multiselect>
              </b-form-group>

              <p v-if="isOnlineStoreSelected" class="bpx-2 py-1 text-success rounded-lg d-flex align-items-center">
                Online Store
              </p>
            </b-col>
            <b-col cols="12" v-if="isOnlineStoreSelected">
              <b-form-group id="group-products" label-for="input-products">
                <label for="pin-code" style="display: inline-block;">Pin Code:</label>
                <b-form-input
                  id="pin-code"
                  placeholder="Enter Pin Code"
                  v-model="pinCode"
                  :formatter="limit"
                  class="ml-2 mb-2"
                  style="display: inline-block;"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <div class="m-2 text-danger" v-if="errorMessage !== undefined && errorMessage !== ''">
              **{{ errorMessage }}
            </div>
          </b-row>
        </b-form>
        <div slot="modal-footer" class="w-100 d-flex justify-content-end">
          <b-button variant="danger" class="ml-2 mb-2" :disabled="loading" @click="proceed()">
            <span class="spinner spinner-white" v-if="loading"></span>
            Proceed</b-button
          >
          <b-button variant="secondary" class="ml-2 mb-2" @click="handleCancel">Cancel</b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import _ from 'lodash';
import Multiselect from 'vue-multiselect';
import TableBoxOrder from '../../components/TableBoxOrder.vue';
import User from '../../model/user';

export default {
  name: 'OnlineOrder',
  components: {
    TableBoxOrder,
    Multiselect
  },
  metaInfo() {
    return {
      title: 'Online Orders',
      meta: [
        {
          name: 'description',
          content: `Manage orders`
        }
      ]
    };
  },
  mounted() {
    this.listStoreDetail({ router });
    if (_.isEmpty(this.$route.query)) {
      this.ecommOrderList({
        query: {
          ...this.$route.query,
          startdate: moment().format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.ecommOrderList({ query: this.$route.query });
    }
  },

  beforeRouteUpdate(to, from, next) {
    this.ecommOrderList({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Orders',
      searchItems: ['Customer Mobile', 'Name', 'Address', 'Store Id'],
      isOnlineStoreSelected: false,
      storeOptions: [],
      selectedStoreId: '',
      selectedStoreObj: {},
      isSingleStore: false,
      pinCode: '',
      isOnlineData: false,
      filterSelected: null,
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'DISPATCHED', text: 'DISPATCHED' },
        { value: 'IN PROGRESS', text: 'IN PROGRESS' },
        { value: 'OUT FOR DELIVERY', text: 'OUT FOR DELIVERY' },
        { value: 'CANCELLED', text: 'CANCELLED' },
        { value: 'DELIVERED', text: 'DELIVERED' },
        { value: 'REFUNDED', text: 'REFUNDED' }
      ],
      sourceOptions: [
        { value: null, text: 'Select Source' },
        { value: 'CALL_CENTER_ENTRY', text: 'CALL CENTER ENTRY' },
        { value: 'STORE_ENTRY', text: 'STORE ENTRY' }
      ]
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('auth', {
      authUser: 'user'
    }),
    ...mapState('order', [
      'loading',
      'baseUrl',
      'orders',
      'pagination',
      'errorMessage',
      'ecomOrderList',
      'downloadOrdersListOnline'
    ]),
    ...mapState('store', ['storeList', 'storeListDetail']),
    handleTabsRequired() {
      const storeIds = ['H049'];
      const logic = storeIds.some(store => this.user.storeids.includes(store));
      if (logic) {
        return true;
      }
      return false;
    },
    order() {
      return this.orders.map(i => {
        let status = '';
        if (i.actualDeliveredTime) {
          status = `Order Delivered: ${i.actualDeliveredTime}`;
        } else if (i.packagedTime) {
          status = `Order Packaged: ${i.packagedTime}`;
        } else if (i.posPingTime) {
          status = `Bill Generated:  ${i.posPingTime}`;
        } else if (i.acknowledgedTime) {
          status = `Acknowledged Time:  ${i.acknowledgedTime}`;
        } else {
          status = `Order Created: ${i.createdAt}`;
        }

        return {
          ...i,
          status
        };
      });
    },
    columnsToShow() {
      return [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'id',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Customer Mobile',
          class: { 'text-center': true },
          textKey: 'customerNumber',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Customer Name',
          class: { 'text-center': true },
          textKey: 'name',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Email',
          class: { 'text-center': true },
          textKey: 'customerEmail',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Address',
          class: { 'text-center': true },
          textKey: 'address',
          width: '21%'
        },
        {
          type: 'string',
          headerText: 'Order Total',
          class: { 'text-center': true },
          textKey: 'orderTotal',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Status',
          class: { 'text-center': true },
          textKey: 'orderStatus',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Created At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Source',
          class: { 'text-center': true },
          textKey: 'source',
          width: '10%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '9%',
          functions: {
            edit: true,
            ack: false
          }
        }
      ];
    },
    handleDisabled() {
      return this.selectedStoreId === '' || (this.isOnlineStoreSelected && this.pinCode.length < 6);
    }
  },
  watch: {
    downloadOrdersListOnline(newValue) {
      if (newValue.length) {
        const { startdate, enddate } = this.$route.query;
        let csv = '';
        if (startdate !== undefined && enddate !== undefined) {
          csv += `\n,Date From ${startdate} to ${enddate},\n\n`;
        } else {
          csv += `\n,Date From ${moment().format('YYYY-MM-DD')} to ${moment().format('YYYY-MM-DD')},\n\n`;
        }
        csv += 'Customer Mobile,Customer Name,Store ID,Email,Customer Address,Order Total,Status, Created At\n';
        newValue.forEach(row => {
          //  const formattedDistance = totalDistance != '-' ? `${totalDistance} km` : totalDistance; //eslint-disable-line
          csv += `"${row.customerNumber}","${row.name}","${row.storeid}","${row.customerEmail}","${row.address}","${row.orderTotal}","${row.orderStatus}","${row.createdAt}"\n`;
        });

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Online-Orders.csv';
        anchor.click();
      }
    },

    selectedStoreId(newValue, oldValue) {
      if (newValue !== oldValue) {
        const filteredStore = this.storeListDetail.filter(store => store.storeid === newValue)[0];
        this.selectedStoreObj = filteredStore;
        if (filteredStore?.isOnline) {
          this.isOnlineStoreSelected = true;
        } else {
          this.isOnlineStoreSelected = false;
        }
      }
    },
    filterSelected(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateFilter(newValue);
      }
    }
  },
  methods: {
    ...mapActions('order', [
      'list',
      'deleteOne',
      'upload',
      'sendAcknowledgement',
      'download',
      'downloadData',
      'getStoreAvailability',
      'setStorePinCode',
      'ecommOrderList'
    ]),
    ...mapActions('store', ['listStore', 'listStoreDetail']),

    onFetchOnline() {
      this.isOnlineData = true;
      this.ecommOrderList({
        query: {
          ...this.$route.query
        }
      });
    },
    ifCallCenterExecutive() {
      const newUser = this.user.roles.map(role => role.name);
      const CallCenter = newUser.filter(role => role.includes('CALL_CENTER_EXECUTIVE'));
      if (CallCenter.length > 0) {
        return true;
      }
      return false;
    },

    limit(e) {
      return e.slice(0, 6);
    },
    async onAdd() {
      // More that one store
      if (this.user?.storeids?.length > 1) {
        this.storeOptions = this.user.storeids;
        this.$refs['order-Modal'].show();
      } else {
        // single store
        this.selectedStoreId = this.user?.storeids[0];

        const storeAB = this.storeListDetail.filter(store => store.storeid === this.selectedStoreId)[0];
        if (storeAB?.isOnline) {
          this.$refs['order-Modal'].show();
        } else {
          this.$router.push(`/order/new/${null}`);
        }
      }
    },
    handleCancel() {
      this.$refs['order-Modal'].hide();
    },
    proceed() {
      if (this.selectedStoreObj.isOnline) {
        const storeid = this.selectedStoreId?.toLowerCase();
        const pincode = this.pinCode;

        this.getStoreAvailability({ storeid, pincode, router });
        this.setStorePinCode({ storeid, pincode });
        // this.$refs['order-Modal'].hide();
      } else {
        this.$router.push(`/order/new/${null}`);
      }
    },
    onEdit({ row }) {
      if (row.orderIncrementId !== undefined && row.orderIncrementId !== '') {
        router.push(`/orderFormBoxV2/${row.orderId}`);
      } else {
        router.push(`/order/${row.orderId}`);
      }
    },

    calculateAverageTime(timeArray) {
      const totalSeconds = timeArray.reduce((acc, time) => {
        const [hours, minutes, seconds] = time.split(':').map(Number);
        return acc + hours * 3600 + minutes * 60 + seconds;
      }, 0);
      const averageSeconds = totalSeconds / timeArray.length;
      const hours = Math.floor(averageSeconds / 3600);
      const minutes = Math.floor((averageSeconds % 3600) / 60);
      const seconds = Math.floor(averageSeconds % 60);
      const timeReturn = t => {
        return String(t).padStart(2, '0');
      };
      return `${timeReturn(hours)}:${timeReturn(minutes)}:${timeReturn(seconds)}`;
    },
    totalAverageTimeConverter(totalSeconds, length) {
      const averageSeconds = totalSeconds / length;
      const seconds = Math.floor((averageSeconds / 1000) % 60);
      const minutes = Math.floor((averageSeconds / (1000 * 60)) % 60);
      const hours = Math.floor(averageSeconds / (1000 * 60 * 60));
      const timeReturn = t => {
        return String(t).padStart(2, '0');
      };
      return `${timeReturn(hours)}:${timeReturn(minutes)}:${timeReturn(seconds)}`;
    },
    milliSecondsToHours(totalSeconds) {
      if (totalSeconds < 0) {
        return '-';
      }
      const averageSeconds = totalSeconds;
      const seconds = Math.floor((averageSeconds / 1000) % 60);
      const minutes = Math.floor((averageSeconds / (1000 * 60)) % 60);
      const hours = Math.floor(averageSeconds / (1000 * 60 * 60));
      const timeReturn = t => {
        return String(t).padStart(2, '0');
      };
      return `${timeReturn(hours)}:${timeReturn(minutes)}:${timeReturn(seconds)}`;
    },
    async onShowUpload() {
      let footer =
        '<a href="https://spencer-template.s3.ap-south-1.amazonaws.com/Orders.csv" >Download sample upload file</a>';
      if (this.user.role === User.userRole.administrator) {
        footer =
          '<a href="https://spencer-template.s3.ap-south-1.amazonaws.com/OrdersAdmin.csv" >Download sample upload file</a>';
      }
      const { value: orderFile } = await Vue.swal({
        title: 'Upload file for orders..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your orders file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        showCancelButton: true,
        cancelButtonText: 'Close',
        footer
      });
      if (orderFile) {
        const reader = new FileReader();
        reader.readAsText(orderFile);
        reader.onload = e => {
          this.upload({ data: e.target.result, router });
        };
      }
    },

    onExportCSVfile() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.downloadData({ query: { ...this.$route.query, page_size: 99999, page: 1 } });
      } else {
        this.downloadData({
          query: {
            ...this.$route.query,
            page_size: 99999,
            page: 1,
            startdate: moment()
              .subtract(1, 'months')
              .format('MMM YYYY')
          }
        });
      }
    },

    onDelete({ row }) {
      if (!this.user.storeids.includes(row.storeid)) {
        Vue.swal({
          title: 'Sorry, you can not delete an order of other store..',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Okay'
        });
      } else {
        Vue.swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this.",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',

          preConfirm: () => {
            this.deleteOne({
              id: row.orderId,
              sort: true
            });
          }
        });
      }
    },
    onAcknowledge({ row }) {
      if (row.isAcknowledged) {
        Vue.swal({
          title: 'Are you sure?',
          text: 'The user has been already acknowledged. Do you want to send messgae again ?',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Send message again!',

          preConfirm: () => {
            this.sendAcknowledgement({
              id: row.orderId,
              mobile: row.customerMobile,
              type: 'ORDER_ACKNOWLEDGEMENT',
              query: this.$route.query,
              router
            });
          }
        });
      } else {
        Vue.swal({
          title: 'Are you sure?',
          text: 'Do you want to do send acknowledge sms for this order ?',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirm',

          preConfirm: () => {
            this.sendAcknowledgement({
              id: row.orderId,
              mobile: row.customerMobile,
              type: 'ORDER_ACKNOWLEDGEMENT',
              query: this.$route.query,
              router
            });
          }
        });
      }
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
