<template>
  <div>
    <div>
      <b-modal
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        hide-header-close
        ref="my-modal"
        title="Enter Your Area Pin Code"
        @show="resetModal"
        @hidden="resetModal"
        hide-footer
      >
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            label="Pincode"
            label-for="pincode-input"
            invalid-feedback="Pincode is required"
            :state="pincodeState"
          >
            <div style="display: flex">
              <b-form-input
                id="pincode-input"
                v-model="inputData"
                :state="pincodeState"
                placeholder="Enter your 6 digit Pin code"
                required
                style="max-width: 270px"
              >
              </b-form-input>
              <b-button style="margin-left: 10px" variant="success" @click="searchStore">Get Stores</b-button>
            </div>
          </b-form-group>
        </form>

        <div>
          <div v-if="storeList.length > 0">
            <h5>Please select a store</h5>
            <ul class="list-group" v-for="store in storeList" :key="store.id">
              <li class="list-group-item storesList" @click="() => selectStore(store)"> {{ store.storeName }} </li>
            </ul>
          </div>
          <div v-else>
            <div v-if="hasSearched">
              <h5>No Stores Found.</h5>
              <p style="color: red">We are Live Soon In Your Area. Please Try with another Pincode.</p>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <div v-if="this.products.catalogueTitle !== ''">
      <br />
      <retail-box @open-store-modal="viewStoreModal" :products="products" />
    </div>
    <div v-else>
      <error></error>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RetailBox from '../../components/RetailBox.vue';
import Error from './Error.vue';

export default {
  components: { RetailBox, Error },
  data() {
    return {
      inputData: '',
      stores: [],
      pincodeState: null,
      storeData: null,
      hasSearched: false
    };
  },
  mounted() {
    if (localStorage.getItem('storeData') === null) this.showModal();
    this.id = this.$route.params.id;
    this.listProducts({ id: this.id });
    this.listStore({});
  },
  beforeRouteUpdate(to, form, next) {
    this.listStore({ query: to.query });
    next();
  },
  computed: {
    ...mapState('productList', ['products', 'storeList'])
  },
  methods: {
    ...mapActions('productList', ['listProducts', 'listStore']),
    selectStore(store) {
      this.hideModal();
      this.storeData = store;
      window.localStorage.setItem('storeData', JSON.stringify(store));
      window.location.reload();
    },
    searchStore() {
      //  Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.stores.push(this.inputData);
      this.listStore({
        query: {
          pincode: this.inputData
        }
      });
      this.hasSearched = true;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.pincodeState = valid;
      return valid;
    },
    resetModal() {
      this.inputData = '';
      this.pincodeState = null;
    },
    showModal() {
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    viewStoreModal() {
      this.showModal();
    }
  }
};
</script>

<style>
.storesList:hover {
  cursor: pointer;
  color: blue;
}
</style>
