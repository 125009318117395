<template>
  <div class="main">
    <!-- Header with spencer logo  -->
    <div class="header_div">
      <div class="header_logo_div">
        <!-- Spencer Logo  -->
        <div class="spencer_logo">
          <a href="#" class="logo_anchor">
            <img src="../assets/images/logo.png" alt="spencerLogo" width="80" />
          </a>
        </div>
        <!-- Spencer Title  -->
        <div class="header_title"> Spencer's Retail </div>
        <!-- Search Bar  -->
        <div class="search_bar">
          <div class="search_input">
            <input @keyup="filterBySearch" type="text" placeholder="Search for Products" v-model="filter" />
            <font-awesome-icon :icon="['fas', 'search']" class="mr-1 search_icon" />
          </div>
        </div>
      </div>
    </div>
    <div class="app">
      <!-- Small Screen Filter  -->
      <div class="mobileSize_btn">
        <div class="mobile_filter_btn">
          <b-button
            class="mobileFilter_btn dark_btn"
            @click="modelfilter = !modelfilter"
            style="padding-left: 20px; padding-right: 10px"
          >
            <font-awesome-icon :icon="['fas', 'filter']" class="mr-1" />Filter</b-button
          >
          <!-- Mobile Filter Model  -->
          <b-modal ref="my-modal" ok-only title="Filter" v-model="modelfilter">
            <b-tabs pills card vertical>
              <!-- Categories  -->
              <b-tab title="Categories" class="nav-link active" active style="margin-top: -18px"
                ><b-card-text>
                  <div style="overflow-y: scroll; max-height: 220px">
                    <b-col v-for="category in mobileFilterArr" :key="category.id" style="border: 1px solid grey">
                      <label>
                        <input
                          :value="category"
                          v-model="categories"
                          id="category"
                          @change="filterByCategory"
                          type="checkbox"
                          style="width: 20px; height: 20px; margin-top: 0.5rem"
                        />
                        <span style="padding: 0.7rem"> {{ category }}</span>
                      </label></b-col
                    >
                  </div>
                </b-card-text></b-tab
              >
              <!-- Price  -->
              <b-tab title="Price"
                ><b-card-text>
                  <div>
                    <b-form inline>
                      <b-input-group prepend="₹" class="mb-2 mr-sm-2 mb-sm-0">
                        <b-form-input
                          id="inline-form-input-username"
                          placeholder="Min"
                          style="width: 5rem"
                          v-model="min"
                        ></b-form-input>
                      </b-input-group>

                      <b-input-group prepend="₹" class="mb-2 mr-sm-2 mb-sm-0">
                        <b-form-input
                          id="inline-form-input-username"
                          placeholder="Max"
                          v-model="max"
                          style="width: 5rem"
                        ></b-form-input>
                      </b-input-group>
                    </b-form>
                    <b-button @click="filterByPrice" class="btn btn-primary" style="margin-top: 1rem">Apply</b-button>
                  </div>
                </b-card-text></b-tab
              >
            </b-tabs>
          </b-modal>
        </div>

        <!-- Mobile Filter Button  -->
        <div class="mobileSize_filter">
          <b-button class="mobileFilter_btn" @click="openStoreModal">Change Store</b-button>
          <b-button class="mobileFilter_btn" @click="clearFilter">Clear Filters</b-button>
          <b-button
            class="mobileFilter_btn"
            v-for="filter in mobileFilterArr"
            @click="filterByCategoryButton(filter)"
            :key="filter.id"
            >{{ filter }}</b-button
          >
        </div>
      </div>

      <!-- store selection for mobile size -->
      <b-row class="mobile_select_store">
        <div class="storeMobile">
          <div class="storeData">
            <div>
              <span class="storeKey">Selected Store : </span>
            </div>
            <div>
              <a class="storeName">{{ selectedStore.storeName }}</a>
            </div>
          </div>
        </div>
      </b-row>

      <!-- Desktop Screen Filter  -->
      <div class="filter">
        <!-- store selection  -->
        <b-row>
          <b-card class="categoryfilter">
            <h6 class="catogoryfilter_h6">Selected Store</h6>
            <a> {{ selectedStore.storeName }} </a>
            <button style="margin-top: 13px" @click="openStoreModal" class="btn btn-medium button" data-abc="true"
              >Change Store</button
            >
          </b-card>
        </b-row>
        <!-- category Filter  -->
        <b-row>
          <b-card class="categoryfilter">
            <h6 class="catogoryfilter_h6">Categories</h6>
            <div class="checkbox_card" v-for="category in computedArr" :key="category.id">
              <input type="checkbox" :value="category" id="category" v-model="categories" @change="filterByCategory" />
              <label>
                <span> {{ category }}</span>
              </label>
            </div>
            <button class="show_more_btn" v-if="this.limit != null" @click="limit = null"> Show More</button>
            <button class="show_less_btn" v-if="this.limit == null" @click="limit = 5"> Show less</button>
            <button @click="clearFilter2" class="clearbutton" data-abc="true">Clear Filter </button>
          </b-card>
        </b-row>
        <!-- Price Filter  -->
        <b-row>
          <b-card class="pricefilter">
            <h6 class="pricefilter_h6">Price</h6>
            <div class="pricefields">
              <div class="form-group col-md-7">
                <label>Min</label>
                <input class="form-control pricefield" v-model="min" placeholder="Min" type="number" />
              </div>
              <div class="form-group col-md-7">
                <label>Max</label>
                <input class="form-control pricefield" v-model="max" placeholder="Max" type="number" />
              </div>
            </div>
            <button @click="filterByPrice" class="btn btn-medium button" data-abc="true">Apply Now</button>
            <button @click="clearFilter" class="btn btn-medium button" data-abc="true">Clear Filter </button>
          </b-card>
        </b-row>
      </div>

      <!-- Product Card Section  -->
      <div class="items">
        <b-container>
          <!-- Cart Button  -->
          <div class="text-right cart_btn_div"
            ><button class="btn btn-primary" @click="openCart">Cart Items {{ cartItemsCount }} </button>
          </div>
          <div style="display: flex; margin-left: 2.1rem; color: #383838">
            <h5 class="title">{{ products.catalogueTitle }}</h5></div
          >
          <span style="margin-left: 2.2rem; font-size: 16px; font-weight: 500; color: #383838"
            >{{ productsLength }} Items</span
          >
          <!-- Product Cards  -->
          <b-row
            style="display: flex; justify-content: center; align-items: center"
            cols-xl="6"
            cols-lg="5"
            cols-md="4"
            cols-sm="3"
            cols-xs="2"
          >
            <!-- <h6 v-if="Searchproduct.length === 0">No match Found !</h6> -->
            <b-card no-body class="card_body wrappers" v-for="product in filteredProducts" :key="product.id">
              <b-badge class="discount_badge" v-if="product.discountInPercent !== null"
                >{{ product.discountInPercent }}% off</b-badge
              >
              <b-badge class="offer_badge target" v-if="product.offers !== null">{{ product.offers }}</b-badge>
              <!-- <div v-else>
                <b-badge class="discount_badge" v-if="product.discountInPercent === null"
                  >{{ product.offers }}% off</b-badge
                >
              </div> -->
              <img class="card_image" v-bind:src="product.images" />

              <div class="productname">{{ product.title }}</div>
              <div class="productDiscription">{{ product.description }}</div>
              <div v-if="product.discountInPercent === null || product.discountInPercent === 0" class="productprice">
                ₹ {{ product.price }}</div
              >
              <div style="display: flex" v-if="product.discountInPercent >= 1">
                <div class="productprice" v-if="product.discountInPercent !== null">
                  ₹ {{ product.price - product.price * (product.discountInPercent / 100) }}</div
                >
                <div
                  class="productprice cutPrice"
                  style="margin-top: 0.1em; font-weight: 400; text-decoration: line-through;"
                >
                  ₹ {{ product.price }}</div
                >
              </div>
              <div>
                <b-button
                  block
                  class="cartbutton"
                  v-if="!cartItems[product._id]"
                  variant="info"
                  @click="addToCart(product)"
                  >Add to Cart
                </b-button>
                <div class="quantity_btn_div" v-else>
                  <b-button class="plus_btn" @click="addToCart(product)">
                    <font-awesome-icon :icon="['fas', 'plus']" class="mr-1 arrow_icon" />
                  </b-button>
                  <span v-if="cartItems[product._id].quantity">
                    {{ cartItems[product._id].quantity }}
                  </span>
                  <b-button class="minus_btn" @click="removeFromCart(product)">
                    <font-awesome-icon :icon="['fas', 'minus']" class="mr-1 arrow_icon"
                  /></b-button>
                </div>
              </div>
            </b-card>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router';

export default {
  props: {
    products: Object
  },
  data() {
    return {
      categories: [],
      filter: '',
      limit: 5,
      modelfilter: false,
      cartItems: {},
      filteredProducts: null,
      min: '',
      max: '',
      selectedStore: {}
    };
  },
  mounted() {
    if (this.products?.products) {
      this.filteredProducts = [...this.products.products];
    }
    const cartItems = localStorage.getItem('product-cart') || '{}';
    this.cartItems = JSON.parse(cartItems);
    const selectedStore = localStorage.getItem('storeData') || '{}';
    this.selectedStore = JSON.parse(selectedStore);
  },
  methods: {
    filterByCategoryButton(value) {
      const category = value;
      if (category.length > 0) {
        const filteredProducts = this.products.products.filter(product => {
          return product.tags.some(tag => category.includes(tag));
        });
        this.filteredProducts = filteredProducts;
      }
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    addToCart(product) {
      const cartItems = { ...this.cartItems };
      const productId = product._id; // eslint-disable-line
      if (cartItems[productId]) {
        cartItems[productId] = {
          ...cartItems[productId],
          quantity: cartItems[productId].quantity + 1
        };
      } else {
        cartItems[productId] = { quantity: 1 };
      }
      this.cartItems = cartItems;
      window.localStorage.setItem('product-cart', JSON.stringify(cartItems));
    },
    removeFromCart(product) {
      const cartItems = { ...this.cartItems };
      const productId = product._id; // eslint-disable-line

      if (cartItems[productId]) {
        const quantity = cartItems[productId].quantity - 1;
        if (quantity < 1) {
          delete cartItems[productId];
        } else {
          cartItems[productId] = {
            ...cartItems[productId],
            quantity
          };
        }
      }
      this.cartItems = cartItems;
      window.localStorage.setItem('product-cart', JSON.stringify(cartItems));
    },
    openCart() {
      router.push(`/product-cart/${this.products.id}`);
    },
    clearFilter() {
      this.min = '';
      this.max = '';
      this.filteredProducts = [...this.products.products];
    },
    clearFilter2() {
      this.categories = [];
      this.filter = '';
      this.filteredProducts = [...this.products.products];
    },
    filterByCategory() {
      if (this.categories.length > 0) {
        const filteredProducts = this.products.products.filter(product => {
          return product.tags.some(tag => this.categories.includes(tag));
        });
        this.filteredProducts = filteredProducts;
      }
      if (this.categories.length === 0) {
        this.filteredProducts = [...this.products.products];
      }
    },
    filterByPrice() {
      const priceProducts = this.products.products.filter(item => {
        return item.price >= this.min && item.price <= this.max;
      });
      this.filteredProducts = priceProducts;
      this.hideModal();
    },
    filterBySearch() {
      const searchProducts = this.products.products.filter(item => {
        return item.title.toLowerCase().includes(this.filter.toLowerCase());
      });
      this.filteredProducts = searchProducts;
    },
    openStoreModal() {
      this.$emit('open-store-modal');
    }
  },
  computed: {
    productsLength() {
      if (this.filteredProducts) {
        return this.filteredProducts.length;
      }
      return 0;
    },
    cartItemsCount() {
      return Object.values(this.cartItems).reduce((count, item) => {
        return count + item.quantity;
      }, 0);
    },
    computedArr() {
      const arr = [];
      if (this.products.products) {
        this.products.products.forEach(product => {
          product.tags.forEach(tag => {
            if (!arr.includes(tag)) {
              arr.push(tag);
            }
          });
        });
      }
      return this.limit ? arr.slice(0, this.limit) : arr;
    },
    mobileFilterArr() {
      const arr = [];
      if (this.products.products) {
        this.products.products.forEach(product => {
          product.tags.forEach(tag => {
            if (!arr.includes(tag)) {
              arr.push(tag);
            }
          });
        });
      }
      return arr;
    }
  },
  watch: {
    products(newValue) {
      if (newValue.products) {
        this.filteredProducts = [...newValue.products];
      }
    }
  }
};
</script>

<style scoped>
.wrappers {
  overflow-x: hidden;
  overflow-y: hidden;
}
.target {
  margin-top: 10px;
  animation-name: rightToLeft;
  animation-duration: 8s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes rightToLeft {
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(-200px);
  }
}
.main {
  margin-top: -4.5rem;
  padding: 0;
}
/* Header and Spencer Logo Css  */
.header_div {
  margin-top: -2rem;
  margin-bottom: 1rem;
}

.header_logo_div {
  display: flex;
}

/* Spencers Title  */
.header_title {
  color: #383838;
  font-weight: 700;
  margin-left: 1em;
  font-size: 1.2rem;
  margin-top: -0.3rem;
}

/* Search Bar  */
.search_bar {
  margin-left: auto;
  border-radius: 3px;
  border: 2px solid #f28a3e;
  background-color: #f28a3e;
}

.search_input > input {
  border: none;
  width: 15rem;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #f28a3e;
}

.search_input > input:focus {
  outline: none;
}

.search_icon {
  color: #fff;
  padding: 1px;
  margin-left: 6px;
}

/* Desktop Size and Card Css  */
.app {
  display: flex;
}

.card_body {
  margin: 10px 10px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card_image {
  width: 92%;
}

/* Mobile Filter Css  */
.mobileSize_filter {
  display: none;
}

.mobileSize_btn {
  display: none;
}

.filter {
  display: inline-flex;
  flex-direction: column;
  width: 20%;
  margin-left: 2%;
  margin-top: 3%;
  height: 100%;
}

.cart_btn_div {
  margin-right: -19px;
}

.cart_btn_div > button {
  width: 17rem;
  height: 35px;
  padding-top: 3px;
  font-weight: 500;
  padding-bottom: 26px;
  border: 2px solid #fff;
  background-color: #f28a3e;
}

.cart_btn_div > button:hover {
  color: #f28a3e;
  padding-top: 3px;
  background-color: #fff;
  border: 2px solid #f28a3e;
}

.items {
  display: inline-flex;
  width: 100%;
  height: 100%;
}

.checkbox-btn {
  position: relative;
}

.checkbox-btn input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.product_actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Category filter Styling  */

.categoryfilter {
  width: 100%;
  height: 100%;
  margin-bottom: 10%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.catogoryfilter_h6 {
  font-size: 18px;
  font-weight: 600;
}

.show_more_btn,
.show_less_btn {
  width: 100%;
  color: #fff;
  outline: none;
  font-size: 13px;
  font-weight: 600;
  padding: 3px 10px;
  border-radius: 2px;
  letter-spacing: 1px;
  margin: 17px 0px 0px 0px;
  border: 2px solid #f28a3e;
  background-color: #f28a3e;
}

.clearbutton {
  width: 100%;
  color: #fff;
  outline: none;
  font-size: 13px;
  font-weight: 600;
  padding: 3px 10px;
  border-radius: 2px;
  letter-spacing: 1px;
  margin: 5px 0px 0px 0px;
  border: 2px solid #f28a3e;
  background-color: #f28a3e;
}

.clearbutton:hover {
  color: #f28a3e;
  background-color: #fff;
  border: 2px solid #f28a3e;
}

.show_more_btn:hover {
  color: #f28a3e;
  background-color: #fff;
  border: 2px solid #f28a3e;
}

.show_less_btn:hover {
  color: #f28a3e;
  background-color: #fff;
  border: 2px solid #f28a3e;
}

.checkbox_card {
  position: relative;
}

.checkbox_card > input {
  width: 20px;
  height: 20px;
  margin: 0.6rem 0.3rem 0 0.6rem;
}

.checkbox_card > label > span {
  top: 23%;
  font-size: 16px;
  position: absolute;
  margin-left: 0.9rem;
}

.pricefilter {
  width: 100%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.pricefilter_h6 {
  font-size: 18px;
  font-weight: 600;
}

.pricefields {
  display: flex;
  margin-left: -15px;
  margin-right: 10px;
}

.pricefields > div > label {
  font-size: 16px;
  font-weight: 400;
}

.pricefields > div > input {
  height: 32px;
}

.btn-medium {
  width: 100%;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 10px;
  letter-spacing: 1px;
  display: inline-block;
  border: 2px solid #fff;
  background-color: #f28a3e;
}

.btn-medium:hover {
  width: 100%;
  cursor: pointer;
  color: #f28a3e;
  border-radius: 2px;
  padding: 5px 10px;
  letter-spacing: 1px;
  background-color: #fff;
  border: 2px solid #f28a3e;
}

.title {
  margin-top: 0;
  font-size: 23px;
  margin-bottom: 2%;
}

.card_body {
  cursor: pointer;
  position: relative;
  border-radius: unset;
}

.card_body:hover {
  box-shadow: #eeb790 0px 3px 9px;
}

/* Select Store for Mobile view  */
.mobile_select_store {
  display: none;
}

/* Card Styling  */
.discount_badge {
  width: 80px;
  height: 20px;
  border-radius: 15px 80px;
  position: absolute;
  background: #f28a3e;
  transform: translate(1%, 60%);
}

.offer_badge {
  /* width: 100px; */
  height: 20px;
  border-radius: 15px 80px;
  position: absolute;
  background: #f28a3e;
  transform: translate(1%, 60%);
}

.card_image {
  padding: 0.5rem;
  margin: 32px 15px 5px 4px;
  max-width: 7rem;
  min-width: 8rem;
  max-height: 7rem;
  min-height: 8rem;
}

.productname {
  color: #383838;
  font-size: 12px;
  font-weight: 500;
  padding-left: 8px;
}

.productDiscription {
  font-size: 9px;
  font-weight: 400;
  padding-left: 8px;
  width: max-content;
}

.productprice {
  color: #383838;
  font-size: 15px;
  font-weight: 500;
  padding-left: 8px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.cartbutton {
  background-color: #f28a3e;
  margin-bottom: -0.1rem;
  border: 1px solid #f28a3e;
  border-radius: unset;
}

/* Quantity Button styling  */
.quantity_btn_div {
  display: flex;
  justify-content: space-between;
  border: 1px solid #f28a3e;
  border-radius: 2px;
}

.quantity_btn_div > span {
  color: #383838;
  font-size: 25px;
  margin-left: 9%;
  font-weight: 400;
}

.plus_btn {
  width: 35%;
  margin: 1px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 15px;
  font-weight: 900;
  border-radius: 2px;
  background-color: #f28a3e;
}

.minus_btn {
  width: 35%;
  outline: none;
  border: none;
  padding: 0px;
  color: #fff;
  font-size: 15px;
  font-weight: 900;
  margin: 1px 1px 1px auto;
  border-radius: 2px;
  background-color: #f28a3e;
}

@media screen and (max-width: 319px) {
  .storeKey {
    font-size: 8px;
    font-weight: 500;
    color: #495057;
  }

  .storeName {
    font-size: 9px;
    color: #f28a3e;
    font-weight: 500;
  }

  .productname {
    font-size: 9px;
  }

  .productDiscription {
    font-size: 8px;
  }

  .productprice {
    font-size: 8px;
  }
}

@media screen and (max-width: 300px) {
  .search_input > input {
    width: 7rem;
    padding: 0px;
  }

  .search_icon {
    padding: 0px;
    margin-left: 4px;
  }

  .card_image {
    max-width: 5rem;
    min-width: 5rem;
    max-height: 5rem;
    min-height: 6rem;
  }

  .quantity_btn_div {
    height: 35px;
  }

  .quantity_btn_div > span {
    font-size: 15px;
    font-weight: 700;
    margin: 0.6rem 0.2rem 0.5rem 0.4rem;
  }

  .cartbutton {
    font-size: 10px;
  }
}

@media only screen and (max-width: 538px) and (min-width: 301px) {
  .search_input > input {
    width: 10rem;
    padding: 3px;
  }

  .search_icon {
    padding: 2px;
    margin-left: 4px;
  }
}

@media screen and (max-width: 575px) {
  .card_body {
    width: 42%;
  }
}

@media screen and (max-width: 600px) {
  .cart_btn_div > button {
    width: 107%;
    margin: 20px 0px 20px -21px;
  }

  .mobileSize_btn {
    max-width: 100%;
    margin-left: -8px;
  }

  .storeMobile {
    padding: unset;
    min-width: 10rem;
    margin-top: 10px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 700px) {
  .header_title {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .app {
    display: flex;
    flex-direction: column;
  }

  .filter {
    display: none;
  }

  .categoryfilter {
    display: none;
  }

  .pricefilter {
    display: none;
  }

  .mobileSize_btn {
    display: flex;
  }

  .mobile_filter_btn {
    margin-top: 0.3rem;
  }

  .mobileSize_filter {
    display: flex;
    justify-content: space-between;
    margin: 4px, 4px;
    padding: 5px;
    overflow: auto;
    white-space: nowrap;
  }

  .mobileFilter_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid #f28a3e;
    border-radius: 15px;
    font-size: 13px;
    font-weight: 500;
    height: 30px;
    width: auto;
    margin-bottom: 7px;
    margin-left: 5px;
    color: #f28a3e;
  }

  /* mobile view store select  */
  .dark_btn {
    color: #fff;
    background-color: #f28a3e;
  }
  .mobile_select_store {
    display: flex;
    margin: auto;
  }

  .storeMobile {
    padding: unset;
    min-width: 10rem;
    margin-bottom: 5px;
  }

  .storeData {
    display: flex;
  }
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .storeKey {
    font-size: 10px;
    font-weight: 500;
    color: #495057;
  }

  .storeName {
    margin-left: 2px;
    font-size: 12px;
    color: #f28a3e;
    font-weight: 500;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1100px) {
  .pricefields {
    display: block;
  }
  .pricefield {
    width: 8rem;
  }
}
</style>
