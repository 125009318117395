export default `<style>
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #FAFAFA;
    font: 12pt "Arial";
  }
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  .page {
    width:29.7cm;
    height: 21cm;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  .noDisplay{
    display: none;
  }
  .title{
    position:absolute;
    top:1%;
    left:2%;
    font-size: 20pt;
    letter-spacing: 1px;
    font-style: arial;
    font-weight: bold;
  }
  .orderTime{
    position:absolute;
    text-align: right;
    top:1%;
    right:0;
    font-size: 12pt;
    font-style: arial;
    font-weight: bold;
  }
  .header{
    text-align: center;
    margin-bottom:2%;
  }
  .table-container {
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .ctr{
    position: absolute,
    left:5%;
  }
  .quantity {
    position: relative;
    top: 25%;
    font-weight: bold;
    text-align: center;
  }
  .amount {
    position: relative;
    top: 25%;
    text-align: center;
    font-weight: bold;
 }
  .sellingPrice{
  position: relative;
  top: 25%;
  text-align: center;
  font-weight: bold;
 }
  .cartValue{
    position: relative;
    top: 25%;
    right: 0;
    text-align: center;
    font-weight: bold;
  }
  .sellingPriceItem{
    font-size: 18pt;
    letter-spacing: 1px;
    font-style: arial;
    font-weight: bold;
    text-align: center;
    margin: 0 5px;
  }
  .item{
    font-size: 18pt;
    letter-spacing: 1px;
    font-style: arial;
    font-weight: bold;
    text-align: center;

  }

  border-collapse: collapse;
  width: 90%;

  border-bottom: 1px solid black;
}

th, td {

  padding: 8px;
  text-align: center;
  font-weight: bold;
  right: 2%
  left:3%
  border-bottom: 1px solid black;

}

th {
  background-color: #dbd7d2;
    font-size: 18pt;
    border-top: 1px solid black;
}
.articleCode, .products, .quantity, .amount, .cartValue {
  font-size: 13pt;
}



 .productitem {
  font-size: 15pt;
  letter-spacing: 1px;
  font-style: arial;
  font-weight: bold;
  overflow-wrap: break-word;
  width:90%
}

/* Add borders between columns */
th:not(:last-child), td:not(:last-child) {
  border-right: 1px solid black;

}

/* Add borders between rows */
tr:not(:last-child) td, tr:not(:last-child) th {
  border-bottom: 1px solid black;

}
  .size_20{
    color: black;
    font-size: 20pt;
    text-align:left !important;
    letter-spacing: 1px;
    font-style: arial;
    font-weight: bold;
    text-align: center;
  }
  .info{
    color: black;
    font-size: 15pt;
    font-style: arial;
    font-weight: bold;
    text-align: center;
  }
  .header-area {
    width: 100%;
    position:absolute;
    top:5%;
    color: black;
    font-size: 67pt;
    font-style: arial;
    font-weight: bold;
    text-align: center;
  }

  .center-area {
    width: 100%;
    position:absolute;
    top:23%;
    display: flex;
    flex-direction: column;
  }
  .offer-text {
    justify-content: center;
     margin: 0;
     width: 100%;
     display:flex;
   }

  .price-container {
    position:absolute;
    top:68%;
    width:90%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    display: flex;
    justify-content: space-between;
  }
  .price-label {
    font-style: arial;
    font-size: 40pt;
    font-weight: bold;
  }

  .mrp-container{
    position: absolute;
    right:5%;
    top:65%;
  }
  .save-container{
    position: absolute;
    left:5%;
    top:65%
  }
  .rupeeSymbol{
    font-weight: bold;
    font-size: 120pt;
    font-family: Arial;
    padding-top: 20px;
  }
  .percentageSymbol{
    font-weight: bold;
    font-size: 80pt;
    font-family: Arial;
    padding-top: 115px;
  }

  .buyClass{
    width: 45%;
    text-align: center;
    font-weight: bold;
    font-size: 120pt;
    font-family: Arial;
    position : absolute;
    // line-height:120pt;
    top:36%;
    left:43%;
    transform: translateY(-50%) translateX(-50%);
  }

  .offer-text-rupee {
    font-weight: bold;
    font-family: Arial;
    font-size: 180pt;
    position: relative;

  }
 .description {
    position: absolute;
    padding:0;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    top:90%;
    width: 65%;
    text-align: center;
    padding:0;
    color: #000000;
    font-weight: bold;
    font-size: 34pt;
    font-family: Arial;
  }
  .familyDescription{
    position: absolute;
    padding:0;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    top:80%;
    width: 65%;
    text-align: center;
    padding:0;
    color: #000000;
    font-weight: bold;
    font-size: 34pt;
    font-family: Arial;
  }

  .price-text-area {
      padding: 25px;
      float: left;
      font-weight: bold;
      font-style: arial;
      font-size: 38pt;
  }
   .price-text-area2 {
    float:right:
      padding: 25px;
      font-weight: bold;
      font-style: arial;
      font-size: 38pt;
  }

  .footer-text {
    color: #000000;
    font-weight: bold;
    font-size: 34pt;
    font-family: Arial;
    width: 100%;
 }

  @page {
    size: landscape;
    margin: 0;
  }
  @media print {
      html, body {
        width:29.7cm;
        height: 21cm;
      }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    }
  }
  </style>`;
