<template>
  <div class="page-class page-user-list">
    <h1 class="page-title">{{ title }}</h1>
    <b-2-b-print-template-a-4 v-show="false" :data="printData" ref="orderB2B"></b-2-b-print-template-a-4>

    <table-box-order
      :columns="columns"
      :rows="b2border"
      :baseUrl="baseUrl"
      :showToolbar="true"
      :loading="loading"
      :downloading="downloading"
      :showDownload="true"
      :showAdd="isAdmin"
      listText="B2B BDA Wise Report List"
      downloadText="Download B2B-Orders List"
      addText="Copy E-Link"
      :showOtherDownload="hasAccessToBDAReport"
      :filterOptions="filterOptions"
      :searchItems="searchItems"
      @edit="onEdit"
      @download="onExportCSV"
      :showPagination="true"
      :pagination="pagination"
      @print="onPrint"
      @list="onList"
      @add="onCopyLink"
      emptyText="No Data Found"
    ></table-box-order>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import Vue from 'vue';
import TableBoxOrder from '@/components/TableBoxOrder.vue';
import B2BPrintTemplateA4 from '@/components/B2BPrintTemplateA4.vue';
import router from '@/router';

import PrintTemplate from '../../helper/b2bPrintTemplate';

export default {
  name: 'OrderList',
  components: {
    TableBoxOrder,
    B2BPrintTemplateA4
  },
  metaInfo() {
    return {
      title: 'B2B-Orders',
      meta: [
        {
          name: 'description',
          content: `Manage orders`
        }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.list({
        query: {
          ...this.$route.query,
          startdate: moment().format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.list({ query: this.$route.query });
    }
    this.b2BStoreList({ router });
  },
  beforeRouteUpdate(to, from, next) {
    this.list({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'B2B-Orders',
      printData: {},
      searchItems: ['Store Id', 'GST Number', 'Sales Associate Name', 'Employee Id'],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'Acknowledged', text: 'Acknowledged' },
        { value: 'Delivered', text: 'Delivered' }
      ],
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'rowNum',
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeId',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'BDA Name',
          class: { 'text-center': true },
          textKey: 'salesAssociateName',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'GST number',
          class: { 'text-center': true },
          textKey: 'gstNumber',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'Employee Id',
          class: { 'text-center': true },
          htmlKey: 'employeeId',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Products',
          class: { 'text-center': true },
          textKey: 'products',
          width: '22%'
        },
        {
          type: 'string',
          headerText: 'Address',
          class: { 'text-center': true },
          htmlKey: 'address',
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Order Status',
          class: { 'text-center': true },
          htmlKey: 'status',
          width: '8%'
        },

        {
          type: 'string',
          headerText: 'Acknowledged',
          class: { 'text-center': true },
          textKey: 'isAcknowledged',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Delivered',
          class: { 'text-center': true },
          textKey: 'isDelivered',
          width: '7%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '12%',
          functions: {
            edit: true,
            ack: false,
            print: true
          }
        }
      ]
    };
  },
  computed: {
    ...mapState('b2BOrder', ['baseUrl', 'pagination', 'loading', 'b2borders', 'downloadOrdersList', 'downloading']),
    ...mapState('B2BReport', ['b2bStoreList']),
    ...mapState('auth', ['user']),
    isAdmin() {
      const userRole = this.user.roles.map(({ name }) => name);
      if (userRole.includes('ADMIN')) return true;
      return false;
    },
    hasAccessToBDAReport() {
      const { storeids } = this.user;
      const userRole = this.user.roles.map(({ name }) => name);
      if (userRole.includes('ADMIN')) return true;
      if (
        storeids.find(store => this.b2bStoreList.includes(store)) &&
        (userRole.includes('STORE_SALES_MANAGER') ||
          userRole.includes('AREA_SALES_MANAGER') ||
          userRole.includes('REGIONAL_SALES_MANAGER'))
      ) {
        return true;
      }
      return false;
    },
    b2border() {
      return this.b2borders.map(i => {
        let status = '';
        if (i.actualDeliveredTime) {
          status = `Order Delivered: ${i.actualDeliveredTime}`;
        } else if (i.packagedTime) {
          status = `Order Packaged: ${i.packagedTime}`;
        } else if (i.posPingTime) {
          status = `Bill Generated:  ${i.posPingTime}`;
        } else if (i.acknowledgedTime) {
          status = `Acknowledged Time:  ${i.acknowledgedTime}`;
        } else {
          status = `Order Created: ${i.createdAt}`;
        }
        return {
          ...i,
          status
        };
      });
    }
  },
  watch: {
    downloadOrdersList(newValue) {
      if (newValue.length) {
        let csv =
          'Order Id,BDA Name,GST Number,Customer Name,Customer Address,Source,Customer Mobile,Created On,Updated On,Description,Store Id,Acknowledged,Delivered,Products,Article Code,Quantity,Mrp,Selling Price,Cart Price,Order Created Time,order Acknowledged Time,Order Packaged Time ,Bill Generated Time,Order Delivered Time,Order Mode, Pincode,Bill Number,POS Quantity,POS Selling Price,POS amount,\n';

        newValue.forEach(row => {
          const products = row.products.split(',');
          const restPosItems = [];
          const productsNames = [];
          products.forEach(product => {
            const [productName, quantity = '', articleCode = '', mrp = '', sellingPrice = ''] = product.split(':::');
            const cartValue = quantity * sellingPrice;
            productsNames.push(productName);
            const sellItem = row.sellQuantity.find(item => {
              return item.itemName.includes(productName);
            });
            const quant = sellItem ? sellItem.quantity : '';
            const amnt = sellItem ? sellItem.amount : '';
            if (quant === '' && amnt === '') {
              const sp = '';
              csv += `"${row.orderId}","${row.salesAssociateName}","${row.gstNumber}","${row.customerName}","${row.address}","${row.source}","${row.customerMobile}","${row.dateCreated}","${row.dateUpdated}","${row.description}","${row.storeId}","${row.isAcknowledged}","${row.isDelivered}","${productName}","${articleCode}","${quantity}","${mrp}","${sellingPrice}","${cartValue}","${row.createdAt}","${row.acknowledgedTime}","${row.packagedTime}","${row.posPingTime}","${row.actualDeliveredTime}","${row.orderMode}","${row.pinCode}","${row.billNumber}","${quant}","${sp}","${amnt}"\n`;
            } else {
              csv += `"${row.orderId}","${row.salesAssociateName}","${row.gstNumber}","${row.customerName}","${row.address}","${row.source}","${row.customerMobile}","${row.dateCreated}","${row.dateUpdated}","${row.description}","${row.storeId}","${row.isAcknowledged}","${row.isDelivered}","${productName}","${articleCode}","${quantity}","${mrp}","${sellingPrice}","${cartValue}","${row.createdAt}","${row.acknowledgedTime}","${row.packagedTime}","${row.posPingTime}","${row.actualDeliveredTime}","${row.orderMode}","${row.pinCode}","${row.billNumber}","${quant}","${sellingPrice}","${amnt}"\n`;
            }
          });
          row.sellQuantity.forEach(item => {
            if (!productsNames.includes(item.itemName)) {
              restPosItems.push(item);
            }
          });
          if (restPosItems.length > 0) {
            restPosItems.forEach(item => {
              csv += `"${item.id}","${row.salesAssociateName}","${row.gstNumber}","${row.customerName}","${row.address}","POS Entries","${row.customerMobile}","${row.dateCreated}","${row.dateUpdated}","${row.description}","${row.storeId}","${row.isAcknowledged}","${row.isDelivered}","${item.itemName}","${item.articleCode}",,,,,"${row.createdAt}","${row.acknowledgedTime}","${row.packagedTime}","${row.posPingTime}","${row.actualDeliveredTime}",,"${row.pinCode}","${row.billNumber}","${item.quantity}","${item.mrp}","${item.amount}"\n`;
            });
          }
        });

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'B2B-Orders.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('b2BOrder', ['list', 'download', 'markOrderPrinted']),
    ...mapActions('B2BReport', ['b2BStoreList']),
    async onCopyLink() {
      await navigator.clipboard.writeText('ecatalogue.attabot.in/eCategoryList');
      Vue.swal({
        title: 'Copied!',
        text: 'The link has been copied to your clipboard.',
        icon: 'success',
        timer: 2000,
        timerProgressBar: true
      });
    },
    onList() {
      router.push('/b2b/report');
    },
    onPrint({ row }) {
      this.printData = row;
      setTimeout(() => {
        this.printCommand(row?.orderId);
      }, 500);
    },
    printCommand(orderId) {
      const stylesHtml = PrintTemplate;
      setTimeout(() => {}, 2000);
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      setTimeout(() => {
        WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
          </head>
          <body>
            ${this.$refs.orderB2B.$el.innerHTML}
          </body>
        </html>`);
      }, 1000);
      setTimeout(() => {
        WinPrint.document.close();
      }, 1000);
      setTimeout(() => {
        WinPrint.focus();
      }, 1000);
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 2000);
      this.printedCommand(orderId);
    },
    printedCommand(orderId) {
      Vue.swal({
        title: 'Mark these items printed',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Marked Printed'
      }).then(result => {
        if (result.isDismissed) {
          return null;
        }
        if (result.value) {
          this.markOrderPrinted({
            id: orderId,
            router
          });
          return null;
        }
        return null;
      });
    },

    onEdit({ row }) {
      router.push(`/b2b-order/${row.orderId}`);
    },
    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query, page_size: 5000, page: 1 } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            page_size: 5000,
            page: 1,
            startdate: moment()
              .subtract(1, 'months')
              .format('MMM YYYY')
          }
        });
      }
    }
  }
};
</script>
