<template>
  <div class="page-class page-user-list">
    <h1 class="page-title">{{ title }}</h1>
    <order-report-box
      :columns="columns"
      :rows="outboundCallerList"
      :baseUrl="baseUrl"
      :loading="loading"
      :showDownload="true"
      :showFilter="false"
      :showUpload="ifAdmin()"
      :showToolbar="true"
      :showPagination="true"
      :showList="true"
      :showDeleteList="ifPermission"
      :showBlocked="ifAdmin()"
      :pagination="pagination"
      :searchItems="searchItems"
      :filterOptions="filterOptions"
      downloadText="Download List"
      uploadText="Upload Calling List"
      emptyText="No Data found."
      listText="Dialed Call List"
      blockedText="BlackList numbers"
      deleteText="Delete"
      @download="onExportCSV"
      @show-upload="onUpload"
      @list="onList"
      @blocklist="onBlockList"
      @deletelist="onDeleteList"
      :uploadLoading="uploadLoading"
    >
    </order-report-box>
    <div>
      <b-modal ref="delete-Modal" id="modal-lg" size="lg" title="Select Date Range">
        <b-form data-v-step="2" inline class="float-left">
          <div style="display: flex; flex-direction: column; align-items: flex-start;">
            <div class="mb-2">
              <label for="start-date" style="display: inline-block;">Start Date:</label>

              <b-form-datepicker
                id="start-date"
                placeholder="Start Date"
                :min="minDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                v-model="startDateValueForDelete"
                class="ml-2 mb-2"
                style="display: inline-block;"
              ></b-form-datepicker>
            </div>

            <div class="mb-2">
              <label for="end-date" style="display: inline-block;">End Date:</label>
              <b-form-datepicker
                id="end-date"
                placeholder="End Date"
                :min="minDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                v-model="endDateValueForDelete"
                class="ml-2 mb-2"
                style="display: inline-block;"
              ></b-form-datepicker>
            </div>

            <div class="mb-2">
              <label for="filter-new" style="display: inline-block;">Customer Type:</label>
              <b-form-input
                :formatter="formatter"
                id="filter-new"
                placeholder="Enter Value"
                v-model="filterNewValue"
                class="ml-2 mb-2"
                style="display: inline-block;"
              ></b-form-input>
            </div>
            <b-form-group id="group-products" label-for="input-products">
              <template v-slot:label> Store Id <span class="text-danger">*</span> </template>
              <multiselect
                v-model="selectedStores"
                ref="multiselect"
                placeholder="Select Store Ids"
                :options="storeOptions"
              >
              </multiselect>
            </b-form-group>
          </div>
        </b-form>
        <div slot="modal-footer" class="w-100 d-flex justify-content-end">
          <b-button variant="danger" class="ml-2 mb-2" @click="deleteItem">Proceed</b-button>
          <b-button variant="secondary" class="ml-2 mb-2" @click="$refs['delete-Modal'].hide()">Cancel</b-button>
        </div>
      </b-modal>

      <b-modal ref="confirm-Modal" size="lg" title="Please Confirm">
        <div v-if="loading" class="text-center"> <span class="spinner spinner-black"></span> </div>
        <div v-else
          ><p class="my-1">{{ this.numberList }} numbers found. Are you sure you want to delete these numbers?</p></div
        >
        <div slot="modal-footer" class="w-100 d-flex justify-content-end">
          <b-button variant="danger" :disabled="this.numberList <= 0" class="ml-2 mb-2" @click="finalDelete"
            >Delete</b-button
          >
          <b-button
            variant="secondary"
            class="ml-2 mb-2"
            @click="
              $refs['delete-Modal'].show();
              $refs['confirm-Modal'].hide();
            "
            >Cancel</b-button
          >
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import csv from 'csvtojson';
import router from '@/router';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import Multiselect from 'vue-multiselect';
import orderReportBox from '../../components/orderReportBox.vue';

export default {
  name: 'OutboundCallerList',
  components: {
    orderReportBox,
    Multiselect
  },
  metaInfo() {
    return {
      title: 'Outbound Caller List',
      meta: [
        {
          name: 'description',
          content: `Manage Outbound Caller List`
        }
      ]
    };
  },
  mounted() {
    this.listStore({ router });
    this.removeFromList({ fromPage: true });
    if (_.isEmpty(this.$route.query)) {
      this.list({
        query: {
          ...this.$route.query,
          startdate: moment().format('YYYY-MM-DD'),
          enddate: moment()
            .add(1, 'month')
            .format('YYYY-MM-DD')
        }
      });
    } else {
      this.list({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.list({ query: to.query });
    next();
  },
  data() {
    return {
      storeOptions: [],
      selectedStores: '',
      startDateValueForDelete: '',
      endDateValueForDelete: '',
      filterNewValue: '',
      minDate: moment().format('YYYY-MM-DD'),
      title: 'Outbound Caller Uploaded List',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'rowNum',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Mobile',
          class: { 'text-center': true },
          textKey: 'mobile',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeCode',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Priority',
          class: { 'text-center': true },
          textKey: 'priority',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Customer Type',
          class: { 'text-center': true },
          textKey: 'filterNew',
          width: '20%'
        },
        {
          type: 'string',
          headerText: 'Calling Date',
          class: { 'text-center': true },
          textKey: 'callingDate',
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Uploaded By',
          class: { 'text-center': true },
          textKey: 'createdByName',
          width: '18%'
        },
        {
          type: 'string',
          headerText: 'Uploaded At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '10%'
        }
      ],
      searchItems: ['Store Id', 'Mobile'],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'Dialed-True', text: 'Dialed-True' },
        { value: 'Dialed-False', text: 'Dialed-False' }
      ]
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('outboundCaller', [
      'outboundCallerList',
      'pagination',
      'loading',
      'downloadOutboundCallerList',
      'baseUrl',
      'uploadLoading',
      'numberList'
    ]),
    ...mapState('store', ['storeList']),
    ifPermission() {
      const role = this.user.roles.filter(role => role.name === 'OUTBOUND_CALLER_DELETE' || role.name === 'TEST_ROLE');
      if (role.length) {
        return true;
      }
      return false;
    }
  },
  watch: {
    downloadOutboundCallerList(newValue) {
      if (newValue.length) {
        let csv = 'Row Num,Mobile,Store Code,Priority,Customer Type,Calling Date,Uploaded At,Uploaded By\n';
        newValue.forEach(row => {
          csv += `${row.rowNum},${row.mobile},${row.storeCode},${row.priority},${row.filterNew},${row.callingDate},${row.createdAt},${row.createdByName}\n`;
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Calling List.csv';
        anchor.click();
      }
    },
    storeList(newValue) {
      let comparingStores = [];
      comparingStores = newValue.map(store => ({
        storeid: store.value
      }));
      this.newStoreList = comparingStores;
      this.stores = newValue;
    },
    user(_newValue, _oldValue) {
      if (this.user.storeids.length) {
        this.storeid = { text: this.user.storeids[0], value: this.user.storeids[0] };
        this.storeids = this.user.storeids.map(id => ({ text: id, value: id }));
      }
    }
  },
  methods: {
    ...mapActions('outboundCaller', [
      'list',
      'download',
      'upload',
      'removeFromList',
      'getNumberToDelete',
      'deleteNumbers'
    ]),
    ...mapActions('store', ['listStore']),
    formatter(name) {
      return name
        .toUpperCase()
        .split(/ /g)
        .join('_');
    },
    deleteItem() {
      if (this.startDateValueForDelete === '' || this.endDateValueForDelete === '') {
        Vue.swal({
          title: 'Date Range Require!',
          icon: 'error',
          text: 'Please select date range ',
          error: 'please select date '
        });
      } else {
        const queryParams = {
          startdate: this.startDateValueForDelete,
          enddate: this.endDateValueForDelete
        };
        if (this.filterNewValue !== '') {
          queryParams.filter = this.filterNewValue;
        }
        if (this.selectedStores !== '' && this.selectedStores !== null) {
          queryParams.storeid = this.selectedStores;
        }
        this.getNumberToDelete({
          query: queryParams
        });
        this.$refs['delete-Modal'].hide();
        this.$refs['confirm-Modal'].show();
      }
    },

    finalDelete() {
      this.$refs['confirm-Modal'].hide();
      const queryParams = {
        startdate: this.startDateValueForDelete,
        enddate: this.endDateValueForDelete
      };
      if (this.filterNewValue !== '') {
        queryParams.filter = this.filterNewValue;
      }
      if (this.selectedStores !== '' && this.selectedStores !== null) {
        queryParams.storeid = this.selectedStores;
      }
      this.deleteNumbers({
        query: queryParams
      });
    },

    onList() {
      router.push('/dialedCallList');
    },
    onBlockList() {
      router.push('/blockedNumberList');
    },
    ifAdmin() {
      const isAdmin = this.user.role === 99;
      if (isAdmin) {
        return true;
      }
      return false;
    },
    onDeleteList() {
      this.$refs['delete-Modal'].show();
    },

    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            startdate: moment()
              .subtract(1, 'months')
              .format('MMM YYYY')
          }
        });
      }
    },

    createCsv() {
      const csv = `Mobile, Store Id,Priority(true/false), Calling Date(DD-MM-YYYY),Filter(REGULAR/etc)\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'outboundCallerUploadList.csv';
      anchor.click();
    },
    async onUpload() {
      await Vue.swal({
        title: 'Upload file',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close'
      }).then(result => {
        if (result.isDenied) {
          this.createCsv();
        }
        if (result.value) {
          if (result.value.name === 'outboundCallerUploadList.csv') {
            const reader = new FileReader();
            reader.onload = async () => {
              csv({
                noheader: true,
                output: 'csv'
              })
                .fromString(reader.result)
                .then(async csvRow => {
                  const inputs = csvRow.slice(1, csvRow.length).map(i => {
                    const values = i.reduce((acc, curr, index) => {
                      if (index === 0) {
                        if (i[0].length !== 10) {
                          Vue.swal({
                            title: 'Access Denied',
                            text: `Mobile Number ${i[0]} must be 10 digits`,
                            type: 'error',
                            confirmButtonText: 'Ok',
                            icon: 'error'
                          });
                          return null;
                        }
                        acc.mobile = curr;
                      }
                      if (index === 1) {
                        if (i[1].length !== 4) {
                          Vue.swal({
                            title: 'Access Denied',
                            text: `Store Id ${i[1].charAt(0).toUpperCase() + i[1].slice(1)} must be 4 digits`,
                            type: 'error',
                            confirmButtonText: 'Ok',
                            icon: 'error'
                          });
                          return null;
                        }
                        const inUppercase = i[1].toUpperCase();
                        const compareStoreIdFromDatabse = this.newStoreList?.filter(
                          store => store.storeid === inUppercase
                        );
                        if (compareStoreIdFromDatabse.length === 0) {
                          Vue.swal({
                            title: 'Access Denied',
                            text: `Store id ${i[1].charAt(0).toUpperCase() +
                              i[1].slice(1)} is not found in database! Please enter correct store id.`,
                            type: 'error',
                            confirmButtonText: 'Ok',
                            icon: 'error'
                          });
                          return null;
                        }
                        acc.storeCode = curr.toUpperCase();
                      }
                      if (index === 2) {
                        // if (i[2] === true || i[2] === false) {
                        //   acc.priority = curr;
                        // } else {
                        //   Vue.swal({
                        //     title: 'Access Denied',
                        //     text: `Priority ${i[2]} must be true or false`,
                        //     type: 'error',
                        //     confirmButtonText: 'Ok',
                        //     icon: 'error'
                        //   });
                        //   return null;
                        // }
                        const inLowercase = i[2].toLowerCase();
                        acc.priority = inLowercase == 'true' ? true : false; // eslint-disable-line
                      }
                      if (index === 3) {
                        acc.callingDate = moment(curr, 'DD-MM-YYYY').format('YYYY-MM-DD');
                      }
                      if (index === 4) {
                        if (i[4] === '') {
                          Vue.swal({
                            title: 'Access Denied',
                            text: `Filter ${i[4]} can not be EMPTY`,
                            type: 'error',
                            confirmButtonText: 'Ok',
                            icon: 'error'
                          });
                          return null;
                        }
                        const inUppercase = i[4].toUpperCase();
                        acc.filterNew = inUppercase;

                        // const inUppercase = i[4].toUpperCase();
                        // acc.filterNew = inUppercase ? inUppercase : '';
                      }
                      return acc;
                    }, {});
                    return values;
                  });
                  this.upload({ data: inputs, router });
                });
            };
            reader.readAsBinaryString(result.value);
          } else {
            Vue.swal({
              title: 'Access Denied',
              text: 'Please upload correct file',
              type: 'error',
              confirmButtonText: 'Ok',
              icon: 'error'
            });
          }
        }
      });
    }
  }
};
</script>

<style></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
