<template>
  <div>
    {{ JSON.stringify(listCategory) }}
    <!-- <div style="color: Red">
      {{ selectedCategory }}
    </div> -->
    <div class="homeBtn-ctr">
      <button @click="goHome()" class="homeBtn">
        Home
      </button>
    </div>
    <div class="subCategory-product-container">
      <div class="subCategory">
        <div class="subHeading">
          <h5> E-Sub-Categories</h5>
        </div>
        <div id="child">
          <a
            class="singleSubCategory"
            @click="getProducts(subCategory)"
            v-for="subCategory in subCategories"
            :key="subCategory._id"
          >
            <div
              class="subCategoryImage"
              :style="`border: ${selectedSubCategory._id === subCategory._id ? '2.5px solid #f28a3f' : 'none'}; `"
            >
              <img v-bind:src="subCategory.imageUrl" class="imageSet" />
            </div>
            <div>
              <h6
                style="text-align: center; color: #717171"
                :style="`color: ${selectedSubCategory._id === subCategory._id ? '#f28a3f' : '#717171'}`"
              >
                {{ subCategory.name }}</h6
              >
            </div>
          </a>
        </div>
      </div>
      <div class="product">
        <div class="selectedHeading">
          <h4> {{ selectedCategory }}</h4>
        </div>
        <div class="allProducts" v-if="selectedSubCategory">
          <div id="child1">
            <b-row class="productRow" cols-xl="5" cols-lg="4" cols-md="3" cols-sm="3" cols-xs="2">
              <div v-if="loading" class="d-flex justify-content-center align-items-center">
                <span class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></span>
              </div>
              <div class="noneProducts" v-if="!loading && productsLength === 0"
                >Sorry there are no products available for the selected store please change the store or visit
                later...</div
              >
              <b-card no-body class="card_body wrappers" v-for="product in products" :key="product.id">
                <b-badge class="discount_badge" v-if="product.discount !== null && product.discount !== 0"
                  >{{ product.discount }}% off</b-badge
                >
                <b-badge class="offer_badge target" v-if="product.offers !== null">{{ product.offers }}</b-badge>
                <div class="imageControl">
                  <img class="card_image" v-bind:src="product.images" />
                </div>
                <div class="productname">{{ product.description }}</div>
                <div v-if="product.discount === null || product.discount === 0" class="productprice">
                  ₹ {{ product.mrp }}</div
                >
                <div style="display: flex" v-if="product.discount >= 1">
                  <div class="productprice" v-if="product.discount !== null"> ₹ {{ product.sellingPrice }}</div>
                  <div class="productprice cutPrice" style="font-weight: 400; text-decoration: line-through">
                    ₹ {{ product.mrp }}</div
                  >
                </div>
                <div>
                  <b-button
                    block
                    class="cartbutton"
                    v-if="!cartItems[product._id]"
                    variant="info"
                    @click="addToCart(product)"
                    >Add to Cart
                  </b-button>
                  <div class="quantity_btn_div" v-else>
                    <b-button class="plus_btn" @click="removeFromCart(product)">
                      <font-awesome-icon :icon="['fas', 'minus']" class="mr-1 arrow_icon"
                    /></b-button>
                    <div>
                      <b-form-input
                        class="text-center"
                        type="text"
                        :no-wheel="true"
                        min="0"
                        @keydown="handleKeyDown($event)"
                        @input="handleChange(product, $event)"
                        :value="cartItems[product._id].quantity"
                      ></b-form-input>
                    </div>
                    <b-button class="minus_btn" @click="addToCart(product)">
                      <font-awesome-icon :icon="['fas', 'plus']" class="mr-1 arrow_icon" />
                    </b-button>
                  </div>
                </div>
                <div>
                  <b-button block id="offer-modal" @click="showModal(product)">View Offers </b-button>
                </div>
              </b-card>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="offer-modal" :title="modalProducts.description" ok-only>
      <div v-if="!loading" class="table-row table-row-empty">
        <div class="text-center">
          <h2 v-html="handleProduct(this.modalProducts)"></h2>
        </div>
      </div>
      <div v-else class="table-row table-row-empty">
        <div class="text-center"
          >Fetching Offer...
          <span class="spinner spinner-black"></span>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import router from '@/router';
import moment from 'moment';

export default {
  name: 'SubCategories',
  data() {
    return {
      categories: [],
      selectedCategory: '',
      subCategories: [],
      selectedSubCategory: '',
      products: [],
      cartItems: {},
      modalProducts: {},
      modalOffers: {},
      todayDate: moment().format('YYYY-MM-DD')
    };
  },
  mounted() {
    this.listCategory({ router });
    const storeList = localStorage.getItem('storeData') || '{}';
    const store = JSON.parse(storeList);
    this.listCustomer({
      query: {
        storeId: store.storeid
      }
    });
    const cartItems = localStorage.getItem('product-cart') || '{}';
    this.cartItems = JSON.parse(cartItems);
    this.selectedCategory = this.$route.query.parent;
    // if (this.customerList.length > 0) {
    //   this.getProducts(this.selectedSubCategoryStore);
    // }
  },
  computed: {
    ...mapState('product', ['categoryList', 'customerList', 'selectedSubCategoryStore', 'offer', 'loading']),
    productsLength() {
      return this.products ? this.products.length : 0;
    }
  },
  methods: {
    ...mapActions('product', ['listCategory', 'listCustomer', 'setCartItem', 'setSelectedSubCategory', 'getOffer']),
    handleProduct(item) {
      let offer = '';
      if (item.scale3Csp !== 0) {
        offer = `BUY 1PC @${item.sellingPrice}EACH <br/> BUY ${item.scale2Qty}PC @${item.scale2Csp} EACH <br/> BUY ${item.scale3Qty} PC @${item.scale3Csp}EACH`;
      } else if (item.scale2Csp !== 0) {
        offer = `BUY 1PC @${item.sellingPrice}EACH <br/> BUY ${item.scale2Qty}PC @${item.scale2Csp} EACH`;
      } else {
        offer = `BUY 1PC @${item.sellingPrice}EACH`;
      }
      return offer;
    },
    getProducts(subCategory) {
      this.selectedSubCategory = subCategory;
      this.products = this.customerList.filter(
        i => i.category === this.selectedSubCategory.parent && i.subCategory === this.selectedSubCategory.name
      );
    },
    handleKeyDown(event) {
      if (
        !(
          (
            event.key.match(/^[0-9]$/) || // Allow digits 0-9
            event.key === 'Backspace' || // Allow Backspace key
            event.key === 'Delete'
          ) // Allow Delete key
        )
      ) {
        event.preventDefault(); // Prevent typing non-digit characters
      }
    },
    handleChange(product, event) {
      const cartItems = { ...this.cartItems };
      const productId = product._id; // eslint-disable-line

      if (cartItems[productId]) {
        const temp = Number(event.replace(/\D/g, ''));
        const quantity = temp;
        if (quantity < 0) {
          delete cartItems[productId];
        } else {
          cartItems[productId] = {
            ...cartItems[productId],
            quantity
          };
        }
      }
      this.cartItems = cartItems;
      this.setCartItem({ cartItems });
      window.localStorage.setItem('product-cart', JSON.stringify(cartItems));
    },
    addToCart(product) {
      const cartItems = { ...this.cartItems };
      const productId = product._id; // eslint-disable-line
      if (cartItems[productId]) {
        cartItems[productId] = {
          ...cartItems[productId],
          quantity: cartItems[productId].quantity + 1
        };
      } else {
        cartItems[productId] = { quantity: 1 };
      }
      this.cartItems = cartItems;
      this.setCartItem({ cartItems });
      window.localStorage.setItem('product-cart', JSON.stringify(cartItems));
    },
    removeFromCart(product) {
      const cartItems = { ...this.cartItems };
      const productId = product._id; // eslint-disable-line

      if (cartItems[productId]) {
        const quantity = cartItems[productId].quantity - 1;
        if (quantity < 0) {
          delete cartItems[productId];
        } else {
          cartItems[productId] = {
            ...cartItems[productId],
            quantity
          };
        }
      }
      this.cartItems = cartItems;
      this.setCartItem({ cartItems });
      window.localStorage.setItem('product-cart', JSON.stringify(cartItems));
    },
    goHome() {
      router.push(`/eCategoryList`);
    },
    showModal(product) {
      this.modalProducts = product;
      // this.getOffer({ articleId: product.articleCode, storeId: product.storeids[0], router: this.$router });
      this.$refs['offer-modal'].show();
    }
  },
  watch: {
    offer(newValue) {
      if (newValue) {
        const newPromo = newValue.delta;
        if (newPromo === 'NEW PROMO') {
          const validToDate = newValue.validTo;

          if (this.todayDate >= validToDate) {
            this.modalOffers = {};
          } else {
            this.modalOffers = newValue;
          }
        } else {
          this.modalOffers = {};
        }
      }
    },
    categoryList(newValue) {
      this.categories = newValue.filter(i => i.type === '1');
      this.subCategories = newValue.filter(i => i.type === '2' && i.parent === this.selectedCategory);
      this.selectedSubCategory = this.subCategories[0]; // eslint-disable-line
      this.setSelectedSubCategory({ subCategory: this.subCategories[0] });
    },
    customerList(newValue, oldValue) {
      if (newValue.length !== oldValue.length) {
        this.getProducts(this.selectedSubCategory);
      }
    },
    selectedSubCategoryStore(newValue) {
      this.getProducts(newValue);
    }
  }
};
</script>

<style>
.homeBtn-ctr {
  width: 53px;
  display: flex;
  border-radius: 26px;
  overflow: hidden;
  height: 53px;
  background-color: #eafdfc;
  align-content: center;
  position: fixed;
  right: 18px;
  bottom: 130px;
  z-index: 200;
  font-family: Myriad;
}

.homeBtn-ctr:hover {
  background-color: wheat;
  transform: scale(1.04, 1.04);
  /* transition-duration: 500ms; */
}

.homeBtn {
  border: none;
  width: 100%;
  background-color: #c0deff;
  color: black;
}

.homeBtn:hover {
  background-color: #62b6b7;
  transition-duration: 500ms;
}

.noneProducts {
  text-align: center;
  font-weight: 500;
  font-size: 20ps;
  margin-top: 10%;
}

.imageSet {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.subHeading {
  align-content: center;
  margin: auto;
  text-align: center;
}

.productRow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* height: 100%; */
  /* border: 1px solid; */
  /* overflow-x: scroll; */
}

.card_body {
  margin: 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  border-radius: unset;
  border-radius: 10px;
  height: 100%;
  /* border: 1px solid; */
}

#child1 {
  /* width: 100%; */
  /* height: 125vh; */
  /* overflow-y: scroll; */
  /* padding-right: 19px; */
  box-sizing: content-box;
  /* border: 1px solid; */
}

.card_body:hover {
  box-shadow: #eeb790 0px 3px 9px;
}

/* @media screen and (max-width: 575px) {
  .card_body{
    width: 42%;
  }
} */
.wrappers {
  overflow-x: hidden;
  overflow-y: hidden;
}

.discount_badge {
  width: 85px;
  height: 20px;
  padding: 5px;
  border-radius: 1px 15px 1px 18px;
  position: absolute;
  top: -2px;
  background: #f28a3f;
  transform: translate(1%, 60%);
  z-index: 10;
}

.offer_badge {
  height: 20px;
  border-radius: 15px 80px;
  position: absolute;
  background: #f28a3f;
  transform: translate(1%, 60%);
}

.target {
  margin-top: 10px;
  animation-name: rightToLeft;
  animation-duration: 8s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.imageControl {
  align-self: center;
  /* border: 1px solid; */
  margin: 32px 0 5px 0;
  position: relative;
  width: 10rem;
  /* margin: auto; */
  border-radius: 10px;
  overflow: hidden;
  height: 11rem;
  height: 10rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card_image {
  width: 100%;
  /* padding: 0.5rem; */
  /* margin: 32px 15px 5px 4px; */
  /* height: 11rem; */
  max-width: 100%;
  min-width: 50%;
  max-height: 100%;
  /* min-height: 80%; */
  /* border: 1px solid;
  align-self: center;
  display: block;
  margin: auto; */
}

.productname {
  color: #383838;
  font-size: 17px;
  font-weight: 500;
  padding-left: 8px;
  width: auto;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.productname:hover {
  white-space: normal;
}

.productprice {
  color: #383838;
  font-size: 15px;
  font-weight: 500;
  padding-left: 8px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.cartbutton {
  background-color: #f28a3f;
  margin-bottom: -0.1rem;
  border: 1px solid #f28a3f;
  border-radius: unset;
}

.cartbutton:hover {
  background-color: #f28a3f;
  border: transparent;
}

.quantity_btn_div {
  display: flex;
  justify-content: space-between;
  border: 1px solid #f28a3f;
  border-radius: 2px;
}

.quantity_btn_div > span {
  color: #383838;
  font-size: 25px;
  margin-left: 9%;
  font-weight: 400;
}

.plus_btn {
  width: 35%;
  margin: 1px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 15px;
  font-weight: 900;
  border-radius: 2px;
  background-color: #f28a3f;
}

.minus_btn {
  width: 35%;
  outline: none;
  border: none;
  padding: 0px;
  color: #fff;
  font-size: 15px;
  font-weight: 900;
  margin: 1px 1px 1px auto;
  border-radius: 2px;
  background-color: #f28a3f;
}

.singleSubCategory {
  position: relative;
  text-decoration: none;
  box-sizing: content-box;
}

h5 {
  font-size: 15px;
}

.singleSubCategory:hover {
  cursor: pointer;
  border-left: 1px solid #eeb790;
  text-decoration: none;
}

.subCategory-product-container {
  display: flex;
  position: relative;
  padding-top: 2%;
  width: 100%;
  padding-inline: 5%;
  gap: 5%;
  font-family: Myriad;
}

.subCategory {
  overflow: hidden;
  display: flex;
  /* position: relative; */
  flex-direction: column;
  height: 100vh;
  box-shadow: 0 4px 4px #00000040;
  padding: 0.8%;
  padding-top: 30px;
  width: 190px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

#child {
  padding-left: 5px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 30px;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  gap: 5%;
  margin-top: 30px;
  box-sizing: content-box;
}

.selectedHeading {
  text-align: center;
  margin: 5vh;
}

.product {
  width: 100%;
  position: relative;
}

.allProducts {
  width: 100%;
  overflow: hidden;
}

.subCategoryImage {
  width: 100%;
  height: 110px;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

@keyframes rightToLeft {
  0% {
    transform: translateX(250px);
  }

  100% {
    transform: translateX(-200px);
  }
}

@media screen and (max-width: 300px) {
  .search_input > input {
    width: 7rem;
    padding: 0px;
  }

  .search_icon {
    padding: 0px;
    margin-left: 4px;
  }

  .card_image {
    max-width: 5rem;
    min-width: 5rem;
    max-height: 5rem;
    min-height: 6rem;
  }

  .quantity_btn_div {
    height: 35px;
  }

  .quantity_btn_div > span {
    font-size: 15px;
    font-weight: 700;
    margin: 0.6rem 0.2rem 0.5rem 0.4rem;
  }

  .cartbutton {
    font-size: 10px;
  }
}

@media screen and (max-width: 880px) {
  .imageControl {
    width: 80%;
    /* height: 10rem; */
  }
}

@media screen and (max-width: 575px) {
  .card_body {
    width: 40%;
  }
}

@media screen and (max-width: 450px) {
  .imageControl {
    height: 8rem;
    width: 9rem;
  }
}

@media screen and (max-width: 650px) {
  .subCategory-product-container {
    flex-direction: column;
    gap: 1rem;
    padding: 5px;
  }

  h5 {
    font-size: 12px;
  }

  .subCategory {
    flex-direction: row;
    height: 125px;
    width: 100%;
    padding-top: 0;
    align-content: center;
    box-shadow: 0 2px 2px #00000040;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #fff;
  }

  .singleSubCategory {
    margin-inline: 5px;
  }

  .singleSubCategory:hover {
    border-left: none;
  }

  #child {
    flex-direction: row;
    overflow: scroll;
    overflow-y: hidden;
    margin-top: 3px;
    padding-bottom: 25px;
  }

  h4 {
    font-size: 20px;
  }

  .subCategoryImage {
    width: 90px;
    height: 100px;
  }

  .selectedHeading {
    margin: 2vh;
  }
}
</style>
