<template>
  <div class="page-class page-user-list">
    <h1 class="page-title">{{ title }}</h1>
    <!-- {{ JSON.stringify(reportList) }} -->
    <B2B-report-box
      :columns="columns"
      :rows="reportList"
      :baseUrl="baseUrl"
      :loading="loading"
      :showDownload="true"
      :showToolbar="true"
      downloadText="Download Report"
      emptyText="No Data found."
      @download="onExportCSV"
    >
    </B2B-report-box>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import B2BReportBox from '../../components/B2BReportBox.vue';

export default {
  name: 'B2BReport',
  components: {
    B2BReportBox
  },
  metaInfo() {
    return {
      title: 'B2B Report',
      meta: [
        {
          name: 'description',
          content: `Manage Report`
        }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.list({
        query: {
          ...this.$route.query,
          startdate: moment().format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.list({
        query: this.$route.query
      });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.list({
      query: to.query
    });
    next();
  },
  data() {
    return {
      title: 'B2B BDA Wise ReportList',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: {
            'text-center': true
          },
          textKey: 'index',
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'BDA Name',
          // class: {
          //   'text-center': true
          // },
          textKey: 'bdaName',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          // class: {
          //   'text-center': true
          // },
          textKey: 'storeId',
          width: '4%'
        },
        {
          type: 'number',
          headerText: 'Total Order Created',
          class: {
            'text-center': true
          },
          textKey: 'totalOrderCreated',
          width: '8%'
        },
        {
          type: 'number',
          headerText: 'Total Item Count',
          class: {
            'text-center': true
          },
          textKey: 'totalItemCount',
          width: '9%'
        },
        {
          type: 'number',
          headerText: 'Sum Of Quantity Ordered',
          class: {
            'text-center': true
          },
          textKey: 'sumOfQuantity',
          width: '13%'
        },
        {
          type: 'number',
          headerText: 'Sum Of CartValue',
          textKey: 'sumOfCartValue',
          width: '10%'
        },
        {
          type: 'number',
          headerText: 'Total Challan Generated',
          class: {
            'text-center': true
          },
          textKey: 'totalChallanGenerated',
          width: '9%'
        },
        {
          type: 'number',
          headerText: 'Total Item Billed',
          class: {
            'text-center': true
          },
          textKey: 'totalItemBilled',
          width: '10%'
        },
        {
          type: 'number',
          headerText: 'Sum Of Quantity Billed',
          textKey: 'sumOfQuantityBilled',
          width: '12%'
        },
        {
          type: 'number',
          headerText: 'Sum Of Challan Value',
          textKey: 'sumOfChallanValue',
          width: '12%'
        }
      ]
      // searchItems: ['Bill Number', 'Store Id']
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('B2BReport', ['downloadReport', 'loading', 'baseUrl', 'reportList'])
  },
  watch: {
    downloadReport(newValue) {
      if (newValue.length) {
        let csv =
          'BDA Name,Store Id,TotalOrderCreated,TotalItemCount,SumOfQuantity Ordered,SumOfCartValue,TotalChallanGenerated,TotalItemBilled, SumOfQuantityBilled,SumOfChallanValue \n';
        newValue.forEach(row => {
          csv += `${row.bdaName},${row.storeId},${row.totalOrderCreated},${row.totalItemCount},${row.sumOfQuantity},${row.sumOfCartValue},${row.totalChallanGenerated},${row.totalItemBilled},${row.sumOfQuantityBilled},${row.sumOfChallanValue}\n`;
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'B2B BDA Wise ReportList.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('B2BReport', ['list', 'download']),
    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({
          query: {
            ...this.$route.query
          }
        });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            startdate: moment()
              .subtract(1, 'day')
              .format('MMM YYYY')
          }
        });
      }
    }
  }
};
</script>
