<template>
  <div class="page-class page-user-list">
    <h1 class="page-title">{{ title }}</h1>
    <!-- {{ this.orderReportList }} -->
    <report-box-f
      :columns="columns"
      :rows="orderData"
      :baseUrl="baseUrl"
      :loading="loading"
      :showDownload="true"
      :showToolbar="true"
      :searchItems="searchItems"
      downloadText="Download Orders Report"
      emptyText="No Data found."
      @download="onExportCSV"
    >
    </report-box-f>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
// import router from '@/router';
import reportBoxF from '../../components/reportBoxF.vue';

export default {
  name: 'OrderReport',
  components: {
    reportBoxF
  },
  metaInfo() {
    return {
      title: 'Order Report',
      meta: [
        {
          name: 'description',
          content: `Manage Report`
        }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.list({
        query: {
          ...this.$route.query,
          startdate: moment().format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.list({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.list({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Order Live Summary',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'index',
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'Region',
          class: { 'text-center': true },
          textKey: 'storeRegion',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Cluster',
          class: { 'text-center': true },
          textKey: 'storeCluster',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Store Name',
          class: { 'text-center': true },
          textKey: 'storeName',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: '_id',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Store Format',
          class: { 'text-center': true },
          textKey: 'storeFormat',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Order Created',
          class: { 'text-center': true },
          textKey: 'orderCreated',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Order Acknowledged',
          class: { 'text-center': true },
          textKey: 'orderAnknowleged',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Picking Done',
          class: { 'text-center': true },
          textKey: 'pickingDone',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Challan Generated',
          class: { 'text-center': true },
          textKey: 'challanGenerated',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Challan Sale Value',
          class: { 'text-center': true },
          textKey: 'challanValue',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Delivered',
          class: { 'text-center': true },
          textKey: 'delivered',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Total',
          class: { 'text-center': true },
          textKey: 'total',
          width: '7%'
        }
      ],
      searchItems: ['Store Id']
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('orderReport', ['downloadOrderReport', 'loading', 'baseUrl', 'orderReportList']),
    orderData() {
      return this.orderReportList.map(i => ({
        ...i,
        challanValue: parseInt(i.challanValue) // eslint-disable-line
      }));
    }
  },
  watch: {
    downloadOrderReport(newValue) {
      if (newValue.length) {
        let csv =
          'Row Num,Region,Cluster,Store Name,Store Id,Store Format, Order Created, Order Acknowledged, Picking Done, Challan Generated,Challan Sale Value,Delivered,Total \n';
        newValue.forEach(row => {
          Object.values(row).forEach(val => {
            csv += `"${val}"`;
            csv += ',';
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'OrdersReport.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('orderReport', ['list', 'download']),
    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            startdate: moment().subtract(1, 'day').format('MMM YYYY')
          }
        });
      }
    }
  }
};
</script>
