<template>
  <div class="page-class page-user-list">
    <h1 class="page-title">{{ title }}</h1>
    <order-report-box
      :columns="columns"
      :rows="salesData"
      :baseUrl="baseUrl"
      :loading="loading"
      :showDownload="true"
      :showToolbar="true"
      :searchItems="searchItems"
      downloadText="Download Sales Report"
      emptyText="No Data found."
      @download="onExportCSV"
    >
    </order-report-box>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import orderReportBox from '../../components/orderReportBox.vue';

export default {
  name: 'SalesReport',
  components: {
    orderReportBox
  },
  metaInfo() {
    return {
      title: 'Sales Report',
      meta: [
        {
          name: 'description',
          content: `Manage Report`
        }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.list({
        query: {
          ...this.$route.query,
          startdate: moment().format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.list({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.list({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Sales ReportList',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'index',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Region',
          class: { 'text-center': true },
          textKey: 'storeRegion',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Cluster',
          class: { 'text-center': true },
          textKey: 'storeCluster',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Store Name',
          class: { 'text-center': true },
          textKey: 'storeName',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: '_id',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Store Format',
          class: { 'text-center': true },
          textKey: 'storeFormat',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Total NOB',
          class: { 'text-center': true },
          textKey: 'totalNOB',
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Total Sale',
          class: { 'text-center': true },
          textKey: 'totalSale',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'ABV',
          class: { 'text-center': true },
          textKey: 'abv',
          width: '16%'
        }
      ],
      searchItems: ['Bill Number', 'Store Id']
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('salesReport', ['downloadReport', 'loading', 'baseUrl', 'reportList']),
    salesData() {
      return this.reportList.map(i => ({
        ...i,
        totalSale: parseInt(i.totalSale), // eslint-disable-line
        abv: parseInt(i.abv) // eslint-disable-line
      }));
    }
  },
  watch: {
    downloadReport(newValue) {
      if (newValue.length) {
        let csv = 'Row Num,Region,Cluster,Store Name,Store Id,Store Format, Total NOB, Total Sale, ABV \n';
        newValue.forEach(row => {
          Object.values(row).forEach(val => {
            csv += `"${val}"`;
            csv += ',';
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'SalesReport.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('salesReport', ['list', 'download']),
    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            startdate: moment().subtract(1, 'day').format('MMM YYYY')
          }
        });
      }
    }
  }
};
</script>
