<template>
  <div>
    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group id="group-mobile" label-for="input-mobile">
          <template v-slot:label>
            Mobile
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="input-mobile"
            v-model="form.mobile"
            type="number"
            :disabled="formType !== 'new'"
            placeholder="Enter Mobile Number"
            :state="$v.form.mobile.$dirty ? !$v.form.mobile.$error : null"
          ></b-form-input>

          <b-form-text id="input-mobile-help">Mobile Number must be a valid 10 digit long number.</b-form-text>

          <b-form-invalid-feedback id="input-email-invalid">Please enter valid mobile number.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="group-name" label-for="input-name">
          <template v-slot:label>
            Name
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            id="input-name"
            type="text"
            v-model="form.name"
            :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
            placeholder="Enter Name"
          ></b-form-input>

          <b-form-invalid-feedback id="input-name-invalid">Please enter name.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="group-password" label-for="input-password">
          <template v-slot:label>
            Password
            <span class="text-danger" v-if="formType === 'new'">*</span>
          </template>

          <b-form-input
            autocomplete="new-password"
            id="input-password"
            type="password"
            v-model="form.password"
            :state="$v.form.password.$dirty ? !$v.form.password.$error : null"
            placeholder="Enter Password"
          ></b-form-input>

          <b-form-text id="input-password-help">Password must be more than 6 characters.</b-form-text>

          <b-form-invalid-feedback id="input-password-invalid">Please enter valid password.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label> Roles <span class="text-danger">*</span> </template>
          <multiselect
            v-model="roles"
            ref="multiselect"
            tag-placeholder="Add this as new tag"
            placeholder="Select Roles"
            label="text"
            track-by="text"
            :options="userRoles"
            :multiple="true"
            :taggable="true"
            @tag="addTag"
          >
          </multiselect>
          <b-form-text id="input-role-help"
            >Role field is configuring whether the user is administrator or staff.</b-form-text
          >
        </b-form-group>

        <b-form-group v-if="ifAdmin()" id="group-products" label-for="input-products">
          <template v-slot:label> Clusters </template>
          <multiselect
            v-model="clusters"
            ref="multiselect"
            tag-placeholder="Add this as new tag"
            placeholder="Select Clusters"
            label="text"
            track-by="text"
            :options="clusterIds"
            :multiple="true"
            :taggable="true"
            @tag="addTag"
          ></multiselect>
          <b-form-text id="input-role-help">Please select the cluster(s) where the user is operational.</b-form-text>
        </b-form-group>

        <b-form-group v-if="ifAdmin()" id="group-products" label-for="input-products">
          <template v-slot:label> Regions </template>
          <multiselect
            v-model="regions"
            ref="multiselect"
            tag-placeholder="Add this as new tag"
            placeholder="Select Regions"
            label="text"
            track-by="text"
            :options="regionIds"
            :multiple="true"
            :taggable="true"
            @tag="addTag"
            :disabled="this.roles.some(role => role.text === 'TELLECALLER')"
          ></multiselect>
          <b-form-text id="input-role-help">Please select the region(s) where the user is operational.</b-form-text>
        </b-form-group>

        <b-form-group id="group-type" label-for="input-type">
          <template v-slot:label>
            User Type
            <span class="text-danger" v-if="formType === 'new'">*</span>
          </template>

          <multiselect
            label="text"
            id="input-type"
            v-model="form.type"
            placeholder="Select User Type"
            :options="usersType"
          ></multiselect>
        </b-form-group>

        <b-form-group id="group-role" label-for="input-role" v-if="userType === 'staff'">
          <template v-slot:label>
            Role Under
            <span class="text-danger">*</span>
          </template>

          <b-form-select
            id="input-role"
            v-model="form.role"
            :options="staffRoles"
            :state="$v.form.role.$dirty ? !$v.form.role.$error : null"
          ></b-form-select>

          <b-form-text id="input-role-help"
            >Role field is configuring whether the user is administrator or staff.</b-form-text
          >

          <b-form-invalid-feedback id="input-role-invalid">Please select valid role.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="group-empId" label-for="input-empid">
          <template v-slot:label>
            Employee Id
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            id="input-empid"
            type="text"
            v-model="form.empid"
            :state="$v.form.empid.$dirty ? !$v.form.empid.$error : null"
            placeholder="Enter Employee Id"
          ></b-form-input>

          <b-form-invalid-feedback id="input-empid-invalid">Please enter Employee Id.</b-form-invalid-feedback>
          <b-form-text id="input-empid-help">Please enter the id of employee</b-form-text>
        </b-form-group>

        <b-form-group id="group-designation" label-for="input-designation">
          <template v-slot:label>
            Designation
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            id="input-designation"
            type="text"
            v-model="form.designation"
            :state="$v.form.designation.$dirty ? !$v.form.designation.$error : null"
            placeholder="Enter Designation"
          ></b-form-input>
          <b-form-text id="input-designation-help">Please enter the designation of employee</b-form-text>
          <b-form-invalid-feedback id="input-designation-invalid"
            >Please enter employee designation.</b-form-invalid-feedback
          >
        </b-form-group>
        <!--
        <b-form-group v-if="form.role === 99">
          <template v-slot:label>
            Store Id
            <span class="text-danger">*</span>
          </template>
          <model-select :options="options" v-model="storeid" placeholder="Select Store Id"> </model-select>
          <b-form-text id="input-role-help">Please select a store id you want to associate with this user.</b-form-text>
        </b-form-group> -->

        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label> Store Ids <span class="text-danger">*</span> </template>
          <multiselect
            v-model="storeids"
            ref="multiselect"
            tag-placeholder="Add this as new tag"
            placeholder="Select Store Ids"
            label="text"
            track-by="value"
            :options="options"
            :multiple="true"
            :taggable="true"
            @tag="addTag"
          ></multiselect>
          <b-form-text id="input-role-help"
            >Note: The first selected store id will be the primary store id of user for all operations.</b-form-text
          >
          <b-button v-if="ifAdmin()" @click="uploadStores" variant="success">Upload Stores</b-button>
        </b-form-group>

        <b-form-group id="group-status" label-for="input-status">
          <template v-slot:label>
            Status
            <span class="text-danger">*</span>
          </template>

          <b-form-select
            id="input-status"
            v-model="form.status"
            :options="userEnabled"
            :state="$v.form.status.$dirty ? !$v.form.status.$error : null"
          ></b-form-select>

          <b-form-text id="input-status-help"
            >Status field is configuring whether the user is active or deleted.</b-form-text
          >

          <b-form-invalid-feedback id="input-status-invalid">Please select valid status.</b-form-invalid-feedback>
        </b-form-group>

        <template v-if="errorMessages">
          <b-row class="mb-2">
            <b-col class="text-danger message-col">{{ errorMessages }}</b-col>
          </b-row>
        </template>

        <template v-if="storeIdError">
          <b-row class="mb-2">
            <b-col class="text-danger message-col">{{ storeIdError }}</b-col>
          </b-row>
        </template>

        <b-row>
          <b-col>
            <b-button type="submit" size="sm" variant="success" :disabled="loading">
              <span class="spinner spinner-white" v-if="loading"></span>
              <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button size="sm" variant="warning" :to="{ path: `${listUrl}` }">
              <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import csv from 'csvtojson';
import Vue from 'vue';
import { numeric, maxLength, required, minLength } from 'vuelidate/lib/validators';
import { mapGetters, mapState, mapActions } from 'vuex';
import _ from 'lodash';
import User from '@/model/user';
import configService from '@/services/configService';
import router from '@/router';
// import { ModelSelect } from 'vue-search-select';
import Multiselect from 'vue-multiselect';

export default {
  name: 'UserFormBox',
  components: {
    // ModelSelect,
    Multiselect
  },
  props: {
    listUrl: String,
    userType: String,
    formType: String,
    userId: {
      type: Number,
      required: false
    }
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      title: '',
      options: [],
      storeids: [],
      storeid: {
        value: '',
        text: ''
      },
      roles: [],
      clusters: [],
      regions: [],
      clusterIds: [],
      regionIds: [],
      formLoaded: false,
      regionTempStoreIds: [],
      totalStoreIds: [],
      // clusterTempStoreIds: [],
      adminStoreList: [],
      form: {
        mobile: null,
        name: null,
        password: null,
        role: null,
        status: null,
        type: null,
        empid: null,
        designation: null
      },
      storeIdError: null,
      dateTimeFormat: configService.get('format').pickerDateTime,
      staffRoles: _.reduce(
        _.pick(User.userRole, ['administrator', 'staff', 'caller']),
        (result, value, key) => {
          result.push({ value, text: User.nameUserRole[User.userRole[key]] });
          return result;
        },
        []
      ),
      usersType: _.reduce(
        User.usersType,
        (result, value, key) => {
          result.push({ value, text: _.capitalize(key) });
          return result;
        },
        []
      ),
      userRoles: [],
      userEnabled: _.reduce(
        User.userEnabled,
        (result, value, key) => {
          result.push({ value, text: _.capitalize(key) });
          return result;
        },
        []
      )
    };
  },
  validations() {
    const formValidation = {
      mobile: {
        required,
        numeric,
        maxLength: maxLength(10),
        minLength: minLength(10)
      },
      name: {
        required
      },
      empid: {
        required
      },
      designation: {
        required
      },
      password: {
        minLength: minLength(6)
      },
      role: {
        validateRole: value => {
          if (this.userType === 'user') {
            return true;
          }
          return _.some(User.userRole, role => value === role);
        }
      },
      status: {
        validateStatus: value => {
          return _.some(User.userEnabled, enabled => value === enabled);
        }
      }
    };

    if (this.formType === 'new') {
      formValidation.password.required = required;
    }

    return { form: formValidation };
  },
  mounted() {
    this.listStore({ router });
    this.listCluster({ router, query: { type: 'cluster' } });
    this.listRegion({ router, query: { type: 'region' } });
    this.listStoreDetail({ router });
    this.getRoles();
    this.$nextTick(async () => {
      // Code that will run only after the entire view has been re-rendered
      if (this.formType === 'new') {
        if (this.userType === 'staff') {
          this.form.role = User.userRole.staff;
        }
        this.form.status = User.userEnabled.active;
        this.formLoaded = true;
        this.$v.$touch(); // Set initial validation
        this.$v.$reset(); // Reset $dirty
      }
    });
  },
  computed: {
    metaDescription() {
      return this.formType === 'new' ? 'Add new user' : 'Update user';
    },
    ...mapGetters('alert', ['errorMessages']),
    ...mapState('user', ['loading', 'user', 'availableRoles']),
    ...mapState('store', ['storeList', 'regionList', 'clusterList', 'storeListDetail']),
    ...mapState('auth', {
      authUser: 'user'
    }),
    showPermissions() {
      return this.userType === 'staff' && this.form.role === User.userRole.staff;
    }
  },
  methods: {
    ...mapActions('store', ['listStore', 'listRegion', 'listCluster', 'listStoreDetail']),
    ...mapActions('user', ['getRoles']),
    ifAdmin() {
      const newUser = this.authUser.roles.map(role => role.name);
      const admin = newUser.filter(role => role.includes('ADMIN'));
      if (admin.length > 0) {
        return true;
      }
      return false;
    },
    isSubAdmin() {
      if (this.authUser) {
        const filterSubAdmin = this.authUser.roles.filter(role => role.name === 'SUB_ADMIN_PD');
        if (filterSubAdmin.length > 0) {
          return true;
        }
      }
      return false;
    },
    onSubmit() {
      this.storeIdError = null;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      const storeids = [];
      const roles = [];
      this.roles.map(role => roles.push(role.value));
      const clusters = [];
      this.clusters.map(cluster => clusters.push(cluster.value));
      const regions = [];
      this.regions.map(region => regions.push(region.value));

      // if (this.form.role === 99) {
      // if (!this.storeids) {
      //   this.storeIdError = 'Please select store id.';
      //   return false;
      // }
      // storeids.push(this.storeid.value);
      // } else {
      if (!this.storeids.length) {
        this.storeIdError = 'Please select at least one store id.';
        return false;
      }
      this.storeids.map(({ value }) => storeids.push(value));
      // }
      let uniqueStore = new Set(storeids);
      uniqueStore = Array.from(uniqueStore).filter(item => item !== '');
      const user = {
        mobile: parseInt(this.form.mobile, 10),
        name: this.form.name,
        password: this.form.password,
        role: this.form.role ? this.form.role : User.userRole.user,
        status: this.form.status,
        type: this.form.type.value,
        empid: this.form.empid,
        designation: this.form.designation,
        storeids: Array.from(uniqueStore),
        roles,
        clusters: clusters || '',
        regions: regions || ''
      };
      if (this.formType === 'new') {
        this.$emit('add', { user });
      } else {
        this.storeids = Array.from(uniqueStore);
        this.$emit('edit', { user });
      }

      return false;
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
      setTimeout(() => {
        this.$refs.multiselect.$refs.search.focus();
      }, 100);
    },
    createCsv() {
      const csv = `Store Id\n`;
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'sampleFile.csv';
      anchor.click();
    },
    async uploadStores() {
      await Vue.swal({
        title: 'Upload file for Stores..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your Store file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        denyButtonText: 'Download Sample',
        denyButtonColor: '#3a4',
        showCancelButton: true,
        showDenyButton: true,
        cancelButtonText: 'Close'
      }).then(result => {
        if (result.isDenied) {
          this.createCsv();
        }
        if (result.value) {
          const reader = new FileReader();
          reader.onload = async () => {
            csv({
              noheader: true,
              output: 'csv'
            })
              .fromString(reader.result)
              .then(csvRow => {
                const inputs = csvRow.slice(1, csvRow.length).map(item => {
                  if (item[0].length !== 4) {
                    Vue.swal({
                      title: 'Access Denied',
                      text: `Store Id ${item[0].charAt(0).toUpperCase() + item[0].slice(1)} must be 4 digits`,
                      type: 'error',
                      confirmButtonText: 'Ok'
                    });
                    return null;
                  }
                  const inUppercase = item[0].toUpperCase();
                  const compareStoreIdFromDatabse = this.newStoreList.filter(store => store.storeid === inUppercase);
                  if (compareStoreIdFromDatabse.length === 0) {
                    Vue.swal({
                      title: 'Access Denied',
                      text: `Store id ${item[0].charAt(0).toUpperCase() +
                        item[0].slice(1)} is not found in database! Please enter correct store id.`,
                      type: 'error',
                      confirmButtonText: 'Ok'
                    });
                    return null;
                  }
                  return {
                    value: item[0].charAt(0).toUpperCase() + item[0].slice(1),
                    text: item[0].charAt(0).toUpperCase() + item[0].slice(1)
                  };
                });
                const uniqueStoreId = new Set(inputs.map(input => input.value));
                const getDuplicateValue = [...uniqueStoreId].filter(
                  item => inputs.filter(input => input.value === item).length > 1
                );
                if (getDuplicateValue.length > 0) {
                  Vue.swal({
                    title: 'Access Denied',
                    text: `Store id ${getDuplicateValue[0]} is duplicate in sample file!`,
                    type: 'error',
                    confirmButtonText: 'Ok'
                  });
                  return null;
                }
                this.abc = inputs.map(store => ({
                  value: store.value,
                  text: store.value
                }));
                const newAddingStore = this.abc.map(store => store.value);
                const foundExistStore = this.storeids.filter(store => newAddingStore.includes(store.value));
                if (foundExistStore.length > 0) {
                  Vue.swal({
                    title: 'Access Denied',
                    text: `Store id ${foundExistStore[0].value} is already assigned to user!`,
                    type: 'error',
                    confirmButtonText: 'Ok'
                  });
                  return null;
                }
                this.storeids = [...this.storeids, ...this.abc];
                return null;
              });
          };
          reader.readAsBinaryString(result.value);
        }
      });
    }
  },
  watch: {
    //
    regions(newValue) {
      if (newValue.length > 0) {
        const region = newValue.map(item => item.text);
        const regionIds = this.storeListDetail.filter(item => region.includes(item.region));
        const matchStoreIds = regionIds.filter(item => this.authUser.storeids.includes(item.storeid));
        const newStoreIds = regionIds
          .filter(item => !matchStoreIds.includes(item.storeid))
          .map(item => {
            return { text: item.storeid, value: item.storeid };
          })
          .filter((value, index, self) => {
            return self.findIndex(v => v.value === value.value) === index;
          });

        this.storeids = [...this.storeids, ...newStoreIds];
      } else {
        this.storeids = [];
      }
      this.storeids = this.storeids.filter((value, index, self) => {
        return self.findIndex(v => v.value === value.value) === index;
      });
    },
    clusters(newValue) {
      if (newValue.length > 0) {
        const cluster = newValue.map(item => item.text);
        const clusterIds = this.storeListDetail.filter(item => cluster.includes(item.cluster));
        const matchStoreIds = clusterIds.filter(item => this.authUser.storeids.includes(item.storeid));
        const newStoreIds = clusterIds
          .filter(item => !matchStoreIds.includes(item.storeid))
          .map(item => {
            return { text: item.storeid, value: item.storeid };
          })
          .filter((value, index, self) => {
            return self.findIndex(v => v.value === value.value) === index;
          });
        this.storeids = [...this.storeids, ...newStoreIds];
      } else {
        this.storeids = [];
      }
      this.storeids = this.storeids.filter((value, index, self) => {
        return self.findIndex(v => v.value === value.value) === index;
      });
    },
    storeList(_newValue) {
      let comparingStores = [];
      comparingStores = _newValue.map(store => ({
        storeid: store.value
      }));
      this.newStoreList = comparingStores;
      this.options = _newValue;
      if (this.authUser.role !== 99) {
        this.staffRoles = [
          { text: 'Staff', value: 50 },
          { text: 'Outbound Caller', value: 25 }
        ];
        this.options = this.authUser.storeids.map(store => ({ text: store, value: store }));
      }
    },
    clusterList(newValue) {
      this.clusterIds = newValue;
    },
    regionList(newValue) {
      this.regionIds = newValue;
    },
    user(_newValue, _oldValue) {
      if (!this.user.id) {
        return;
      }

      // Loaded user, assign to form
      this.form.mobile = this.user.mobile;
      this.form.name = this.user.name;
      this.form.role = this.user.role;
      this.form.status = this.user.status;
      this.form.type = { text: User.nameUserType[this.user.type], value: this.user.type };
      this.form.empid = this.user.empid;
      this.form.designation = this.user.designation;

      this.formLoaded = true;

      if (this.user.storeids.length) {
        this.storeid = { text: this.user.storeids[0], value: this.user.storeids[0] };
        this.storeids = this.user.storeids.map(id => ({ text: id, value: id }));
      }
      if (this.user.roles.length) {
        this.roles = this.user.roles.map(({ name, _id }) => ({ text: name, value: _id }));
      }

      if (this.user.clusters.length) {
        this.clusters = this.user.clusters.map(text => ({ text, value: text }));
      }

      if (this.user.regions.length) {
        this.regions = this.user.regions.map(text => ({ text, value: text }));
      }

      this.$v.$touch(); // Set initial validation
      this.$v.$reset(); // Reset $dirty
    },
    storeids(newValue) {
      if (this.roles.some(role => role.text === 'TELLECALLER')) {
        if (newValue.length > 5) {
          this.storeids.pop();
          Vue.swal({
            title: 'Access Denied',
            text: `TELLECALLER can only select up to five store IDs.`,
            type: 'error',
            confirmButtonText: 'Ok'
          });
        }
      }
    },
    availableRoles(newValue) {
      if (this.isSubAdmin()) {
        this.userRoles = newValue.map(item => ({ text: item.name, value: item.id }));
      } else if (this.authUser.role === 50) {
        this.userRoles = newValue
          .filter(item => item.name !== 'TELLECALLER' || item.id !== '6221ab96ea24917b9f37cd52')
          .map(item => ({ text: item.name, value: item.id }));
      } else {
        this.userRoles = newValue.map(item => ({ text: item.name, value: item.id }));
      }
    }
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
