<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title">{{ title }}</h1>
      <user-form-box
        list-url="/staff"
        user-type="staff"
        :form-type="formType"
        :mobile="mobile"
        @add="onAdd"
        @edit="onEdit"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import UserFormBox from '@/components/UserFormBox.vue';
import router from '@/router';

export default {
  name: 'StaffForm',
  components: {
    UserFormBox
  },
  async mounted() {
    if (this.$route.name === 'staff-new') {
      this.formType = 'new';
      this.mobile = null;
    } else {
      this.formType = 'update';
      this.mobile = parseInt(this.$route.params.id, 10);
      await this.getOne({
        type: 'staff',
        mobile: this.mobile,
        router
      });
    }
  },
  data() {
    return {
      formType: '',
      mobile: null
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Add new staff' : 'Update staff';
    },
    ...mapState('user', ['loading'])
  },
  methods: {
    ...mapActions('user', ['getOne', 'postOne', 'patchOne']),
    onAdd({ user }) {
      this.postOne({
        type: 'staff',
        user,
        router,
        redirectUrl: '/staff'
      });
    },
    onEdit({ user }) {
      this.patchOne({
        type: 'staff',
        mobile: this.mobile,
        user,
        router,
        redirectUrl: '/staff'
      });
    }
  }
};
</script>
