<template>
  <div class="page-class page-user-list">
    <h1 class="page-title">{{ title }}</h1>
    <b-button size="sm" variant="success" class="mb-2" @click="clickDownload">
      <font-awesome-icon :icon="['fas', 'download']" class="mr-1" />Download
    </b-button>
    <h3>NOB</h3>
    <order-report-box
      :columns="columns"
      :rows="categoryListNob"
      :baseUrl="baseUrl"
      :loading="loading"
      :showToolbar="false"
      :showFilter="false"
      emptyText="No Data found."
    >
    </order-report-box>
    <h3>Sale</h3>
    <order-report-box
      :columns="columns"
      :rows="categoryListSale"
      :baseUrl="baseUrl"
      :loading="loading"
      :showToolbar="false"
      :showFilter="false"
      emptyText="No Data found."
    >
    </order-report-box>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import orderReportBox from '../../components/orderReportBox.vue';

export default {
  name: 'CategoryWiseReport',
  components: {
    orderReportBox
  },
  metaInfo() {
    return {
      title: 'Category Report',
      meta: [
        {
          name: 'description',
          content: `Manage Report`
        }
      ]
    };
  },
  data() {
    return {
      title: 'Category Report',
      columns: [
        {
          type: 'row_num',
          headerText: 'Category',
          class: { 'text-center': true },
          textKey: 'category',
          width: '12%'
        },
        {
          type: 'row_num',
          headerText: 'East',
          class: { 'text-center': true },
          textKey: 'EastLD',
          width: '11%'
        },
        {
          type: 'row_num',
          headerText: 'North',
          class: { 'text-center': true },
          textKey: 'NorthLD',
          width: '11%'
        },
        {
          type: 'row_num',
          headerText: 'South',
          class: { 'text-center': true },
          textKey: 'SouthLD',
          width: '11%'
        },
        {
          type: 'row_num',
          headerText: 'Total LD',
          class: { 'text-center': true },
          textKey: 'totalLD',
          width: '11%'
        },
        {
          type: 'row_num',
          headerText: 'East',
          class: { 'text-center': true },
          textKey: 'EastMTD',
          width: '11%'
        },
        {
          type: 'row_num',
          headerText: 'North',
          class: { 'text-center': true },
          textKey: 'NorthMTD',
          width: '11%'
        },
        {
          type: 'row_num',
          headerText: 'South',
          class: { 'text-center': true },
          textKey: 'SouthMTD',
          width: '11%'
        },
        {
          type: 'row_num',
          headerText: 'Total MTD',
          class: { 'text-center': true },
          textKey: 'totalMTD',
          width: '11%'
        }
      ]
    };
  },
  mounted() {
    this.listNob({ query: {} });
    this.listSale({ query: {} });
  },
  computed: {
    ...mapState('categoryReport', ['downloadReport', 'loading', 'baseUrl', 'categoryListNob', 'categoryListSale'])
  },
  methods: {
    ...mapActions('categoryReport', ['download', 'listNob', 'listSale']),
    clickDownload() {
      let csv = '';
      const totalNob = {
        EastLD: 0,
        NorthLD: 0,
        SouthLD: 0,
        totalLD: 0,
        EastMTD: 0,
        NorthMTD: 0,
        SouthMTD: 0,
        totalMTD: 0
      };
      const totalSales = {
        EastLD: 0.0,
        NorthLD: 0.0,
        SouthLD: 0.0,
        totalLD: 0.0,
        EastMTD: 0.0,
        NorthMTD: 0.0,
        SouthMTD: 0.0,
        totalMTD: 0.0
      };
      if (this.categoryListNob.length) {
        csv = ',,Last Day NOB,,,,,Month Till Date NOB\n';
        csv += 'Category,East,North,South,Total,,East,North,South,Total\n';
        this.categoryListNob.forEach(row => {
          csv +=
            `${row.category},${row.EastLD},${row.NorthLD},${row.SouthLD},${row.totalLD},,` +
            `${row.EastMTD},${row.NorthMTD},${row.SouthMTD},${row.totalMTD}`;
          csv += '\n';
          totalNob.EastLD += row.EastLD;
          totalNob.NorthLD += row.NorthLD;
          totalNob.SouthLD += row.SouthLD;
          totalNob.totalLD += row.totalLD;
          totalNob.EastMTD += row.EastMTD;
          totalNob.NorthMTD += row.NorthMTD;
          totalNob.SouthMTD += row.SouthMTD;
          totalNob.totalMTD += row.totalMTD;
        });
        csv +=
          `Total,${totalNob.EastLD},${totalNob.NorthLD},${totalNob.SouthLD},${totalNob.totalLD},,` +
          `${totalNob.EastMTD},${totalNob.NorthMTD},${totalNob.SouthMTD},${totalNob.totalMTD}`;
      }
      if (this.categoryListSale.length) {
        csv += '\n\n\n';
        csv += ',,Last Day Sales,,,,,Month Till Date Sales\n';
        csv += 'Category,East,North,South,Total,,East,North,South,Total\n';
        this.categoryListSale.forEach(row => {
          csv +=
            `${row.category},${row.EastLD},${row.NorthLD},${row.SouthLD},${row.totalLD},,` +
            `${row.EastMTD},${row.NorthMTD},${row.SouthMTD},${row.totalMTD}`;
          csv += '\n';
          totalSales.EastLD += parseFloat(row.EastLD);
          totalSales.NorthLD += parseFloat(row.NorthLD);
          totalSales.SouthLD += parseFloat(row.SouthLD);
          totalSales.totalLD += parseFloat(row.totalLD);
          totalSales.EastMTD += parseFloat(row.EastMTD);
          totalSales.NorthMTD += parseFloat(row.NorthMTD);
          totalSales.SouthMTD += parseFloat(row.SouthMTD);
          totalSales.totalMTD += parseFloat(row.totalMTD);
        });
        csv +=
          `Total,${totalSales.EastLD.toFixed(2)},${totalSales.NorthLD.toFixed(2)},${totalSales.SouthLD.toFixed(
            2
          )},${totalSales.totalLD.toFixed(2)},,` +
          `${totalSales.EastMTD.toFixed(2)},${totalSales.NorthMTD.toFixed(2)},${totalSales.SouthMTD.toFixed(
            2
          )},${totalSales.totalMTD.toFixed(2)}`;
      }
      if (this.categoryListNob.length) {
        csv += '\n\n\n';
        csv += ',,Last Day NOB Category Percent,,,,,Month Till Date NOB Category Percent\n';
        csv += 'Category,East,North,South,Total,,East,North,South,Total\n';
        this.categoryListNob.forEach(row => {
          csv +=
            `${row.category},` +
            `${((row.EastLD / totalNob.EastLD) * 100).toFixed(2)}%,` +
            `${((row.NorthLD / totalNob.NorthLD) * 100).toFixed(2)}%,` +
            `${((row.SouthLD / totalNob.SouthLD) * 100).toFixed(2)}%,` +
            `${((row.totalLD / totalNob.totalLD) * 100).toFixed(2)}%,,` +
            `${((row.EastMTD / totalNob.EastMTD) * 100).toFixed(2)}%,` +
            `${((row.NorthMTD / totalNob.NorthMTD) * 100).toFixed(2)}%, ` +
            `${((row.SouthMTD / totalNob.SouthMTD) * 100).toFixed(2)}%, ` +
            `${((row.totalMTD / totalNob.totalMTD) * 100).toFixed(2)}%`;
          csv += '\n';
        });
      }
      if (this.categoryListSale.length) {
        csv += '\n\n\n';
        csv += ',,Last Day Sale Category Percent,,,,,Month Till Date Sale Category Percent\n';
        csv += 'Category,East,North,South,Total,,East,North,South,Total\n';
        this.categoryListSale.forEach(row => {
          csv +=
            `${row.category},` +
            `${((row.EastLD / totalSales.EastLD) * 100).toFixed(2)}%,` +
            `${((row.NorthLD / totalSales.NorthLD) * 100).toFixed(2)}%,` +
            `${((row.SouthLD / totalSales.SouthLD) * 100).toFixed(2)}%,` +
            `${((row.totalLD / totalSales.totalLD) * 100).toFixed(2)}%,,` +
            `${((row.EastMTD / totalSales.EastMTD) * 100).toFixed(2)}%,` +
            `${((row.NorthMTD / totalSales.NorthMTD) * 100).toFixed(2)}%, ` +
            `${((row.SouthMTD / totalSales.SouthMTD) * 100).toFixed(2)}%, ` +
            `${((row.totalMTD / totalSales.totalMTD) * 100).toFixed(2)}%`;
          csv += '\n';
        });
      }
      const anchor = document.createElement('a');
      anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
      anchor.target = '_blank';
      anchor.download = 'SalesMis.csv';
      anchor.click();
    }
  }
};
</script>

<style>
</style>
