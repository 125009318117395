<template>
  <div class="body">
    <b-overlay :show="busy" rounded="sm">
      <div :aria-hidden="busy ? 'true' : null" class="nav_main_div">
        <div class="nav_bar_div">
          <nav class="navbar navbar-light bg-light">
            <button @click="goBack" class="back_btn">
              <font-awesome-icon :icon="['fas', 'angle-double-left']" class="mr-1 arrow_icon" />Back</button
            >
            <span style="margin-right: 50%; color: #383838" class="navbar-brand h1">Review</span>
            <!-- <span v-if="computedList !== ''">₹{{ total }}</span> -->
            <!-- <span v-if="computedList !== ''">{{ cartItemsCount }} Items</span> -->
          </nav>
        </div>
      </div>
      <div v-if="computedList != ''">
        <div class="body_div">
          <h4>Your Order Items</h4>

          <b-card class="total_card">
            <div class="total_price_div">
              <div class="price_div" v-if="computedList !== ''">{{ cartItemsCount }} Items</div>
              <div class="total_div" v-if="computedList !== ''">₹{{ total }}</div>
            </div>
          </b-card>

          <b-card class="product_card" v-for="product in computedList" :key="product.id">
            <div class="content_div">
              <div class="left_div">
                <img class="card_image" v-bind:src="product.images" />
              </div>
              <div class="middle_div">
                <div class="productName">{{ product.title }}</div>
                <div class="quantity_btn_div">
                  <b-button class="cart_plus_btn" @click="addToCart(product)"
                    ><font-awesome-icon :icon="['fas', 'plus']" class="mr-1 arrow_icon"
                  /></b-button>
                  <span style="margin: 8px" v-if="cartItems[product._id].quantity">
                    {{ cartItems[product._id].quantity }}
                  </span>
                  <b-button class="cart_minus_btn" @click="removeFromCart(product)">
                    <font-awesome-icon :icon="['fas', 'minus']" class="mr-1 arrow_icon" />
                  </b-button>
                </div>
              </div>
              <div class="right_div">
                ₹
                {{
                  (product.price - product.price * (product.discountInPercent / 100)) * cartItems[product._id].quantity
                }}</div
              >
            </div>
          </b-card>
          <b-card class="total_card">
            <!-- <hr /> -->
            <div class="total_price_div">
              <div class="total_div">Total</div>
              <div class="price_div">₹{{ total }}</div>
            </div>
          </b-card>
          <b-card class="billing_card">
            <div class="input_form_div"
              ><h5>Billing Details</h5>
              <b-row class="my-1">
                <b-col sm="12">
                  <label class="form_label" for="input-small">Mobile</label>
                </b-col>
                <b-col sm="12">
                  <b-form-input
                    class="form_input_field"
                    v-model="form.mobile"
                    :state="$v.form.mobile.$dirty ? !$v.form.mobile.$error : null"
                    type="number"
                    id="input-small"
                    size="sm"
                    @keyup="fetchData"
                    placeholder="Please enter your Mobile Number"
                  ></b-form-input>
                  <!-- <button @click="fetchData">fetch</button> -->
                  <b-form-text id="input-mobile-help">Mobile Number must be a valid 10 digit long number.</b-form-text>

                  <b-form-invalid-feedback id="input-email-invalid"
                    >Please enter valid mobile number.</b-form-invalid-feedback
                  >
                </b-col>
              </b-row>

              <b-row class="my-1">
                <b-col sm="12">
                  <label class="form_label" for="input-small">Name</label>
                </b-col>
                <b-col sm="12">
                  <b-form-input
                    class="form_input_field"
                    id="input-small"
                    v-model="form.name"
                    :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                    size="sm"
                    placeholder="Please enter your Name"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-name-invalid">Please enter name.</b-form-invalid-feedback>
                </b-col>
              </b-row>

              <b-row class="my-1">
                <b-col sm="12">
                  <label class="form_label" for="input-small">Address</label>
                </b-col>
                <b-col sm="12">
                  <b-form-input
                    class="form_input_field"
                    v-model="form.address"
                    :state="$v.form.address.$dirty ? !$v.form.address.$error : null"
                    id="input-small"
                    size="sm"
                    placeholder="Please enter your Address"
                  ></b-form-input>
                  <b-form-text id="input-address-help">Address must be more than 10 characters.</b-form-text>

                  <b-form-invalid-feedback id="input-address-invalid"
                    >Please enter valid address.</b-form-invalid-feedback
                  >
                </b-col>
              </b-row>

              <b-row class="my-1">
                <b-col sm="12">
                  <label class="form_label" for="input-small">Description</label>
                </b-col>
                <b-col sm="12">
                  <b-form-textarea
                    class="form_input_field"
                    v-model="form.description"
                    id="textarea-no-resize"
                    rows="3"
                    no-resize
                  ></b-form-textarea>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </div>
        <div class="confirm_order_div">
          <b-button :disabled="busy" @click="onSubmit" class="confirm_order_btn" block>
            <span> Confirm-Order</span>
            <font-awesome-icon :icon="['fas', 'arrow-right']" class="mr-3 arrow_icon right_arrow_icon" />
          </b-button>
        </div>
      </div>
      <h4 style="text-align: center; margin-top: 6rem" v-else>Your Cart is Empty !</h4>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import router from '@/router';
import { numeric, maxLength, required, minLength } from 'vuelidate/lib/validators';

export default {
  components: {},
  data() {
    return {
      cartItems: [],
      form: {
        name: '',
        number: '',
        address: '',
        description: ''
      },
      busy: false,
      timeout: null,
      box: '',
      customer: []
    };
  },
  validations() {
    const formValidation = {
      mobile: {
        required,
        numeric,
        maxLength: maxLength(10),
        minLength: minLength(10)
      },
      name: {
        required
      },
      address: {
        required,
        minLength: minLength(10)
      },
      description: {}
    };
    return { form: formValidation };
  },
  beforeDestroy() {
    this.clearTimeout();
  },
  mounted() {
    const storeList = localStorage.getItem('storeData') || '{}';
    this.storeList = JSON.parse(storeList);
    const cartItems = localStorage.getItem('product-cart') || '{}';
    this.cartItems = JSON.parse(cartItems);
    this.id = this.$route.params.id;
    this.listProducts({ id: this.id });
  },
  computed: {
    ...mapState('productList', ['products']),
    ...mapState('customerMetaData', ['customerData']),
    computedProduct() {
      const arr = [];
      if (this.computedList) {
        this.computedList
          .map(
            product => product.title + ':::' + this.cartItems[product._id].quantity // eslint-disable-line
          )
          .forEach(item => {
            arr.push(item);
          });
      }
      return arr;
    },
    cartItemsCount() {
      return Object.values(this.cartItems).reduce((count, item) => {
        return count + item.quantity;
      }, 0);
    },
    computedList() {
      const dataId = Object.entries(this.cartItems).map(([key]) => key);
      if (this.products.products) {
        return this.products.products.filter(product => dataId.includes(product._id)); // eslint-disable-line
      }
      return dataId;
    },
    total() {
      let total = 0;
      if (this.products.products) {
        Object.entries(this.cartItems).forEach(([key, value]) => {
          let price = this.products.products.find(product => product._id === key).price; // eslint-disable-line
          let discount = this.products.products.find(product => product._id === key).discountInPercent; // eslint-disable-line
          total += value.quantity * (price - (price * discount) / 100);
        });
      }
      return total;
    }
  },
  methods: {
    ...mapActions('productList', ['listProducts', 'getOne', 'postOne']),
    ...mapActions('customerMetaData', ['listCustomerMetaData']),
    showMsgBox() {
      this.box = '';
      setTimeout(
        () =>
          this.$bvModal
            .msgBoxOk('Your order submitted successfully', {
              title: 'Notice',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'success',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
            .then(value => {
              this.box = value;
              return router.push(`/product-catalogue/${this.id}`);
            }),
        2000
      );
    },
    fetchData() {
      if (this.form.mobile.length === 10) {
        this.listCustomerMetaData({
          query: {
            mobile: this.form.mobile
          }
        });
      }
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      this.showMsgBox();
      this.busy = true;
      // Simulate an async request
      this.setTimeout(() => {
        this.busy = false;
      });
      const order = {
        name: this.form.name,
        customerMobile: this.form.mobile,
        address: this.form.address,
        products: this.computedProduct,
        storeid: this.storeList.storeid,
        description: this.form.description
      };
      this.postOne({
        order,
        router
      });
      localStorage.removeItem('product-cart');
      return false;
    },
    goBack() {
      this.$router.back();
    },
    addToCart(product) {
      const cartItems = { ...this.cartItems };
      const productId = product._id; // eslint-disable-line
      if (cartItems[productId]) {
        cartItems[productId] = {
          ...cartItems[productId],
          quantity: cartItems[productId].quantity + 1
        };
      } else {
        cartItems[productId] = { quantity: 1 };
      }
      this.cartItems = cartItems;
      window.localStorage.setItem('product-cart', JSON.stringify(cartItems));
    },
    removeFromCart(product) {
      const cartItems = { ...this.cartItems };
      const productId = product._id; // eslint-disable-line

      if (cartItems[productId]) {
        const quantity = cartItems[productId].quantity - 1;
        if (quantity < 1) {
          delete cartItems[productId];
        } else {
          cartItems[productId] = {
            ...cartItems[productId],
            quantity
          };
        }
      }
      this.cartItems = cartItems;
      window.localStorage.setItem('product-cart', JSON.stringify(cartItems));
    },
    clearTimeout() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
    },
    setTimeout(callback) {
      this.clearTimeout();
      this.timeout = setTimeout(() => {
        this.clearTimeout();
        callback();
      }, 2000);
    },
    onHidden() {
      // Return focus to the button
      this.$refs.button.focus();
    },
    onClick() {
      this.busy = true;
      // Simulate an async request
      this.setTimeout(() => {
        this.busy = false;
      });
    }
  },
  watch: {
    customerData(newValue) {
      this.form.name = newValue.name;
      this.form.address = newValue.address;
    }
  }
};
</script>

<style scoped>
/* Navbar styling  */
.body {
  margin-top: -4.5rem;
}
.nav_main_div {
  width: 100vw;
  margin-left: -1rem;
  margin-bottom: 1rem;
}

.nav_bar_div {
  top: 0;
  z-index: 1;
  width: 100%;
  position: fixed;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

/* .navbar {

} */

.back_btn {
  width: 6rem;
  padding: 3px;
  border: none;
  outline: none;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  color: #383838;
  border-radius: 3px;
  background-color: #fff;
}

.back_btn:hover {
  background-color: #dcdcdc;
}

.arrow_icon {
  margin-right: 10px !important;
}

.navbar > span {
  font-size: 20px;
  font-weight: 600;
}

/* Body Styling  */
.body_div {
  margin-top: 6rem;
}

.body_div > h4 {
  color: #383838;
  font-weight: 500;
  margin-left: 20rem;
  margin-right: 20rem;
  text-align: center;
}

/* Product Card Styling */
.product_card {
  width: 50%;
  margin: auto;
  border: none;
  margin-bottom: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.content_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.left_div {
  width: 15%;
}

.left_div > img {
  width: 95%;
}

.middle_div {
  width: 39%;
  margin-right: 9rem;
}

.productName {
  font-size: 14px;
  font-weight: 500;
  margin: 7px 0 10px 0;
}

.quantity_btn_div {
  display: flex;
  width: 30px;
}

.quantity_btn_div > span {
  font-weight: 600;
  font-size: 16px;
  margin: 0.2rem;
}

.cart_plus_btn {
  width: 40px;
  outline: none;
  color: #f28a3e;
  border-radius: 50%;
  background-color: #fff;
  border: 1.5px solid #f28a3e;
}

.cart_plus_btn:hover {
  color: #fff;
  background-color: #f28a3e;
}

.cart_minus_btn {
  width: 40px;
  outline: none;
  color: #f28a3e;
  border-radius: 50%;
  background-color: #fff;
  border: 1.5px solid #f28a3e;
}

.cart_minus_btn:hover {
  color: #fff;
  background-color: #f28a3e;
}

.right_div {
  width: 10%;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  margin: 13px 0 0px 0;
}

/* Total price Styling */
.total_card {
  width: 50%;
  margin: auto;
  border: none;
  margin-bottom: 1rem;
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
}

.total_price_div {
  display: flex;
  justify-content: space-between;
}

.total_div {
  font-size: 18px;
  font-weight: 700;
}

.price_div {
  font-size: 18px;
  font-weight: 700;
}

/* Billing Card Styling */
.billing_card {
  width: 50%;
  margin: auto;
  border: none;
  margin-bottom: 1rem;
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
}

/* Form styling */
.input_form_div > h5 {
  font-weight: 700;
}

.form_label {
  font-size: 15px;
  font-weight: 500;
}

.form_input_field {
  border: 1.5px solid #c4c3d0;
}

/* Confirm Order Button Styling */
.confirm_order_div {
  top: 0;
  top: 85%;
  left: 25%;
  width: 50%;
  z-index: 1;
  margin: auto;
  position: fixed;
  overflow: hidden;
  border-radius: 3px;
}

.confirm_order_btn {
  border: none;
  font-size: 17px;
  font-weight: 700;
  border-radius: 3px;
  padding: 24px 0px 24px 0px;
  background-color: #f28a3e;
}

.right_arrow_icon {
  right: 2%;
  margin-top: 5px;
  position: absolute;
}

@media only screen and (max-width: 349px) {
  .back_btn {
    width: 5rem;
    font-size: 12px;
    font-weight: 500;
  }
  .navbar > span {
    font-size: 12px;
    margin-top: 11px;
    font-weight: 500;
  }

  .left_div {
    width: 20%;
  }

  .left_div > img {
    width: 95%;
    margin-top: 28px;
  }

  .middle_div {
    margin-right: 2rem;
  }

  .right_div {
    width: 15%;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    margin: 13px 0px 0px 0px;
  }
}

@media only screen and (max-width: 540px) and (min-width: 350px) {
  .left_div {
    width: 20%;
    margin-top: 18px;
  }

  .right_div {
    font-size: 15px;
    font-weight: 500;
  }

  .middle_div {
    margin-right: 4rem;
  }

  .back_btn {
    font-size: 15px;
  }

  .navbar > span {
    font-size: 15px;
    margin-top: 5px;
    font-weight: 600;
  }
}

@media screen and (max-width: 768px) {
  .body_div > h4 {
    margin-left: 0;
    margin-right: 0;
  }

  .product_card {
    width: 100%;
  }

  .total_card {
    width: 100%;
  }

  .billing_card {
    width: 100%;
  }

  .confirm_order_div {
    left: 2%;
    width: 96%;
  }
}

@media only screen and (max-width: 1170px) and (min-width: 769px) {
  .left_div {
    width: 20%;
    margin-top: 6px;
  }

  .right_div {
    font-size: 15px;
    font-weight: 500;
  }

  .middle_div {
    margin-right: 4rem;
  }
}
</style>
