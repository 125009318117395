<template>
  <div>
    <div class="homeBtn_ctr">
      <button @click="goHome()" class="homeBtn">
        Back
      </button>
    </div>

    <div class="main">
      <div class="items">
        <div class="mx-3 d-flex align-items-center d-flex justify-content-between"
          ><h2>B2B-Categories</h2
          ><div class="order_List">
            <b-button size="sm" variant="success" class="mr-2" @click="goHome">
              <font-awesome-icon :icon="['fas', 'clipboard-list']" class="mr-1" />Order List
            </b-button>
          </div>
        </div>
        <div class="categoryWrapper">
          <div>
            <div class="category">
              <a
                class="singleCategory"
                v-for="category in categories"
                :key="category.id"
                @click="getSubCategories(category)"
              >
                <div class="categoryImage">
                  <img class="card_image" :src="category.imageUrl" />
                </div>
                <div class="card_title">{{ category.name }}</div>
              </a>
            </div></div
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import router from '@/router';

export default {
  name: 'CategoryList',
  data() {
    return {
      categories: [],
      selectedCategory: null
    };
  },
  mounted() {
    this.listCategory({ router });
  },
  computed: {
    ...mapState('product', ['categoryList'])
  },
  methods: {
    ...mapActions('product', ['listCategory']),
    goHome() {
      // router.push(`/eCategoryList`);
      router.push(`/b2b-order`);
    },
    getSubCategories(category) {
      this.selectedCategory = category;
      router.push({
        path: `/subCategoryList?parent=${category.name.replaceAll('&', '%26')}`
      });
    }
  },
  watch: {
    categoryList(newValue) {
      this.categories = newValue.filter(i => i.type === '1').sort((a, b) => a.name.localeCompare(b.name));
    }
  }
};
</script>

<style scoped>
.homeBtn_ctr {
  width: 53px;
  display: flex;
  border-radius: 26px;
  overflow: hidden;
  height: 53px;
  background-color: #eafdfc !important;
  align-content: center;
  position: fixed;
  right: 18px;
  bottom: 130px;
  z-index: 200;
  font-family: Myriad;
}
.homeBtn_ctr:hover {
  background-color: wheat;
  transform: scale(1.04, 1.04);
  /* transition-duration: 500ms; */
}
.homeBtn {
  border: none;
  width: 100%;
  background-color: #c0deff;
  color: black;
}
.homeBtn:hover {
  background-color: #62b6b7;
  transition-duration: 500ms;
}
.main {
  font-family: Myriad;
}
.items {
  width: 100%;
  position: relative;
  margin: 0 0 20px 0;
}
/* .heading {
  margin-left: 20px;
} */
.categoryWrapper {
  margin: 0 auto;
  width: 80%;
  height: 80%;
}
.category {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  position: relative;
  /* border: 1px solid black; */
}
.singleCategory {
  text-decoration: none;
  color: black;
  margin-inline: 12px;
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 17vw;
  min-width: 200px;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.singleCategory:hover {
  cursor: pointer;
  transform: scale(1.04, 1.04);
  /* border: 1px solid #e7861b; */
  transition-duration: 500ms;
  box-shadow: #f28a3f 0px 1px 4px;
}
.categoryImage {
  /* background: rgba(231, 134, 27, 0.15); */
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.card_image {
  /* width: 70%; */
  object-fit: contain;
  max-width: 100%;
  min-width: 60%;
  max-height: 90%;
  min-height: 60%;
}
.card_title {
  margin: 10px;
  font-size: 20px;
  font-weight: 400;
  border-top: 1px solid #f28a3f;
  border-radius: 10px;
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 415px) {
  .singleCategory {
    width: 140px;
    min-width: 70px;
    /* height: 200px; */
  }
  .categoryImage {
    height: 18vh;
    /* border: 1px solid black; */
  }
}
</style>
