<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title">{{ title }}</h1>
      <b-2-b-order-form-box
        list-url="/order"
        :form-type="formType"
        :mobile="mobile"
        :customer-mobile="customerMobile"
        :customer-address="customerAddress"
        :customer-name="customerName"
        @add="onAdd"
        @edit="onEdit"
        @fetch-user="onFetchCustomer"
        @find="onFindProducts"
      ></b-2-b-order-form-box>

      <h1 class="page-title mt-5">User Order History</h1>
      <table-box-order
        :columns="columns"
        :rows="customerOrders"
        :baseUrl="baseUrl"
        :loading="loading"
        emptyText="No order found. Please add new order."
        :showAdd="false"
        :showToolbar="false"
        :showPagination="false"
        @edit="onEditRow"
        @delete="onDelete"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import B2BOrderFormBox from '@/components/B2BOrderFormBox.vue';

import TableBoxOrder from '@/components/TableBoxOrder.vue';
import router from '@/router';

export default {
  name: 'B2BOrderForm',
  components: {
    B2BOrderFormBox,
    TableBoxOrder
  },
  async mounted() {
    if (this.$route.name === 'order-new') {
      this.formType = 'new';
      this.mobile = null;
      this.customerMobile = parseInt(this.$route.params.customermobile.slice(-10), 10);
      if (this.customerMobile) {
        this.listByCustomer({ customerMobile: this.customerMobile });
      }
    } else {
      this.formType = 'update';
      this.orderId = this.$route.params.id;
      await this.getOne({
        id: this.orderId,
        router
      });
    }
  },
  data() {
    return {
      formType: '',
      mobile: null,
      orderId: null,
      customerName: null,
      customerMobile: null,
      customerAddress: null,
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'id',
          width: '2%'
        },
        {
          type: 'string',
          headerText: 'Customer Mobile',
          class: { 'text-center': true },
          textKey: 'customerMobile',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Name',
          class: { 'text-center': true },
          textKey: 'name',
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Products',
          class: { 'text-center': true },
          textKey: 'products',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'Address',
          class: { 'text-center': true },
          textKey: 'address',
          width: '20%'
        },
        {
          type: 'string',
          headerText: 'Description',
          class: { 'text-center': true },
          textKey: 'description',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Created On',
          class: { 'text-center': true },
          textKey: 'dateCreated',
          width: '13%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '15%',
          functions: {
            edit: true,
            delete: true
          }
        }
      ]
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Add new order' : 'Update B2B-Order';
    },
    ...mapState('order', ['customerOrders', 'fetchedCustomerName', 'fetchedCustomerAddress']),
    ...mapState('b2BOrder', ['loading', 'baseUrl', 'b2BOrder']),
    ...mapState('auth', ['user'])
  },
  watch: {
    b2BOrder(newOrder) {
      if (newOrder?.customerMobile) {
        this.listByCustomer({ customerMobile: newOrder.customerMobile });
      }
    },
    fetchedCustomerName(newValue) {
      this.customerName = newValue;
    },
    fetchedCustomerAddress(newValue) {
      this.customerAddress = newValue;
    }
  },
  methods: {
    ...mapActions('order', ['listByCustomer', 'patchOne']),
    ...mapActions('b2BOrder', ['getOne']),
    ...mapActions('catalogue', ['findProducts']),
    onFindProducts({ query }) {
      this.findProducts({ query: { text: query, storeid: this.user.storeids[0] } });
    },
    onAdd({ order }) {
      this.postOne({
        order,
        router,
        redirectUrl: '/order'
      });
    },
    onFetchCustomer({ customerMobile }) {
      this.listByCustomer({ customerMobile });
    },
    onEdit({ order }) {
      if (!this.user?.storeids?.includes(order.storeid)) {
        Vue.swal({
          title: 'Sorry, you can not edit an order of other store..',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Okay'
        });
      } else {
        this.patchOne({
          orderId: order.orderId,
          order,
          router,
          redirectUrl: '/b2b-order'
        });
      }
    },
    onEditRow({ row }) {
      // router.push(`/order/${row.orderId}`);
      const { href } = router.resolve({
        path: `/order/${row.orderId}`
      });
      window.open(href, '_blank');
    },
    onDelete({ row }) {
      if (this.user.storeid !== row.storeid) {
        Vue.swal({
          title: 'Sorry, you can not delete an order of other store..',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Okay'
        });
      } else {
        Vue.swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this.",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',

          preConfirm: () => {
            this.deleteOne({
              id: row.orderId
            });
          }
        });
      }
    }
  }
};
</script>
