<template>
  <div class="page-class page-user-list">
    <h1 class="page-title">{{ title }}</h1>
    <staff-box
      :columns="columns"
      :rows="users"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="true"
      :showDownload="true"
      :showToolbar="true"
      :showDownloadCallerList="true"
      :showPagination="true"
      :showDownloadReport="true"
      :pagination="pagination"
      :searchItems="searchItems"
      addText="Create new staff"
      downloadText="Download"
      downloadReportText="Download Active Report"
      emptyText="No staff found. Please add new user."
      callerDownloadText="Download Inbound Staff"
      @add="onAdd"
      @download="onDownload"
      @edit="onEdit"
      @delete="onDelete"
      @download-inbound="onDownloadInboundCaller"
      @download-active-report="onDownloadActiveReport"
    >
      <template v-slot:emailAddressFormatted="slotProps">
        <span class="span-text span-block">
          {{ slotProps.row.email }}
        </span>
        <template v-if="slotProps.row.confirmedAtFormatted">
          <span class="text-success span-help-text" :title="slotProps.row.confirmedAt">
            ({{ slotProps.row.confirmedAtFormatted }})
          </span>
        </template>
        <template v-if="slotProps.row.confirmedAtFormatted === null">
          <span class="text-warning span-help-text"> (Not confirmed) </span>
        </template>
      </template>
      <template v-slot:lastLoginAtFormatted="slotProps">
        <template v-if="slotProps.row.lastLoginAtFormatted">
          <span class="text-success">{{ slotProps.row.lastLoginAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.lastLoginAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
      <template v-slot:blockedAtFormatted="slotProps">
        <template v-if="slotProps.row.blockedAtFormatted">
          <span class="text-success">{{ slotProps.row.blockedAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.blockedAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
    </staff-box>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import staffBox from '@/components/staffBox.vue';
import router from '@/router';

export default {
  name: 'StaffList',
  components: {
    staffBox
  },
  metaInfo() {
    return {
      title: 'Staffs',
      meta: [
        {
          name: 'description',
          content: `Manage staff list`
        }
      ]
    };
  },
  mounted() {
    this.list({ type: 'staff', query: this.$route.query });
  },
  beforeRouteUpdate(to, from, next) {
    this.list({ type: 'staff', query: to.query });
    next();
  },
  data() {
    return {
      title: 'Staffs',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'id',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Mobile',
          class: { 'text-center': true },
          textKey: 'mobile',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Name',
          class: { 'text-center': true },
          textKey: 'name',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Role',
          class: { 'text-center': true },
          textKey: 'roleName',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'User Type',
          class: { 'text-center': true },
          textKey: 'typeName',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Designation',
          class: { 'text-center': true },
          textKey: 'designation',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Status',
          class: { 'text-center': true },
          textKey: 'statusName',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Last Login',
          class: { 'text-center': true },
          textKey: 'lastLogin',
          width: '10%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '15%',
          functions: {
            edit: true,
            delete: true
          }
        }
      ],
      searchItems: ['Name', 'Mobile']
    };
  },
  computed: {
    ...mapState('user', ['loading', 'baseUrl', 'users', 'pagination', 'inboundList', 'activeReport', 'newUsers']),
    ...mapState('auth', ['user'])
  },
  watch: {
    inboundList(newValue) {
      if (newValue.length) {
        let csv = 'Row Number,Mobile,Store Id,Name,Employee Id\n';
        newValue.forEach(row => {
          Object.values(row).forEach(val => {
            csv += `"${val}"`;
            csv += ',';
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Users.csv';
        anchor.click();
      }
    },
    activeReport(newValue) {
      if (newValue) {
        const startTime = newValue.map(report => report.LastDay);
        let csv = '';
        csv += `,,,All Users Report\n`;
        csv += `,Time From   ${startTime}   to    ${moment().format('DD-MM-YYYY   HH:mm:ss')}\n`;
        csv += ',Active Users,,Total Users,,Inactive Users\n';
        newValue.forEach(row => {
          Object.values(row).forEach((value, index) => {
            if ([0, 2, 3].includes(index)) {
              csv += `,"${value}"`;
              csv += ',';
            }
          });
          csv += '\n';
        });

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Users Report.csv';
        anchor.click();
      }
    },
    newUsers(newValue) {
      if (newValue) {
        let csv =
          'Row Number,Name,Mobile,Role,Status,Employee Id,Last Login,Designation,Primary Store Id,Managed Store Ids,Pincode,NearBy Pincode,Store Manager Numbers, Sms Number,User Type\n';
        newValue.forEach(row => {
          csv += `"${row.id}","${row.name}","${row.mobile}","${row.roleName}","${row.statusName}","${row.empid}","${row.lastLogin}","${row.designation}","${row.storeid}","${row.storeids}","${row.pincode}","""${row.nearbyPincode}""","""${row.storeManagerNumber}""","${row.smsNumber}","${row.typeName}"\n`; //eslint-disable-line
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Users.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('user', ['list', 'listCaller', 'deleteOne', 'downloadReport', 'downloadUsers']),
    onAdd() {
      router.push('/staff/new');
    },
    onEdit({ row }) {
      router.push(`/staff/${row.mobile}`);
    },
    onDownloadInboundCaller() {
      this.listCaller({ type: 'staff', query: this.$route.query });
    },
    onDownloadActiveReport() {
      this.downloadReport();
    },
    onDownload() {
      this.downloadUsers({ query: { ...this.$route.query, page_size: 50, page: 1 } });
    },
    onDelete({ row }) {
      if (this.user.mobile === row.mobile) {
        Vue.swal({
          title: 'Access Denied',
          text: 'You can not delete yourself !',
          type: 'error',
          confirmButtonText: 'Ok'
        });
      } else {
        Vue.swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this.",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',

          preConfirm: () => {
            this.deleteOne({
              type: 'staff',
              mobile: row.mobile
            });
          }
        });
      }
    }
  }
};
</script>
