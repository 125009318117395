<script src="//unpkg.com/v-viewer/dist/v-viewer.js"></script>
<template>
  <div>
    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group>
          <template>
            Employee Id
            <span class="text-danger">*</span>
          </template>
          <b-form-input disabled v-model="employeeId" placeholder="enter employee Id" />
        </b-form-group>
        <b-form-group>
          <template>
            GST Number
            <span class="text-danger">*</span>
          </template>
          <b-form-input disabled v-model="gstNumber" placeholder="enter gstNumber" />
        </b-form-group>
        <b-form-group>
          <template>
            Sales Associate Name
            <span class="text-danger">*</span>
          </template>
          <b-form-input disabled v-model="salesAssociateName" placeholder="enter salesAssociateName" />
        </b-form-group>
        <b-form-group id="group-mobile" label-for="input-mobile">
          <template v-slot:label>
            Customer Mobile Number
            <span class="text-danger">*</span>
          </template>
          <b-input-group>
            <b-form-input
              id="input-mobile"
              v-model="form.mobile"
              type="number"
              :disabled="formType !== 'new'"
              placeholder="Enter Mobile Number"
              :state="$v.form.mobile.$dirty ? !$v.form.mobile.$error : null"
            ></b-form-input>
            <b-input-group-append v-if="formType === 'new' && form.mobile.length > 6">
              <b-button variant="success" @click="fetchUserInfo">Fetch User Data</b-button>
            </b-input-group-append>
          </b-input-group>

          <b-form-text id="input-mobile-help">Mobile Number must be a valid 10 digit long number.</b-form-text>

          <b-form-invalid-feedback id="input-email-invalid">Please enter valid mobile number.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="group-name" label-for="input-name">
          <template v-slot:label>
            Customer Name
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            id="input-name"
            type="text"
            v-model="form.name"
            :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
            placeholder="Enter Name"
          ></b-form-input>

          <b-form-invalid-feedback id="input-name-invalid">Please enter name.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="group-address" label-for="input-address">
          <template v-slot:label>
            Customer Address
            <span class="text-danger">*</span>
          </template>

          <b-form-textarea
            id="input-address"
            type="text"
            v-model="form.address"
            :state="$v.form.address.$dirty ? !$v.form.address.$error : null"
            placeholder="Enter Customer Address"
          ></b-form-textarea>

          <b-form-text id="input-address-help">Address must be more than 10 characters.</b-form-text>

          <b-form-invalid-feedback id="input-address-invalid">Please enter valid address.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label> Add Products </template>
          <b-form-row v-for="index in value.length + 1" :key="index - 1">
            <b-col cols="8" @click="selectedMulti(index - 1)">
              <multiselect
                :disabled="index != value.length + 1"
                v-model="value[index - 1]"
                ref="multiselect"
                tag-placeholder="Add this as new product"
                placeholder="Add Products"
                label="productName"
                :custom-label="labelWithPrice"
                track-by="productName"
                :options="productOptions"
                :searchable="true"
                :internal-search="false"
                :clear-on-select="true"
                :close-on-select="true"
                :options-limit="20"
                :hide-selected="true"
                :loading="isLoading"
                :multiple="false"
                :taggable="true"
                @search-change="asyncFind"
                @tag="addTag"
              >
              </multiselect>
            </b-col>
            <b-col cols="1" v-bind:style="{ padding: '8px', textAlign: 'center' }">X</b-col>
            <b-col cols="2">
              <b-form-input
                v-bind:style="{ height: '40px !important' }"
                type="number"
                ref="inputquantity"
                v-model="quantity[index - 1]"
                placeholder="1"
              ></b-form-input>
            </b-col>
            <b-col cols="1"> <b-button @click="deleteProduct(index)" variant="danger">Delete</b-button> </b-col>
          </b-form-row>
        </b-form-group>

        <b-form-group id="group-description" label-for="input-description">
          <template v-slot:label> Order Description </template>

          <b-form-textarea
            id="input-description"
            type="text"
            rows="1"
            v-model="form.description"
            :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
            placeholder="Enter Order Description If Any"
          ></b-form-textarea>

          <b-form-text id="input-description-help">Description must be more than 1 characters.</b-form-text>

          <b-form-invalid-feedback id="input-description-invalid"
            >Please enter valid description.</b-form-invalid-feedback
          >
        </b-form-group>

        <template v-if="errorMessages">
          <b-row class="mb-2">
            <b-col class="text-danger message-col">{{ errorMessages }}</b-col>
          </b-row>
        </template>

        <template v-if="productError">
          <b-row class="mb-2">
            <b-col class="text-danger message-col">{{ productError }}</b-col>
          </b-row>
        </template>

        <template v-if="this.formType !== 'new'">
          <div style="display: flex">
            <b-form-group id="group-name" label-for="input-name">
              <template v-slot:label> Order Created At </template>
              <b-form-input
                id="input-name"
                type="text"
                :disabled="true"
                style="max-width: 70%"
                v-model="form.createdAt"
                :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
              ></b-form-input>
            </b-form-group>
            <b-form-group id="group-name" label-for="input-name">
              <template v-slot:label> Acknowledged At </template>
              <b-form-input
                id="input-name"
                type="text"
                style="max-width: 70%"
                :disabled="true"
                v-model="form.acknowledgedTime"
                :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
              ></b-form-input>
            </b-form-group>
            <b-form-group id="group-name" label-for="input-name">
              <template v-slot:label> Packaged </template>
              <b-form-input
                id="input-name"
                type="text"
                style="max-width: 70%"
                :disabled="true"
                v-model="form.packagedTime"
                :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
              ></b-form-input>
            </b-form-group>
            <b-form-group id="group-name" label-for="input-name">
              <template v-slot:label> Bill Generated At </template>
              <b-form-input
                id="input-name"
                type="text"
                style="max-width: 70%"
                :disabled="true"
                v-model="form.posPingTime"
                :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
              ></b-form-input>
            </b-form-group>
            <b-form-group id="group-name" label-for="input-name">
              <template v-slot:label> Delivered At </template>
              <b-form-input
                id="input-name"
                type="text"
                style="max-width: 70%"
                :disabled="true"
                v-model="form.actualDeliveredTime"
                :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
              ></b-form-input>
            </b-form-group>
          </div>
          <label v-if="b2BOrder.imageURL != ''" style="font-weight: bold">Order Images</label>
          <div v-viewer>
            <template v-for="image in b2BOrder.imageURL">
              <div :key="image" class="imageview">
                <label> <img :src="image" class="image"/></label>
              </div>
            </template>
          </div>
          <label v-if="b2BOrder.deliveryPics != ''" style="font-weight: bold">Delivery Images</label>
          <div v-viewer>
            <template v-for="image in b2BOrder.deliveryPics">
              <div :key="image" class="imageview">
                <label> <img :src="image" class="image"/></label>
              </div>
            </template>
          </div>
          <label style="font-weight: bold">POS Items</label>

          <table-box
            :columns="columns"
            :rows="pos"
            :baseUrl="baseUrl"
            :loading="loading"
            emptyText="No pos items found."
          >
          </table-box>
        </template>

        <b-row>
          <b-col>
            <b-button type="submit" size="sm" variant="success" :disabled="loading">
              <span class="spinner spinner-white" v-if="loading"></span>
              <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button size="sm" variant="warning" :to="{ path: `${listUrl}` }">
              <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <link href="//unpkg.com/viewerjs/dist/viewer.css" rel="stylesheet" />
  </div>
</template>

<script>
import { numeric, maxLength, required, minLength } from 'vuelidate/lib/validators';
import Multiselect from 'vue-multiselect';
import { mapActions, mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import Catalogue from '../model/catalogue';
import VueViewer from 'v-viewer';
import Vue from 'vue';
import TableBox from './TableBox.vue';

Vue.use(VueViewer);

export default {
  name: 'B2BOrderFormBox',
  props: {
    listUrl: String,
    userType: String,
    formType: String,
    customerMobile: Number,
    customerName: String,
    customerAddress: String,
    userId: {
      type: Number,
      required: false
    }
  },
  components: {
    Multiselect,
    TableBox
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      columns: [
        {
          type: 'row_num',
          headerText: 'UOM',
          class: { 'text-center': true },
          textKey: 'UOM',
          width: '20%'
        },
        {
          type: 'string',
          headerText: 'Amount',
          class: { 'text-center': true },
          textKey: 'amount',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Discount',
          class: { 'text-center': true },
          textKey: 'disc',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Hsn Code',
          class: { 'text-center': true },
          textKey: 'hsnCode',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Category',
          class: { 'text-center': true },
          textKey: 'itemCategory',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'Item Name',
          class: { 'text-center': true },
          textKey: 'itemName',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'MRP',
          class: { 'text-center': true },
          textKey: 'mrp',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Quantity',
          class: { 'text-center': true },
          textKey: 'quantity',
          width: '10%'
        }
      ],
      title: '',
      formLoaded: false,
      form: {
        mobile: null,
        name: null,
        address: null,
        description: null
      },
      employeeId: '',
      gstNumber: '',
      salesAssociateName: '',
      productError: null,
      value: [],
      quantity: [1],
      sellingPrice: [1],
      mrp: [1],
      articleCode: [1],
      selectedMultiBoxIndex: null,
      productOptions: [],
      isLoading: false,
      timeout: null
    };
  },
  validations() {
    const formValidation = {
      mobile: {
        required,
        numeric,
        maxLength: maxLength(10),
        minLength: minLength(10)
      },
      name: {
        required
      },
      address: {
        required,
        minLength: minLength(10)
      },
      description: {}
    };
    return { form: formValidation };
  },
  mounted() {
    this.$nextTick(async () => {
      // Code that will run only after the entire view has been re-rendered
      if (this.formType === 'new') {
        this.form.mobile = this.customerMobile;
        this.form.name = this.customerName;
        this.form.address = this.customerAddress;
        this.formLoaded = true;
        this.$v.$touch(); // Set initial validation
        this.$v.$reset(); // Reset $dirty
      }
    });
  },
  computed: {
    metaDescription() {
      return this.formType === 'new' ? 'Add new b2BOrder' : 'Update b2BOrder';
    },
    ...mapGetters('alert', ['errorMessages']),
    ...mapState('b2BOrder', ['loading', 'baseUrl', 'b2BOrder']),
    ...mapState('catalogue', ['productsList']),
    pos() {
      return this.b2BOrder?.posItems?.map(i => ({
        ...i
      }));
    }
  },
  methods: {
    ...mapActions('catalogue', ['findProducts']),
    labelWithPrice(product) {
      if (product.productName !== undefined && product.price !== undefined) {
        return `${product.productName} - ₹ ${product.price}`;
      } else {
        return `${product.productName}`;
      }
    },
    fetchUserInfo() {
      this.$emit('fetch-user', { customerMobile: this.form.mobile });
    },
    onSubmit() {
      this.productError = null;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }

      if (_.isEmpty(this.value)) {
        this.productError = 'Please add at least one product';
        return false;
      }

      const products = this.value.map((product, index) => {
        const article_Code = product.articleCode
          ? product.articleCode
          : this.articleCode[index]
          ? this.articleCode[index]
          : 1;
        const mrp = product.mrp ? product.mrp : this.mrp[index] ? this.mrp[index] : 1;
        const sellingPrice =
          product?.scale3Qty > 0 && this.quantity[index] >= product.scale3Qty
            ? product.scale3Price
            : product.scale2Qty > 0 && this.quantity[index] >= product.scale2Qty
            ? product.scale2Price
            : product.price
            ? product.price
            : this.mrp[index]
            ? this.mrp[index]
            : 1;
        return `${product.productName}:::${
          this.quantity[index] ? this.quantity[index] : 1
        }:::${article_Code}:::${mrp}:::${this.sellingPrice[index] ? this.sellingPrice[index] : sellingPrice}`;
      });
      const order = {
        customerMobile: parseInt(this.form.mobile, 10),
        orderId: this.b2BOrder.orderId,
        name: this.form.name,
        address: this.form.address,
        description: this.form.description,
        storeid: this.formType === 'new' ? null : this.b2BOrder.storeId,
        products
      };
      if (this.formType === 'new') {
        this.$emit('add', { order });
      } else {
        this.$emit('edit', { order });
      }
      return false;
    },
    deleteProduct(index) {
      this.value.splice(index - 1, 1);
    },
    asyncFind(query) {
      clearTimeout(this.timeout);
      if (query.length > 2) {
        this.timeout = setTimeout(() => {
          this.$emit('find', { query });
          this.isLoading = true;
        }, 300);
      }
    },
    selectedMulti(index) {
      this.selectedMultiBoxIndex = index;
    },
    addTag(newTag) {
      const tag = new Catalogue({
        productName: newTag,
        id: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      });
      this.productOptions.push(tag);
      this.value.push(tag);
      setTimeout(() => {
        this.$refs.inputquantity[this.value.length - 1].$refs.input.focus();
      }, 300);
    }
  },
  watch: {
    value() {
      setTimeout(() => {
        this.$refs.inputquantity[this.value.length - 1].$refs.input.focus();
      }, 300);
    },
    customerName(_newValue) {
      this.form.name = _newValue;
    },
    customerAddress(_newValue) {
      this.form.address = _newValue;
    },
    productsList(_newValue) {
      this.productOptions = _newValue;
      this.isLoading = false;
    },
    b2BOrder(_newValue, _oldValue) {
      if (!_newValue.id) {
        return;
      }
      this.employeeId = _newValue.employeeId;
      this.gstNumber = _newValue.gstNumber;
      this.salesAssociateName = _newValue.salesAssociateName;
      this.form.mobile = _newValue.customerMobile;
      this.form.name = _newValue.customerName;
      this.form.address = _newValue.address;
      this.form.description = _newValue.description;
      this.form.createdAt = _newValue.createdAt ? _newValue.createdAt : null;
      this.form.acknowledgedTime = _newValue.acknowledgedTime ? _newValue.acknowledgedTime : 'NA';
      this.form.packagedTime = _newValue.packagedTime ? _newValue.packagedTime : 'NA';
      this.form.posPingTime = _newValue.posPingTime ? _newValue.posPingTime : 'NA';
      this.form.actualDeliveredTime = _newValue.actualDeliveredTime ? _newValue.actualDeliveredTime : 'NA';
      const value = _newValue.products.split(',').map((product, index) => {
        this.quantity[index] = product.split(':::').length > 1 ? product.split(':::')[1] : 1;
        this.articleCode[index] = product.split(':::').length > 1 ? product.split(':::')[2] : 1;
        this.mrp[index] = product.split(':::').length > 1 ? product.split(':::')[3] : 1;
        this.sellingPrice[index] = product.split(':::').length > 1 ? product.split(':::')[4] : 1;
        return {
          productName: product.split(':::').length > 1 ? product.split(':::')[0] : product,
          id: product.substring(0, 2) + Math.floor(Math.random() * 10000000)
        };
      });
      this.value = value;
      this.formLoaded = true;

      this.$v.$touch(); // Set initial validation
      this.$v.$reset(); // Reset $dirty
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.imageview {
  display: inline-flex;
}
.image {
  height: 200px;
  width: 200px;
  cursor: pointer;
  margin: 5px;
  display: inline-block;
}
</style>
