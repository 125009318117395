<template>
  <div class="page-class page-user-list">
    <h1 class="page-title">{{ title }}</h1>

    <div class="barCtr" v-if="showUploadProgress">
      <h5>Downloading Report ....</h5>
      <div>
        <span class="spinner spinner-black"></span>
      </div>
      <div style="flex-grow: 1; width: 98%">
        <b-progress class="bar" :value="process" :max="100" show-progress animated></b-progress>
      </div>
    </div>
    <outbound-call-list-box
      :columns="columns"
      :rows="dialedCallList"
      :loading="loading"
      :showDownload="true"
      :showAdd="true"
      :showDayWiseReport="true"
      :showTeleCallerDayWise="true"
      :showFilter="false"
      :showToolbar="true"
      :showPagination="true"
      :showUpload="true"
      list-url="/outboundCallerList"
      :pagination="pagination"
      :searchItems="searchItems"
      :filterOptions="filterOptions"
      downloadText="Download List"
      addText="Download Report"
      dayWiseText="Day Wise Report"
      emptyText="No Data found."
      uploadText="Tellecaller Report"
      teleCallerDayWise="TeleCaller Daywise Report"
      downloadTextLive="Download Report (Live)"
      @download="onExportCSV"
      @add="onDownloadReport"
      @show-upload="onTelecallerReport"
      @day-wise-report="onDownloadDayWiseReport"
      @telecaller-day-wise-report="onTeleCallerDayWiseReport"
    >
    </outbound-call-list-box>
  </div>
</template>

<script>
import moment from 'moment';
// import Vue from 'vue';
// import csv from 'csvtojson';
// import router from '@/router';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import OutboundCallListBox from '../../components/OutboundCallListBox.vue';

export default {
  name: 'DialedCallList',
  components: {
    OutboundCallListBox
  },
  metaInfo() {
    return {
      title: 'Dialed Caller List',
      meta: [
        {
          name: 'description',
          content: `Manage Dialed Caller List`
        }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.listDialed({
        query: {
          ...this.$route.query,
          startdate: moment().format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listDialed({
        query: this.$route.query
      });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listDialed({
      query: to.query
    });
    next();
  },
  data() {
    return {
      title: 'Dialed Calls List',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: {
            'text-center': true
          },
          textKey: 'rowNum',
          width: '3%'
        },
        {
          type: 'string',
          headerText: 'Mobile',
          class: {
            'text-center': true
          },
          textKey: 'mobile',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Priority',
          class: {
            'text-center': true
          },
          textKey: 'priority',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: {
            'text-center': true
          },
          textKey: 'storeCode',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Customer Type',
          class: {
            'text-center': true
          },
          textKey: 'filterNew',
          width: '11%'
        },
        {
          type: 'string',
          headerText: 'Customer Name',
          class: { 'text-center': true },
          textKey: 'customerName',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Address',
          class: { 'text-center': true },
          textKey: 'address',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Duration (In Sec)',
          class: {
            'text-center': true
          },
          textKey: 'callDuration',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Dialed Time',
          class: {
            'text-center': true
          },
          textKey: 'lastDialedAt',
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Order Created At',
          class: {
            'text-center': true
          },
          textKey: 'orderCreatedAt',
          width: '9%'
        },
        {
          type: 'string',
          headerText: 'Converted',
          class: {
            'text-center': true
          },
          textKey: 'orderConverted',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Telecaller Name',
          class: {
            'text-center': true
          },
          textKey: 'callerName',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'Customer Disposition',
          class: {
            'text-center': true
          },
          textKey: 'customerDisposition',
          width: '10%'
        }
      ],
      searchItems: ['Store Id', 'Mobile'],
      filterOptions: [
        {
          value: null,
          text: 'Select Filter'
        },
        {
          value: 'Manual Dialed-True',
          text: 'Manual Dialed-True'
        },
        {
          value: 'Manual Dialed-False',
          text: 'Manual Dialed-False'
        }
      ],
      showUploadProgress: false,
      process: 0
    };
  },
  computed: {
    ...mapState('outboundCaller', [
      'dialedCallList',
      'downloadDialedCallList',
      'pagination',
      'loading',
      'outboundReport',
      'tellCallerReport',
      'callerReportLive',
      'dayWiseReport',
      'teleCallerDayWiseReport',
      'totalDownloadSize',
      'downloadProgress',
      'startDownloading'
    ])
  },
  watch: {
    startDownloading(newValue) {
      if (newValue === false) {
        this.process = 100;
        const delay = setInterval(() => {
          this.process = 0;
          this.showUploadProgress = newValue;
          clearInterval(delay);
        }, 2000);
      } else {
        this.showUploadProgress = newValue;
      }
    },
    downloadProgress(newValue) {
      this.process = (newValue / this.totalDownloadSize) * 100;
    },
    process(newValue) {
      if (newValue > 100) {
        this.showUploadProgress = false;
        this.process = 0;
      }
    },
    downloadDialedCallList(newValue) {
      if (newValue.length) {
        let csv =
          'Row Num,Mobile,Priority,Store Code,Customer Type, Customer Name,Address,Call Duration,Dialed Time,Order Created At,Order Converted,Telecaller Name,Customer Disposition,\n';
        newValue.forEach(row => {
          csv += `${row.rowNum},${row.mobile},${row.priority},${row.storeCode},${row.filterNew},${row.customerName},${row.address},${row.callDuration},${row.lastDialedAt},${row.orderCreatedAt},${row.orderConverted},${row.callerName},${row.customerDisposition}\n`;
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Dialed Calling List.csv';
        anchor.click();
      }
    },
    outboundReport(newValue) {
      if (newValue.length) {
        let csv = '';
        const zeroDial = [];
        newValue.forEach(value => {
          if (value.callDialed === 0) {
            zeroDial.push(value.storeCode);
          }
        });
        const zeroUp = zeroDial.join(';');
        csv += `,${zeroDial.length} Store with no call dial,     =,`;

        csv += `${zeroUp}\n\n`;
        csv += 'Region,Total Tellecaller Logged In,Call Assigned,Call Made,Call Converted,Sale Value\n';
        const regions = ['East', 'North', 'South'];
        regions.forEach(region => {
          const regionData = newValue.filter(row => row.region === region);
          const totalTelecaller = regionData.reduce((acc, row) => acc + row.callerConnected, 0);
          const callAssigned = regionData.reduce((acc, row) => acc + row.callAssigned, 0);
          const callMade = regionData.reduce((acc, row) => acc + row.callDialed, 0);
          const callConverted = regionData.reduce((acc, row) => acc + row.customerConverted, 0);
          const saleValue = regionData.reduce((acc, row) => acc + row.totalSale, 0);
          csv += `${region},${totalTelecaller},${callAssigned},${callMade},${callConverted},${saleValue}\n`;
        });
        const indiaTellecaller = regions.reduce((acc, region) => {
          const regionData = newValue.filter(row => row.region === region);
          return acc + regionData.reduce((acc, row) => acc + row.callerConnected, 0);
        }, 0);

        const indiaCallAssigned = regions.reduce((acc, region) => {
          const regionData = newValue.filter(row => row.region === region);
          return acc + regionData.reduce((acc, row) => acc + row.callAssigned, 0);
        }, 0);

        const indiaCallMade = regions.reduce((acc, region) => {
          const regionData = newValue.filter(row => row.region === region);
          return acc + regionData.reduce((acc, row) => acc + row.callDialed, 0);
        }, 0);

        const indiaCallConverted = regions.reduce((acc, region) => {
          const regionData = newValue.filter(row => row.region === region);
          return acc + regionData.reduce((acc, row) => acc + row.customerConverted, 0);
        }, 0);

        const indiaSaleValue = regions.reduce((acc, region) => {
          const regionData = newValue.filter(row => row.region === region);
          return acc + regionData.reduce((acc, row) => acc + row.totalSale, 0);
        }, 0);

        csv += `India,${indiaTellecaller},${indiaCallAssigned},${indiaCallMade},${indiaCallConverted},${indiaSaleValue}\n`;

        csv += `,,,,\n`;
        csv += `,,,,\n`;
        csv += `,,,,\n`;

        csv += 'Region,ASM,Total Tellecaller Logged In,Call Assigned,Call Made,Call Converted,Sale Value\n';

        const asmOfEast = newValue
          .filter(row => row.region === 'East')
          .map(row => row.ASMName)
          .filter((value, index, self) => self.indexOf(value) === index);
        const asmOfNorth = newValue
          .filter(row => row.region === 'North')
          .map(row => row.ASMName)
          .filter((value, index, self) => self.indexOf(value) === index);
        const asmOfSouth = newValue
          .filter(row => row.region === 'South')
          .map(row => row.ASMName)
          .filter((value, index, self) => self.indexOf(value) === index);

        asmOfEast.forEach(asm => {
          const asmName = asm;
          const asmData = newValue.filter(row => row.ASMName === asm);
          const totalTelecaller = asmData.reduce((acc, row) => acc + row.callerConnected, 0);
          const callAssigned = asmData.reduce((acc, row) => acc + row.callAssigned, 0);
          const callMade = asmData.reduce((acc, row) => acc + row.callDialed, 0);
          const callConverted = asmData.reduce((acc, row) => acc + row.customerConverted, 0);
          const saleValue = asmData.reduce((acc, row) => acc + row.totalSale, 0);
          csv += `East,${asmName},${totalTelecaller},${callAssigned},${callMade},${callConverted},${saleValue}\n`;
        });
        const eastTotalTellecaller = asmOfEast.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.callerConnected, 0);
        }, 0);

        const eastCallAssigned = asmOfEast.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.callAssigned, 0);
        }, 0);

        const eastCallMade = asmOfEast.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.callDialed, 0);
        }, 0);

        const eastCallConverted = asmOfEast.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.customerConverted, 0);
        }, 0);

        const eastSaleValue = asmOfEast.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.totalSale, 0);
        }, 0);

        csv += `East Total,,${eastTotalTellecaller},${eastCallAssigned},${eastCallMade},${eastCallConverted},${eastSaleValue}\n`;

        csv += `,,,,\n`;
        csv += `,,,,\n`;

        asmOfNorth.forEach(asm => {
          const asmName = asm;
          const asmData = newValue.filter(row => row.ASMName === asm);
          const totalTelecaller = asmData.reduce((acc, row) => acc + row.callerConnected, 0);
          const callAssigned = asmData.reduce((acc, row) => acc + row.callAssigned, 0);
          const callMade = asmData.reduce((acc, row) => acc + row.callDialed, 0);
          const callConverted = asmData.reduce((acc, row) => acc + row.customerConverted, 0);
          const saleValue = asmData.reduce((acc, row) => acc + row.totalSale, 0);
          csv += `North,${asmName},${totalTelecaller},${callAssigned},${callMade},${callConverted},${saleValue}\n`;
        });

        const northTotalTellecaller = asmOfNorth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.callerConnected, 0);
        }, 0);

        const northCallAssigned = asmOfNorth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.callAssigned, 0);
        }, 0);

        const northCallMade = asmOfNorth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.callDialed, 0);
        }, 0);

        const northCallConverted = asmOfNorth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.customerConverted, 0);
        }, 0);

        const northSaleValue = asmOfNorth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.totalSale, 0);
        }, 0);

        csv += `North Total,,${northTotalTellecaller},${northCallAssigned},${northCallMade},${northCallConverted},${northSaleValue}\n`;

        csv += `,,,,\n`;
        csv += `,,,,\n`;

        asmOfSouth.forEach(asm => {
          const asmName = asm;
          const asmData = newValue.filter(row => row.ASMName === asm);
          const totalTelecaller = asmData.reduce((acc, row) => acc + row.callerConnected, 0);
          const callAssigned = asmData.reduce((acc, row) => acc + row.callAssigned, 0);
          const callMade = asmData.reduce((acc, row) => acc + row.callDialed, 0);
          const callConverted = asmData.reduce((acc, row) => acc + row.customerConverted, 0);
          const saleValue = asmData.reduce((acc, row) => acc + row.totalSale, 0);
          csv += `South,${asmName},${totalTelecaller},${callAssigned},${callMade},${callConverted},${saleValue}\n`;
        });

        const southTotalTellecaller = asmOfSouth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.callerConnected, 0);
        }, 0);

        const southCallAssigned = asmOfSouth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.callAssigned, 0);
        }, 0);

        const southCallMade = asmOfSouth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.callDialed, 0);
        }, 0);

        const southCallConverted = asmOfSouth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.customerConverted, 0);
        }, 0);

        const southSaleValue = asmOfSouth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.totalSale, 0);
        }, 0);

        csv += `South Total,,${southTotalTellecaller},${southCallAssigned},${southCallMade},${southCallConverted},${southSaleValue}\n`;

        csv += `,,,,\n`;
        csv += `,,,,\n`;
        csv += `,,,,\n`;

        csv += 'Region,Store Code,Store Name,Tellecaller Name,Call Assigned,Call Made,Call Converted,Sale Value\n';

        newValue.forEach(row => {
          const callerName = row.callerName.join(' & ');
          csv += `${row.region},${row.storeCode},${row.storeName},${callerName},${row.callAssigned},${row.callDialed},${row.customerConverted},${row.totalSale}\n`;
        });

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Outbound Calling Report.csv';
        anchor.click();
      }
    },
    dayWiseReport(newValue) {
      if (newValue.length) {
        const startDate = this.$route.query.startdate;
        const endDate = this.$route.query.enddate;
        let csv = '';
        if (startDate === undefined) {
          csv += `,Date from -\n`;
        } else {
          csv += `,Date From ${startDate} To ${endDate}\n`;
        }
        csv += '\n';
        csv +=
          'Region,Store Code,Store Name,Tellecaller Name,Report Date,Call Assigned,Call Made,Call Converted,Sale Value\n';

        newValue.forEach(row => {
          const callerName = row.callerName.join(' & ');
          csv += `${row.region},${row.storeId},${row.storeName},${callerName},${row.reportDate},${row.callAssigned},${row.callDialed},${row.customerConverted},${row.totalSale}\n`;
        });

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Outbound DayWise Report.csv';
        anchor.click();
      }
    },
    tellCallerReport(newValue) {
      if (newValue.length) {
        const startDate = this.$route.query.startdate;
        const endDate = this.$route.query.enddate;
        let csv = '';
        if (startDate === undefined) {
          csv += `,Date from -\n`;
        } else {
          csv += `,Date From ${startDate} To ${endDate}\n`;
        }
        csv += '\n';
        csv += 'Store Code,Tellecaller Name,Call Made,Call Converted,Login Number,Employee Id\n';
        newValue.forEach(({ _id, callDialed, orderConverted, login, employeeId }) => {
          csv += `${_id.storeCode},${_id.callerName},${callDialed},${orderConverted},${login.map(
            i => i
          )},${employeeId.map(i => i)}\n`;
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Tellecaller Report.csv';
        anchor.click();
      }
    },
    teleCallerDayWiseReport(newValue) {
      if (newValue.length) {
        const startDate = this.$route.query.startdate;
        const endDate = this.$route.query.enddate;
        let csv = '';
        if (startDate === undefined) {
          csv += `,Date from -\n`;
        } else {
          csv += `,Date From ${startDate} To ${endDate}\n`;
        }
        csv += '\n';
        csv += 'Store Code,Tellecaller Name, Date,Call Made,Call Converted,LoginMobile,Employee Id\n';
        newValue.forEach(({ _id, callDialed, orderConverted, login, employeeId }) => {
          csv += `${_id.storeCode},${_id.callerName}, ${moment(_id.callingDate).format(
            'DD-MM-YYYY'
          )},${callDialed},${orderConverted},${login.map(i => i)},${employeeId.map(i => i)}\n`;
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Tellecaller DayWise Report.csv';
        anchor.click();
      }
    },
    callerReportLive(newValue) {
      if (newValue.length) {
        const startDate = moment().format('YYYY-MM-DD');

        let csv = '';
        csv += `,Live Report for Date - ${startDate}\n\n`;

        csv += 'Region,Total Tellecaller Logged In,Call Assigned,Call Made,Call Converted,Sale Value\n';
        const regions = ['East', 'North', 'South'];
        regions.forEach(region => {
          const regionData = newValue.filter(row => row.region === region);
          const totalTelecaller = regionData.reduce((acc, row) => acc + row.callerConnected, 0);
          const callAssigned = regionData.reduce((acc, row) => acc + row.callAssigned, 0);
          const callMade = regionData.reduce((acc, row) => acc + row.callDialed, 0);
          const callConverted = regionData.reduce((acc, row) => acc + row.customerConverted, 0);
          const saleValue = regionData.reduce((acc, row) => acc + row.totalSale, 0);
          csv += `${region},${totalTelecaller},${callAssigned},${callMade},${callConverted},${saleValue}\n`;
        });
        const indiaTellecaller = regions.reduce((acc, region) => {
          const regionData = newValue.filter(row => row.region === region);
          return acc + regionData.reduce((acc, row) => acc + row.callerConnected, 0);
        }, 0);

        const indiaCallAssigned = regions.reduce((acc, region) => {
          const regionData = newValue.filter(row => row.region === region);
          return acc + regionData.reduce((acc, row) => acc + row.callAssigned, 0);
        }, 0);

        const indiaCallMade = regions.reduce((acc, region) => {
          const regionData = newValue.filter(row => row.region === region);
          return acc + regionData.reduce((acc, row) => acc + row.callDialed, 0);
        }, 0);

        const indiaCallConverted = regions.reduce((acc, region) => {
          const regionData = newValue.filter(row => row.region === region);
          return acc + regionData.reduce((acc, row) => acc + row.customerConverted, 0);
        }, 0);

        const indiaSaleValue = regions.reduce((acc, region) => {
          const regionData = newValue.filter(row => row.region === region);
          return acc + regionData.reduce((acc, row) => acc + row.totalSale, 0);
        }, 0);

        csv += `India,${indiaTellecaller},${indiaCallAssigned},${indiaCallMade},${indiaCallConverted},${indiaSaleValue}\n`;

        csv += `,,,,\n`;
        csv += `,,,,\n`;
        csv += `,,,,\n`;

        csv += 'Region,ASM,Total Tellecaller Logged In,Call Assigned,Call Made,Call Converted,Sale Value\n';

        const asmOfEast = newValue
          .filter(row => row.region === 'East')
          .map(row => row.ASMName)
          .filter((value, index, self) => self.indexOf(value) === index);
        const asmOfNorth = newValue
          .filter(row => row.region === 'North')
          .map(row => row.ASMName)
          .filter((value, index, self) => self.indexOf(value) === index);
        const asmOfSouth = newValue
          .filter(row => row.region === 'South')
          .map(row => row.ASMName)
          .filter((value, index, self) => self.indexOf(value) === index);

        asmOfEast.forEach(asm => {
          const asmName = asm;
          const asmData = newValue.filter(row => row.ASMName === asm);
          const totalTelecaller = asmData.reduce((acc, row) => acc + row.callerConnected, 0);
          const callAssigned = asmData.reduce((acc, row) => acc + row.callAssigned, 0);
          const callMade = asmData.reduce((acc, row) => acc + row.callDialed, 0);
          const callConverted = asmData.reduce((acc, row) => acc + row.customerConverted, 0);
          const saleValue = asmData.reduce((acc, row) => acc + row.totalSale, 0);
          csv += `East,${asmName},${totalTelecaller},${callAssigned},${callMade},${callConverted},${saleValue}\n`;
        });
        const eastTotalTellecaller = asmOfEast.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.callerConnected, 0);
        }, 0);

        const eastCallAssigned = asmOfEast.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.callAssigned, 0);
        }, 0);

        const eastCallMade = asmOfEast.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.callDialed, 0);
        }, 0);

        const eastCallConverted = asmOfEast.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.customerConverted, 0);
        }, 0);

        const eastSaleValue = asmOfEast.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.totalSale, 0);
        }, 0);

        csv += `East Total,,${eastTotalTellecaller},${eastCallAssigned},${eastCallMade},${eastCallConverted},${eastSaleValue}\n`;

        csv += `,,,,\n`;
        csv += `,,,,\n`;

        asmOfNorth.forEach(asm => {
          const asmName = asm;
          const asmData = newValue.filter(row => row.ASMName === asm);
          const totalTelecaller = asmData.reduce((acc, row) => acc + row.callerConnected, 0);
          const callAssigned = asmData.reduce((acc, row) => acc + row.callAssigned, 0);
          const callMade = asmData.reduce((acc, row) => acc + row.callDialed, 0);
          const callConverted = asmData.reduce((acc, row) => acc + row.customerConverted, 0);
          const saleValue = asmData.reduce((acc, row) => acc + row.totalSale, 0);
          csv += `North,${asmName},${totalTelecaller},${callAssigned},${callMade},${callConverted},${saleValue}\n`;
        });

        const northTotalTellecaller = asmOfNorth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.callerConnected, 0);
        }, 0);

        const northCallAssigned = asmOfNorth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.callAssigned, 0);
        }, 0);

        const northCallMade = asmOfNorth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.callDialed, 0);
        }, 0);

        const northCallConverted = asmOfNorth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.customerConverted, 0);
        }, 0);

        const northSaleValue = asmOfNorth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.totalSale, 0);
        }, 0);

        csv += `North Total,,${northTotalTellecaller},${northCallAssigned},${northCallMade},${northCallConverted},${northSaleValue}\n`;

        csv += `,,,,\n`;
        csv += `,,,,\n`;

        asmOfSouth.forEach(asm => {
          const asmName = asm;
          const asmData = newValue.filter(row => row.ASMName === asm);
          const totalTelecaller = asmData.reduce((acc, row) => acc + row.callerConnected, 0);
          const callAssigned = asmData.reduce((acc, row) => acc + row.callAssigned, 0);
          const callMade = asmData.reduce((acc, row) => acc + row.callDialed, 0);
          const callConverted = asmData.reduce((acc, row) => acc + row.customerConverted, 0);
          const saleValue = asmData.reduce((acc, row) => acc + row.totalSale, 0);
          csv += `South,${asmName},${totalTelecaller},${callAssigned},${callMade},${callConverted},${saleValue}\n`;
        });

        const southTotalTellecaller = asmOfSouth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.callerConnected, 0);
        }, 0);

        const southCallAssigned = asmOfSouth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.callAssigned, 0);
        }, 0);

        const southCallMade = asmOfSouth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.callDialed, 0);
        }, 0);

        const southCallConverted = asmOfSouth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.customerConverted, 0);
        }, 0);

        const southSaleValue = asmOfSouth.reduce((acc, asm) => {
          const asmData = newValue.filter(row => row.ASMName === asm);
          return acc + asmData.reduce((acc, row) => acc + row.totalSale, 0);
        }, 0);

        csv += `South Total,,${southTotalTellecaller},${southCallAssigned},${southCallMade},${southCallConverted},${southSaleValue}\n`;

        csv += `,,,,\n`;
        csv += `,,,,\n`;
        csv += `,,,,\n`;

        csv += 'Region,Store Code,Store Name,Tellecaller Name,Call Assigned,Call Made,Call Converted,Sale Value\n';

        newValue.forEach(row => {
          const callerName = row.callerName.join(' & ');
          csv += `${row.region},${row.storeCode},${row.storeName},${callerName},${row.callAssigned},${row.callDialed},${row.customerConverted},${row.totalSale}\n`;
        });

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'OutboundCaller Live Report.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('outboundCaller', [
      'listDialed',
      'downloadDialed',
      'downloadReport',
      'downloadTelleReport',
      'downloadCallerReportLive',
      'dayWiseOutboundReport',
      'dayWiseTeleCallerReport'
    ]),
    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.downloadDialed({
          query: {
            ...this.$route.query
          }
        });
      } else {
        this.downloadDialed({
          query: {
            ...this.$route.query,
            startdate: moment()
              .subtract(1, 'months')
              .format('MMM YYYY')
          }
        });
      }
    },
    onDownloadReport() {
      if (
        Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate') &&
        this.$route.query.startdate === moment().format('YYYY-MM-DD') &&
        this.$route.query.enddate === moment().format('YYYY-MM-DD')
      ) {
        this.downloadCallerReportLive({
          query: {
            ...this.$route.query,
            startdate: moment().format('YYYY-MM-DD'),
            enddate: moment().format('YYYY-MM-DD')
          }
        });
      } else if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.downloadReport({
          query: {
            ...this.$route.query
          }
        });
      } else {
        this.downloadReport({
          query: {
            ...this.$route.query,
            startdate: moment()
              .subtract(1, 'months')
              .format('MMM YYYY')
          }
        });
      }
    },
    onTelecallerReport() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.downloadTelleReport({
          query: {
            ...this.$route.query
          }
        });
      } else {
        this.downloadTelleReport({
          query: {
            ...this.$route.query,
            startdate: moment()
              .subtract(1, 'months')
              .format('MMM YYYY')
          }
        });
      }
    },
    onDownloadDayWiseReport() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.dayWiseOutboundReport({
          query: {
            ...this.$route.query
          }
        });
      } else {
        this.dayWiseOutboundReport({
          query: {
            ...this.$route.query,
            startdate: moment()
              .subtract(1, 'months')
              .format('MMM YYYY')
          }
        });
      }
    },
    onTeleCallerDayWiseReport() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.dayWiseTeleCallerReport({
          query: {
            ...this.$route.query
          }
        });
      } else {
        this.dayWiseTeleCallerReport({
          query: {
            ...this.$route.query,
            startdate: moment()
              .subtract(1, 'months')
              .format('MMM YYYY')
          }
        });
      }
    }
  }
};
</script>

<style>
.barCtr {
  display: flex;
  align-items: center;
  padding: 0;
  gap: 30px;
  margin-inline: 10px;
  justify-content: center;
  margin-bottom: 15px;
}

@media screen and (max-width: 400px) {
  .barCtr {
    flex-direction: column;
    gap: 5px;
  }
}
</style>
