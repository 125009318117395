<template>
  <div class="containerSearch">
    <b-row class="productRow" cols-xl="6" cols-lg="5" cols-md="4" cols-sm="3" cols-xs="2">
      <div v-if="loading" class="d-flex justify-content-center align-items-center">
        <span class="spinner-border" style="width: 3rem; height: 3rem;" role="status"></span>
      </div>
      <div class="noneProducts" v-if="!loading && productsLength === 0"
        >Sorry there are no products available for the selected store please change the store or visit later...</div
      >
      <b-card v-else no-body class="card_body wrappers" v-for="product in products" :key="product.id">
        <b-badge class="discount_badge" v-if="product.discount !== null && product.discount !== 0"
          >{{ product.discount }}% off</b-badge
        >
        <b-badge class="offer_badge target" v-if="product.offers !== null">{{ product.offers }}</b-badge>
        <div class="imageControl">
          <img class="card_image" v-bind:src="product.images" />
        </div>
        <div class="productname">{{ product.description }}</div>
        <div v-if="product.discount === null || product.discount === 0" class="productprice"> ₹ {{ product.mrp }}</div>
        <div style="display: flex" v-if="product.discount >= 1">
          <div class="productprice" v-if="product.discount !== null"> ₹ {{ product.sellingPrice }}</div>
          <div class="productprice cutPrice" style="font-weight: 400; text-decoration: line-through">
            ₹ {{ product.mrp }}</div
          >
        </div>
        <div>
          <b-button block class="cartbutton" v-if="!cartItems[product._id]" variant="info" @click="addToCart(product)"
            >Add to Cart
          </b-button>
          <div class="quantity_btn_div" v-else>
            <b-button class="plus_btn" @click="removeFromCart(product)">
              <font-awesome-icon :icon="['fas', 'minus']" class="mr-1 arrow_icon"
            /></b-button>
            <div>
              <b-form-input
                class="text-center"
                type="text"
                :no-wheel="true"
                min="0"
                @keydown="handleKeyDown($event)"
                @input="handleChange(product, $event)"
                :value="cartItems[product._id].quantity"
              ></b-form-input>
            </div>
            <b-button class="minus_btn" @click="addToCart(product)">
              <font-awesome-icon :icon="['fas', 'plus']" class="mr-1 arrow_icon" />
            </b-button>
          </div>
        </div>
        <b-button block id="offer-modal" @click="showModal(product)">View Offers </b-button>
      </b-card>
    </b-row>
    <b-modal ref="offer-modal" :title="modalProducts.description" ok-only>
      <div v-if="!loading" class="table-row table-row-empty">
        <div class="text-center">
          <!-- {{ modalProducts }} -->
          <h2 v-html="handleProduct(this.modalProducts)"></h2>
        </div>
        <!-- <div class="text-center"
          ><h2>{{ modalOffers.offerCommunication ? modalOffers.offerCommunication : 'NO OFFER FOUND' }}</h2></div
        > -->
      </div>
      <div v-else class="table-row table-row-empty">
        <div class="text-center"
          >Fetching Offer...
          <span class="spinner spinner-black"></span>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import router from '@/router';
import moment from 'moment';

export default {
  data() {
    return {
      products: [],
      cartItems: {},
      modalProducts: {},
      modalOffers: {},
      todayDate: moment().format('YYYY-MM-DD')
    };
  },
  mounted() {
    const storeList = localStorage.getItem('storeData') || '{}';
    const store = JSON.parse(storeList);
    this.listCustomer({
      query: {
        storeId: store.storeid
      }
    });
    const cartItems = localStorage.getItem('product-cart') || '{}';
    this.cartItems = JSON.parse(cartItems);
  },
  computed: {
    ...mapState('product', ['customerList', 'offer', 'loading']),
    productsLength() {
      return this.products ? this.products.length : 0;
    }
  },
  methods: {
    ...mapActions('product', ['listCustomer', 'setCartItem', 'getOffer']),
    handleProduct(item) {
      let offer = '';
      if (item.scale3Csp !== 0) {
        offer = `BUY 1PC @${item.sellingPrice}EACH <br/> BUY ${item.scale2Qty}PC @${item.scale2Csp} EACH <br/> BUY ${item.scale3Qty} PC @${item.scale3Csp}EACH`;
      } else if (item.scale2Csp !== 0) {
        offer = `BUY 1PC @${item.sellingPrice}EACH <br/> BUY ${item.scale2Qty}PC @${item.scale2Csp} EACH`;
      } else {
        offer = `BUY 1PC @${item.sellingPrice}EACH`;
      }
      return offer;
    },
    searchProduct(searchInput) {
      this.products = this.customerList.filter(product => {
        return product.description.toLowerCase().includes(searchInput.toLowerCase());
      });
    },
    showModal(product) {
      this.modalProducts = product;
      this.getOffer({ articleId: product.articleCode, storeId: product.storeids[0], router: this.$router });
      this.$refs['offer-modal'].show();
    },
    goHome() {
      router.push(`/categoryList`);
    },
    handleKeyDown(event) {
      if (
        !(
          (
            event.key.match(/^[0-9]$/) || // Allow digits 0-9
            event.key === 'Backspace' || // Allow Backspace key
            event.key === 'Delete'
          ) // Allow Delete key
        )
      ) {
        event.preventDefault(); // Prevent typing non-digit characters
      }
    },
    handleChange(product, event) {
      const cartItems = { ...this.cartItems };
      const productId = product._id; // eslint-disable-line

      if (cartItems[productId]) {
        const temp = Number(event.replace(/\D/g, ''));
        const quantity = temp;
        if (quantity < 0) {
          delete cartItems[productId];
        } else {
          cartItems[productId] = {
            ...cartItems[productId],
            quantity
          };
        }
      }
      this.cartItems = cartItems;
      this.setCartItem({ cartItems });
      window.localStorage.setItem('product-cart', JSON.stringify(cartItems));
    },
    addToCart(product) {
      const cartItems = { ...this.cartItems };
      const productId = product._id; // eslint-disable-line
      if (cartItems[productId]) {
        cartItems[productId] = {
          ...cartItems[productId],
          quantity: cartItems[productId].quantity + 1
        };
      } else {
        cartItems[productId] = { quantity: 1 };
      }
      this.cartItems = cartItems;
      this.setCartItem({ cartItems });
      window.localStorage.setItem('product-cart', JSON.stringify(cartItems));
    },
    removeFromCart(product) {
      const cartItems = { ...this.cartItems };
      const productId = product._id; // eslint-disable-line

      if (cartItems[productId]) {
        const quantity = cartItems[productId].quantity - 1;
        if (quantity < 1) {
          delete cartItems[productId];
        } else {
          cartItems[productId] = {
            ...cartItems[productId],
            quantity
          };
        }
      }
      this.cartItems = cartItems;
      this.setCartItem({ cartItems });
      window.localStorage.setItem('product-cart', JSON.stringify(cartItems));
    }
  },
  watch: {
    offer(newValue) {
      if (newValue) {
        const newPromo = newValue.delta;
        if (newPromo === 'NEW PROMO') {
          const validToDate = newValue.validTo;

          if (this.todayDate >= validToDate) {
            this.modalOffers = {};
          } else {
            this.modalOffers = newValue;
          }
        } else {
          this.modalOffers = {};
        }
      }
    },
    customerList(newValue) {
      this.products = newValue;
      this.searchProduct(this.$route.query.query);
    },
    $route(to) {
      this.searchProduct(to.query.query);
    }
  }
};
</script>

<style scoped>
.noneProducts {
  text-align: center;
  font-weight: 500;
  font-size: 20ps;
  margin-top: 10%;
}
.containerSearch {
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  position: relative;
  font-family: Myriad;
}
.productRow {
  /* border: 1px solid; */
  padding: 2%;
  gap: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.card_body {
  width: 40%;
  /* border: 1px solid; */
  margin: 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  border-radius: unset;
  border-radius: 10px;
  height: 100%;
}
.card_body:hover {
  box-shadow: #eeb790 0px 3px 9px;
}
.wrappers {
  overflow-x: hidden;
  overflow-y: hidden;
}
.offer_badge {
  height: 20px;
  border-radius: 15px 80px;
  position: absolute;
  background: #f28a3f;
  transform: translate(1%, 60%);
}
.discount_badge {
  width: 85px;
  height: 20px;
  padding: 5px;
  border-radius: 1px 15px 1px 18px;
  position: absolute;
  top: -2px;
  background: #f28a3f;
  transform: translate(1%, 60%);
  z-index: 10;
}
.target {
  margin-top: 10px;
  animation-name: rightToLeft;
  animation-duration: 8s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.imageControl {
  width: 60%;
  height: 13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  /* border: 1px solid; */
  /* margin: 32px 15px 5px 4px; */
  margin-top: 20%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.card_image {
  width: 100%;
  /* padding: 0.5rem; */
  /* margin: 32px 15px 5px 4px; */
  max-width: 100%;
  min-width: 80%;
  max-height: 100%;
  min-height: 80%;
}
.productname {
  margin-block: 10px;
  font-size: 20px;
  color: #383838;
  font-weight: 500;
  padding-left: 8px;
  width: auto;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.productname:hover {
  white-space: normal;
}
.productprice {
  color: #383838;
  font-size: 15px;
  font-weight: 500;
  padding-left: 8px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.cartbutton {
  background-color: #f28a3f;
  margin-bottom: -0.1rem;
  border: 1px solid #f28a3f;
  border-radius: unset;
}
.cartbutton:hover {
  background-color: #f28a3f;
  border: transparent;
}
.quantity_btn_div {
  display: flex;
  justify-content: space-between;
  border: 1px solid #f28a3f;
  border-radius: 2px;
}
.quantity_btn_div > span {
  color: #383838;
  font-size: 25px;
  margin-left: 9%;
  font-weight: 400;
}
.plus_btn {
  width: 35%;
  margin: 1px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 15px;
  font-weight: 900;
  border-radius: 2px;
  background-color: #f28a3f;
}
.minus_btn {
  width: 35%;
  outline: none;
  border: none;
  padding: 0px;
  color: #fff;
  font-size: 15px;
  font-weight: 900;
  margin: 1px 1px 1px auto;
  border-radius: 2px;
  background-color: #f28a3f;
}
@keyframes rightToLeft {
  0% {
    transform: translateX(270px);
  }
  100% {
    transform: translateX(-100px);
  }
}
@media screen and (max-width: 500px) {
  .productname {
    margin-block: 2px;
    font-size: 15px;
  }
  .imageControl {
    width: 80%;
    height: 10rem;
  }
}
</style>
