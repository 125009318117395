<template>
  <div class="page-class page-user-list">
    <h1 class="page-title">{{ title }}</h1>
    <table-box-analytics
      :columns="columns"
      :rows="orderRecordings"
      :pagination="pagination"
      :baseUrl="baseUrl"
      :loading="loading"
      :searchItems="searchItems"
      :callStatusOptions="callStatusOptions"
      :filterOptions="filterOptions"
      emptyText="No logs found"
      downloadText="Download Order Recordings"
      :showAdd="false"
      :showDownload="true"
      :showFilter="true"
      :showPagination="true"
      :userRole="user.role"
      @add-order="onAddOrder"
      @download="onExportCSV"
      @change-status="onChangeStatus"
    >
    </table-box-analytics>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TableBoxAnalytics from '@/components/TableBoxAnalytics.vue';
import router from '@/router';
import moment from 'moment';
import _ from 'lodash';

export default {
  name: 'OrderRecordings',
  components: {
    TableBoxAnalytics
  },
  metaInfo() {
    return {
      title: 'OrderRecordings',
      meta: [
        {
          name: 'description',
          content: `See Order Recording List`
        }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.list({
        query: {
          ...this.$route.query,
          startdate: moment().format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        },
        companyId: this.user.type
      });
    } else {
      this.list({ query: this.$route.query, companyId: this.user.type });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.list({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Order Recordings',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'id',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Caller Id',
          class: { 'text-center': true },
          textKey: 'callerId',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Manager Number',
          class: { 'text-center': true },
          textKey: 'manager_number',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Recording Link',
          class: { 'text-center': true },
          audioKey: 'filename',
          width: '25%'
        },
        {
          type: 'string',
          headerText: 'Recorded On',
          class: { 'text-center': true },
          textKey: 'time',
          width: '15%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '25%',
          functions: {
            addOrder: true,
            edit: false,
            delete: false,
            callStatus: true
          }
        }
      ],
      searchItems: ['Caller Id', 'Manager Number', 'Store Id'],
      callStatusOptions: [
        { value: 0, text: 'Select Status' },
        { value: 1, text: 'Order Serviced' },
        { value: 2, text: 'Product Not Available' },
        { value: 3, text: 'Enquiry Call' },
        { value: 4, text: 'Out of Serviceable Area' },
        { value: 5, text: 'Customer Not Reachable' }
      ],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'NOT_SELECTED', text: 'Not Selected' },
        { value: 'SERVICED', text: 'Order Serviced' },
        { value: 'NOT_AVAILABLE', text: 'Product Not Available' },
        { value: 'ENQUIRY_CALL', text: 'Enquiry Call' },
        { value: 'OUT_OF_AREA', text: 'Out of Serviceable Area' },
        { value: 'NOT_REACHABLE', text: 'Customer Not Reachable' }
      ]
    };
  },
  computed: {
    ...mapState('recording', ['loading', 'baseUrl', 'pagination', 'orderRecordings', 'downloadOrderRecordings']),
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('recording', ['list', 'download', 'updateRecordingStatusFeedback']),
    onAddOrder({ row }) {
      if (row.callerId && (row.callerId.length === 10 || row.callerId.length === 12)) {
        router.push(`/order/new/${row.callerId}`);
      } else {
        router.push('/order/new');
      }
    },
    onExportCSV() {
      this.download({ query: { ...this.$route.query, page_size: 99999, page: 1 }, companyId: this.user.type });
    },
    onChangeStatus({ row, event }) {
      this.updateRecordingStatusFeedback({
        query: {
          id: row.id,
          status: event
        }
      });
    }
  },
  watch: {
    downloadOrderRecordings(newValue) {
      if (newValue.length) {
        let csv = 'Id,Caller Id,Manager Number,Store Id,Recording Link,Recorded On,Feedback\n';
        newValue.forEach(row => {
          Object.values(row).forEach(val => {
            csv += `"${val}"`;
            csv += ',';
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'OrderRecording.csv';
        anchor.click();
      }
    }
  }
};
</script>
