<template>
  <div>
    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group id="group-name" label-for="input-name">
          <template v-slot:label>
            Store Name
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            :formatter="formatter"
            :space="space"
            id="input-name"
            type="text"
            v-model="form.storeName"
            placeholder="Enter Store Name"
          ></b-form-input>

          <b-form-group id="group-name" label-for="input-companyType" v-if="storeType === 'store'">
            <template v-slot:label>
              Company Type
              <span class="text-danger">*</span>
            </template>
            <b-form-select
              id="input-compantType"
              v-model="form.type"
              :options="storeTypes"
              :state="$v.form.type.$dirty ? !$v.form.type.$error : null"
            ></b-form-select>

            <b-form-text id="input-role-help"
              >Company Type field is configuring whether the store is Spencer or Nature.</b-form-text
            >

            <b-form-invalid-feedback id="input-role-invalid">Please select valid role.</b-form-invalid-feedback>
          </b-form-group>

          <b-form-invalid-feedback id="input-name-invalid">Please enter Store Name.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group id="group-name" label-for="input-name">
          <template v-slot:label>
            Store Id
            <span class="text-danger">*</span>
          </template>

          <b-form-input id="input-name" type="text" v-model="form.storeid" placeholder="Enter Store Id "></b-form-input>

          <b-form-invalid-feedback id="input-name-invalid">Please enter Store Id.</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label>
            Format
            <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="form.format"
            ref="multiselect"
            tag-placeholder="Add this as new tag"
            label="text"
            track-by="value"
            :options="formats"
            :multiple="false"
            :taggable="true"
            @tag="i => addTag(i, 'format')"
          ></multiselect>
          <b-form-text id="input-role-help">Please select the store format.</b-form-text>
        </b-form-group>
        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label>
            Cluster
            <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="form.cluster"
            ref="multiselect"
            tag-placeholder="Add this as new tag"
            label="text"
            track-by="text"
            :options="clusterIds"
            :multiple="false"
            :taggable="true"
            @tag="i => addTag(i, 'cluster')"
          ></multiselect>
          <b-form-text id="input-role-help">Please select the cluster where store is located.</b-form-text>
        </b-form-group>
        <b-form-group id="group-products" label-for="input-products">
          <template v-slot:label>
            Region
            <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="form.region"
            ref="multiselect"
            tag-placeholder="Add this as new tag"
            label="text"
            track-by="text"
            :options="regionIds"
            :multiple="false"
            :taggable="true"
            @tag="i => addTag(i, 'region')"
          ></multiselect>
          <b-form-text id="input-role-help">Please select the region where store is located.</b-form-text>
        </b-form-group>

        <b-form-group id="input-lat" label-for="input-lat">
          <template v-slot:label>
            Location (latitude)
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            :formatter="formatter"
            :space="space"
            id="input-lat"
            type="text"
            v-model="lat"
            placeholder="enter Location"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-long" label-for="input-long">
          <template v-slot:label>
            Location (longitude)
            <span class="text-danger">*</span>
          </template>
          <b-form-input
            :formatter="formatter"
            :space="space"
            id="input-long"
            type="text"
            v-model="long"
            placeholder="enter Location"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols-lg="8"
          label="Pincodes"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          class="mb-2"
        >
        </b-form-group>
        <b-form-group>
          <div v-for="(input, index) in pincodes" :key="`pincode-${index}`" class="input wrapper flex items-center">
            <b-row class="mb-2">
              <b-col cols="4">
                <b-form-input
                  :value="pincodes[index]"
                  type="text"
                  placeholder=" Enter Pincode"
                  @change="handleChange($event, index, pincodes, null)"
                ></b-form-input>
              </b-col>
              <b-col cols="1">
                <b-button @click="removeField(index, pincodes)" variant="danger">Delete</b-button>
              </b-col>
            </b-row>
          </div>
          <b-button @click="addField(pincodes)" variant="primary">Add More</b-button>
        </b-form-group>

        <b-row class="mt-2">
          <b-col class="text-right">
            <b-button type="submit" size="sm" variant="success" :disabled="loading">
              <span class="spinner spinner-white" v-if="loading"></span>
              <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
            </b-button>
            <b-button class="ml-2" size="sm" variant="warning" :to="{ path: `/store` }">
              <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapState } from 'vuex';
import _ from 'lodash';
import router from '@/router';
import Multiselect from 'vue-multiselect';
import Store from '../model/store';

export default {
  name: 'StoreFormBox',
  components: {
    Multiselect
  },
  props: {
    listUrl: String,
    formType: String,
    storeType: String
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: this.metaDescription
        }
      ]
    };
  },
  data() {
    return {
      formLoaded: false,
      options: [],
      regionIds: [],
      clusterIds: [],
      formats: [],
      pincodes: [''],
      locationId: '',
      lat: '',
      long: '',
      form: {
        storeName: '',
        storeid: '',
        cluster: null,
        region: null,
        format: null,
        type: null
      },
      storeTypes: _.reduce(
        _.pick(Store.storeType, ['spencer', 'nature']),
        (result, value, key) => {
          result.push({ value, text: Store.nameStoreType[Store.storeType[key]] });
          return result;
        },
        []
      )
    };
  },
  validations() {
    const formValidation = {
      name: {
        required
      },
      permission: {
        required
      },
      type: {
        validateType: value => {
          if (this.storeType === 'store') {
            return true;
          }
          return _.some(Store.storeType, type => value === type);
        }
      }
    };

    if (this.formType === 'new') {
      formValidation.name.required = required;
      formValidation.permission.required = required;
    }

    return { form: formValidation };
  },
  mounted() {
    this.listCluster({ router, query: { type: 'cluster' } });
    this.listRegion({ router, query: { type: 'region' } });
    this.listFormat({ router, query: { type: 'format' } });

    this.$nextTick(async () => {
      if (this.formType === 'new') {
        this.form.type = Store.storeType.type;
        this.formLoaded = true;
        this.$v.$touch(); // Set initial validation
        this.$v.$reset(); // Reset $dirty
      }
    });
  },
  computed: {
    metaDescription() {
      return this.formType === 'new' ? 'Add new store' : 'Update Store';
    },
    ...mapState('user', ['loading', 'user', 'availableRoles']),
    ...mapState('store', ['storeList', 'regionList', 'clusterList', 'formatList', 'store'])
  },
  methods: {
    ...mapActions('store', ['listStore', 'listRegion', 'listCluster', 'listFormat']),

    formatter(name) {
      return name.toUpperCase();
    },
    space(name) {
      return name.split(' ').join('_');
    },
    handleChange(value, index, fieldType) {
      fieldType.splice(index, 1, value);
    },
    addField(fieldType) {
      fieldType.push('');
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },

    onSubmit() {
      const store = {
        storeName: this.form.storeName,
        storeid: this.form.storeid,
        cluster: this.form.cluster.value,
        region: this.form.region.value,
        format: this.form.format.value,
        type: this.form.type,
        pincodes: this.pincodes,
        locations: {
          lat: this.lat,
          long: this.long
        },
        locationId: this.locationId
      };
      //  /^[0-9.,]+$/ - value.match(/^[0-9.,]+$/) ----------------------------------------------------------------
      if (this.formType === 'new') {
        this.$emit('add', { store });
      } else {
        this.$emit('edit', { store });
      }
      return false;
    },
    addTag(newTag, type) {
      const tag = {
        text: newTag,
        value: newTag
      };
      switch (type) {
        case 'format':
          this.form.format = tag;
          this.formats = [...this.formats, tag];
          break;
        case 'cluster':
          this.form.cluster = tag;
          this.clusterIds = [...this.clusterIds, tag];
          break;
        case 'region':
          this.form.region = tag;
          this.regionIds = [...this.regionIds, tag];
          break;
        default:
          break;
      }
      setTimeout(() => {
        this.$refs.multiselect.$refs.search.focus();
      }, 100);
    }
  },
  watch: {
    clusterList(newValue) {
      this.clusterIds = newValue;
    },
    regionList(newValue) {
      this.regionIds = newValue;
    },
    formatList(newValue) {
      this.formats = newValue;
    },
    store(_newValue, _oldValue) {
      // Assigning loaded user to form
      const { cluster, region, format, storeName, storeid } = this.store;
      this.form.storeName = storeName;
      this.form.type = this.store.type;
      this.form.storeid = storeid;
      this.form.cluster = { text: cluster, value: cluster };
      this.form.region = { text: region, value: region };
      this.form.format = { text: format, value: format };
      this.pincodes = this.store.pincodes;
      this.formLoaded = true;
      this.locationId = this.store?.locations?.locationId;
      this.lat = this.store?.locations?.lat;
      this.long = this.store?.locations?.long;
    }
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
