<template>
  <div class="body">
    <div class="nav_main_div">
      <div class="nav_bar_div">
        <nav class="navbar navbar-light bg-light">
          <button @click="goBack" class="back_btn">
            <font-awesome-icon :icon="['fas', 'angle-double-left']" class="mr-1 arrow_icon" />Back</button
          >
          <span style="margin-right: 50%; color: #383838" class="navbar-brand h1">Order History</span>
        </nav>
      </div>
    </div>
    <div>
      <div class="body_div">
        <b-card class="product_card" v-for="order in this.orders" :key="order.id">
          <b-row>
            <b-col cols="3">
              <h5 style="color: blueviolet">Products</h5>
              <span>{{
                order.products
                  .join(',')
                  .split(':::')
                  .join('  X  ')
              }}</span>
            </b-col>
            <b-col cols="3">
              <h5 style="color: blueviolet"> Payment Mode</h5>
              <span>{{ order.modeOfPayment }}</span>
            </b-col>
            <b-col cols="3">
              <h5 style="color: blueviolet">Order Date</h5>
              <span>{{ moment(order.createdAt).format('DD-MMM-YY hh:mm  A') }}</span>
            </b-col>
            <b-col cols="3">
              <h5 style="color: blueviolet">Store Name</h5>
              <span>{{ order.storeName }}</span>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import Vue from 'vue';

Vue.prototype.moment = moment;
export default {
  components: {},
  data() {
    return {
      orders: []
    };
  },
  mounted() {
    const orders = localStorage.getItem('orders') || '{}';
    this.orders = JSON.parse(orders);
  },
  computed: {
    ...mapGetters('alert', ['errorMessages']),
    ...mapState('product', ['loading'])
  },
  methods: {
    ...mapActions('product', ['listOrders']),
    goBack() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
/* Navbar styling  */
.body {
  margin-top: -4.5rem;
  font-family: Myriad;
}
.nav_main_div {
  width: 100vw;
  margin-left: -1rem;
  margin-bottom: 1rem;
}

.nav_bar_div {
  top: 0;
  z-index: 1;
  width: 100%;
  position: fixed;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

/* .navbar {

} */

.back_btn {
  width: 6rem;
  padding: 3px;
  border: none;
  outline: none;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  color: #383838;
  border-radius: 3px;
  background-color: #fff;
}

.back_btn:hover {
  background-color: #dcdcdc;
}

.arrow_icon {
  margin-right: 10px !important;
}

.navbar > span {
  font-size: 20px;
  font-weight: 600;
}

/* Body Styling  */
.body_div {
  margin-top: 6rem;
}

.body_div > h4 {
  color: #383838;
  font-weight: 500;
  margin-left: 20rem;
  margin-right: 20rem;
  text-align: center;
}

/* Product Card Styling */
.product_card {
  width: 100%;
  margin: auto;
  border: none;
  margin-bottom: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  font-weight: 500;
}

.content_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.left_div {
  width: 15%;
}

.paymentMode {
  width: 15%;
  margin-left: 21%;
}

.middle_div {
  margin-right: 7rem;
}

.productName {
  font-size: 14px;
  font-weight: 500;
  margin: 7px 0 10px 0;
}

.quantity_btn_div {
  display: flex;
  width: 30px;
}

.quantity_btn_div > span {
  font-weight: 600;
  font-size: 16px;
  margin: 0.2rem;
}

.cart_plus_btn {
  width: 40px;
  outline: none;
  color: #f28a3f;
  border-radius: 50%;
  background-color: #fff;
  border: 1.5px solid #f28a3f;
}

.cart_plus_btn:hover {
  color: #fff;
  background-color: #f28a3f;
}

.cart_minus_btn {
  width: 40px;
  outline: none;
  color: #f28a3f;
  border-radius: 50%;
  background-color: #fff;
  border: 1.5px solid #f28a3f;
}

.cart_minus_btn:hover {
  color: #fff;
  background-color: #f28a3f;
}

.right_div {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  margin-left: -14%;
}

@media only screen and (max-width: 349px) {
  .back_btn {
    width: 5rem;
    font-size: 12px;
    font-weight: 500;
  }
  .navbar > span {
    font-size: 12px;
    margin-top: 11px;
    font-weight: 500;
  }

  .left_div {
    width: 20%;
  }

  .paymentMode {
    width: 20%;
  }

  .middle_div {
    margin-right: 2rem;
  }

  .right_div {
    width: 22%;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    margin: 13px 0px 0px 0px;
  }
}

@media only screen and (max-width: 540px) and (min-width: 350px) {
  .left_div {
    width: 37%;
  }
  .paymentMode {
    width: 20%;
  }

  .right_div {
    font-size: 15px;
    font-weight: 500;
  }

  .middle_div {
    margin-right: 3rem;
    margin-left: 5%;
  }

  .back_btn {
    font-size: 15px;
  }

  .navbar > span {
    font-size: 15px;
    margin-top: 5px;
    font-weight: 600;
  }
}

@media screen and (max-width: 768px) {
  .body_div > h4 {
    margin-left: 0;
    margin-right: 0;
  }

  .product_card {
    width: 100%;
    font-weight: 400;
  }

  .total_card {
    width: 100%;
  }

  .billing_card {
    width: 100%;
  }

  .confirm_order_div {
    left: 2%;
    width: 96%;
  }
}

@media only screen and (max-width: 1170px) and (min-width: 769px) {
  .left_div {
    width: 20%;
    margin-top: 6px;
  }

  .right_div {
    font-size: 15px;
    font-weight: 500;
  }

  .middle_div {
    margin-right: 4rem;
  }
}
</style>
