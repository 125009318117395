<template>
  <div class="page-class page-user-list">
    <h1 class="page-title">{{ title }}</h1>
    <table-box-order
      :columns="columnsToShow"
      :rows="this.isOnlineData ? ecomOrderList : order"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="true"
      :showDownload="true && !ifCallCenterExecutive()"
      :showUpload="false"
      :showToolbar="true"
      :showPagination="true"
      :isOfflineOrder="true"
      :showDownloadMessageReport="true && !ifCallCenterExecutive()"
      :showDownloadAptReport="true && !ifCallCenterExecutive()"
      :pagination="pagination"
      :filterOptions="filterOptions"
      :isTabsRequired="handleTabsRequired"
      :searchItems="searchItems"
      addText="Create new order"
      downloadText="Download Orders List"
      uploadText="Upload Orders List"
      emptyText="No order found. Please add new order."
      downloadMessageReportText="Download Message Report"
      downloadAptText="Download Apt-Abt-Adt Report"
      @add="onAdd"
      @edit="onEdit"
      @acknowledge="onAcknowledge"
      @delete="onDelete"
      @download="onExportCSV"
      @download-message-report="onExportMessageCSV"
      @show-upload="onShowUpload"
      @apt-report="onAptReport"
      @fetch-offline="onFetchOffline"
    >
      <template v-slot:emailAddressFormatted="slotProps">
        <span class="span-text span-block">
          {{ slotProps.row.email }}
        </span>
        <template v-if="slotProps.row.confirmedAtFormatted">
          <span class="text-success span-help-text" :title="slotProps.row.confirmedAt">
            ({{ slotProps.row.confirmedAtFormatted }})
          </span>
        </template>
        <template v-if="slotProps.row.confirmedAtFormatted === null">
          <span class="text-warning span-help-text"> (Not confirmed) </span>
        </template>
      </template>
      <template v-slot:lastLoginAtFormatted="slotProps">
        <template v-if="slotProps.row.lastLoginAtFormatted">
          <span class="text-success">{{ slotProps.row.lastLoginAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.lastLoginAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
      <template v-slot:blockedAtFormatted="slotProps">
        <template v-if="slotProps.row.blockedAtFormatted">
          <span class="text-success">{{ slotProps.row.blockedAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.blockedAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
    </table-box-order>
    <div>
      <b-modal ref="order-Modal" id="modal-md" size="md" title="Store Authentication">
        <b-form data-v-step="2" inline class="float-left">
          <b-row>
            <b-col cols="12" class="d-flex justify-content-between align-items-center">
              <b-form-group id="group-products" label-for="input-products">
                <template v-slot:label> Store Id <span class="text-danger">*</span> </template>
                <multiselect
                  id="store-code"
                  v-model="selectedStoreId"
                  ref="multiselect"
                  placeholder="Select Store Ids"
                  :options="storeOptions"
                  class="ml-2 mb-2"
                  style="display: inline-block;"
                ></multiselect>
              </b-form-group>

              <p v-if="isOnlineStoreSelected" class="bpx-2 py-1 text-success rounded-lg d-flex align-items-center">
                Online Store
              </p>
            </b-col>
            <b-col cols="12" v-if="isOnlineStoreSelected">
              <b-form-group id="group-products" label-for="input-products">
                <label for="pin-code" style="display: inline-block;">Pin Code:</label>
                <b-form-input
                  id="pin-code"
                  placeholder="Enter Pin Code"
                  v-model="pinCode"
                  :formatter="limit"
                  class="ml-2 mb-2"
                  style="display: inline-block;"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <div class="m-2 text-danger" v-if="errorMessage !== undefined && errorMessage !== ''">
              **{{ errorMessage }}
            </div>
          </b-row>
        </b-form>
        <div slot="modal-footer" class="w-100 d-flex justify-content-end">
          <b-button variant="danger" class="ml-2 mb-2" :disabled="loading" @click="proceed()">
            <span class="spinner spinner-white" v-if="loading"></span>
            Proceed</b-button
          >
          <b-button variant="secondary" class="ml-2 mb-2" @click="handleCancel">Cancel</b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import _ from 'lodash';
import Multiselect from 'vue-multiselect';
import TableBoxOrder from '../../components/TableBoxOrder.vue';
import User from '../../model/user';

export default {
  name: 'OrderList',
  components: {
    TableBoxOrder,
    Multiselect
  },
  metaInfo() {
    return {
      title: 'Orders',
      meta: [
        {
          name: 'description',
          content: `Manage orders`
        }
      ]
    };
  },
  mounted() {
    this.listStoreDetail({ router });
    if (_.isEmpty(this.$route.query)) {
      this.list({
        query: {
          ...this.$route.query,
          startdate: moment().format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.list({ query: this.$route.query });
    }
  },

  beforeRouteUpdate(to, from, next) {
    this.list({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Orders',
      searchItems: ['Customer Mobile', 'Name', 'Address', 'Store Id'],
      isOnlineStoreSelected: false,
      storeOptions: [],
      selectedStoreId: '',
      selectedStoreObj: {},
      isSingleStore: false,
      pinCode: '',
      isOnlineData: false,
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'Source-SINGLE_FORM_ENTRY', text: 'Source-SINGLE_FORM_ENTRY' },
        { value: 'Source-CALL_CENTER_ENTRY', text: 'Source-CALL_CENTER_ENTRY' },
        { value: 'Source-BULK_CSV_UPLOAD', text: 'Source-BULK_CSV_UPLOAD' },
        // { value: 'Source-OUTBOUND_APP_ENTRY', text: 'Source-OUTBOUND_APP_ENTRY' },
        { value: 'Source-B2B_ENTRY', text: 'Source-B2B_ENTRY' },
        { value: 'Source-ORDERING_APP_ENTRY', text: 'Source-ORDERING_APP_ENTRY' },
        { value: 'Source-DIRECT_POS_ENTRY', text: 'Source-DIRECT_POS_ENTRY' },
        { value: 'Acknowledged-True', text: 'Acknowledged-True' },
        { value: 'Acknowledged-False', text: 'Acknowledged-False' },
        { value: 'Delivered-True', text: 'Delivered-True' },
        { value: 'Delivered-False', text: 'Delivered-False' }
      ]
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('auth', {
      authUser: 'user'
    }),
    ...mapState('order', [
      'loading',
      'baseUrl',
      'orders',
      'pagination',
      'downloadOrdersList',
      'downloadMessageRecordList',
      'downloadAptReportList',
      'errorMessage',
      'ecomOrderList'
    ]),
    ...mapState('store', ['storeList', 'storeListDetail']),
    handleTabsRequired() {
      const storeIds = ['H049'];
      const logic = storeIds.some(store => this.user.storeids.includes(store));
      if (logic) {
        return true;
      }
      return false;
    },
    order() {
      return this.orders.map(i => {
        let status = '';
        if (i.actualDeliveredTime) {
          status = `Order Delivered: ${i.actualDeliveredTime}`;
        } else if (i.packagedTime) {
          status = `Order Packaged: ${i.packagedTime}`;
        } else if (i.posPingTime) {
          status = `Bill Generated:  ${i.posPingTime}`;
        } else if (i.acknowledgedTime) {
          status = `Acknowledged Time:  ${i.acknowledgedTime}`;
        } else {
          status = `Order Created: ${i.createdAt}`;
        }

        return {
          ...i,
          status
        };
      });
    },
    columnsToShow() {
      const newUser = this.authUser.roles.map(role => role.name);
      const admin = newUser.filter(role => role.includes('ADMIN'));
      if (admin.length > 0) {
        return [
          {
            type: 'row_num',
            headerText: '#',
            class: { 'text-center': true },
            textKey: 'id',
            width: '5%'
          },
          {
            type: 'string',
            headerText: 'Customer Mobile',
            class: { 'text-center': true },
            textKey: 'customerMobile',
            width: '8%'
          },
          {
            type: 'string',
            headerText: 'Store Id',
            class: { 'text-center': true },
            textKey: 'storeid',
            width: '4%'
          },
          {
            type: 'string',
            headerText: 'Name',
            class: { 'text-center': true },
            textKey: 'name',
            width: '7%'
          },
          {
            type: 'string',
            headerText: 'Products',
            class: { 'text-center': true },
            textKey: 'products',
            width: '8%'
          },
          {
            type: 'string',
            headerText: 'Address',
            class: { 'text-center': true },
            textKey: 'address',
            width: '12%'
          },
          {
            type: 'string',
            headerText: 'Description',
            class: { 'text-center': true },
            textKey: 'description',
            width: '7%'
          },
          {
            type: 'string',
            headerText: 'Payment Method',
            class: { 'text-center': true },
            htmlKey: 'modeOfPayment',
            width: '6%'
          },
          {
            type: 'string',
            headerText: 'Order Status',
            class: { 'text-center': true },
            htmlKey: 'status',
            width: '8%'
          },
          {
            type: 'string',
            headerText: 'Acknowledged ',
            class: { 'text-center': true },
            textKey: 'isAcknowledged',
            width: '6%'
          },
          {
            type: 'string',
            headerText: 'Delivered',
            class: { 'text-center': true },
            textKey: 'isDelivered',
            width: '5%'
          },
          {
            type: 'string',
            headerText: 'Source',
            class: { 'text-center': true },
            textKey: 'source',
            width: '7%'
          },

          {
            type: 'string',
            headerText: 'Delivery code',
            class: { 'text-center': true },
            textKey: 'deliveryCode',
            width: '5%'
          },
          {
            type: 'functions',
            headerText: 'Functions',
            class: { 'text-center': true },
            width: '12%',
            functions: {
              edit: true,
              delete: true,
              ack: false
            }
          }
        ];
      }
      return [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'id',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Customer Mobile',
          class: { 'text-center': true },
          textKey: 'customerMobile',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'Name',
          class: { 'text-center': true },
          textKey: 'name',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Products',
          class: { 'text-center': true },
          textKey: 'products',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Address',
          class: { 'text-center': true },
          textKey: 'address',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Description',
          class: { 'text-center': true },
          textKey: 'description',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Payment Method',
          class: { 'text-center': true },
          htmlKey: 'modeOfPayment',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Order Status',
          class: { 'text-center': true },
          htmlKey: 'status',
          width: '12%'
        },
        {
          type: 'string',
          headerText: 'Acknowledged ',
          class: { 'text-center': true },
          textKey: 'isAcknowledged',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Delivered',
          class: { 'text-center': true },
          textKey: 'isDelivered',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Source',
          class: { 'text-center': true },
          textKey: 'source',
          width: '7%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '13%',
          functions: {
            edit: true,
            delete: true,
            ack: false
          }
        }
      ];
    },
    handleDisabled() {
      return this.selectedStoreId === '' || (this.isOnlineStoreSelected && this.pinCode.length < 6);
    }
  },
  watch: {
    downloadOrdersList(newValue) {
      if (newValue.length) {
        let csv =
          'Order Id,Customer Name,Customer Address,Source,Customer Mobile,Created On,Updated On,Description,Store Id,Acknowledged,Delivered,Products,Order Created Time,order Acknowledged Time,Order Packaged Time ,Bill Generated Time,Order Delivered Time,Order Mode, Pincode,Bill Number,Bill Value\n';
        newValue.forEach(row => {
          // const createdLocation =
          //   row.orderCreateLocation.lat && row.orderCreateLocation.long
          //     ? `https://maps.google.com/?q=${row.orderCreateLocation.lat},${row.orderCreateLocation.long}`
          //     : null;

          // const deliverLocation =
          //   row.orderDeliveredLocation.lat && row.orderDeliveredLocation.long
          //     ? `https://maps.google.com/?q=${row.orderDeliveredLocation.lat},${row.orderDeliveredLocation.long}`
          //     : null;

          // // get distance from created location to delivered location in km
          // function toRad(Value) {
          //   return (Value * Math.PI) / 180;
          // }
          // if (
          //   row.orderCreateLocation.lat != null &&
          //   row.orderCreateLocation.long != null &&
          //   row.orderDeliveredLocation.lat != null &&
          //   row.orderDeliveredLocation.long != null
          // ) {
          //   const R = 6371; // Radius of the earth in km
          //   const dLat = toRad(row.orderDeliveredLocation.lat - row.orderCreateLocation.lat); // Javascript functions in radians
          //   const dLon = toRad(row.orderDeliveredLocation.long - row.orderCreateLocation.long);
          //   const lat1 = toRad(row.orderCreateLocation.lat);
          //   const lat2 = toRad(row.orderDeliveredLocation.lat);

          //   const a =
          //     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          //     Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);

          //   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
          //   const d = R * c; // Distance in km
          //   distance = d.toFixed(2);
          // } else {
          //   distance = '-';
          // }

          // const totalDistance = distance === 0.0 ? `${0}` : distance === undefined ? '-' : `${distance}`; //eslint-disable-line
          // const formattedDistance = totalDistance != '-' ? `${totalDistance} km` : totalDistance; //eslint-disable-line
          csv += `"${row.orderId}","${row.name}","${row.address}","${row.source}","${row.customerMobile}","${row.dateCreated}","${row.dateUpdated}","${row.description}","${row.storeid}","${row.isAcknowledged}","${row.isDelivered}","${row.products}","${row.createdAt}","${row.acknowledgedTime}","${row.packagedTime}","${row.posPingTime}","${row.actualDeliveredTime}","${row.orderMode}","${row.pinCode}","${row.billNumber}","${row.posItems}"\n`;
        });

        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'Orders.csv';
        anchor.click();
      }
    },

    selectedStoreId(newValue, oldValue) {
      if (newValue !== oldValue) {
        const filteredStore = this.storeListDetail.filter(store => store.storeid === newValue)[0];
        this.selectedStoreObj = filteredStore;
        if (filteredStore?.isOnline) {
          this.isOnlineStoreSelected = true;
        } else {
          this.isOnlineStoreSelected = false;
        }
      }
    },
    downloadMessageRecordList(newValue) {
      if (newValue.length) {
        let csv = 'Row Number,Mobile,Medium,Type,Delivered,Store Id,Ref Id,Created At\n';
        newValue.forEach(row => {
          Object.values(row).forEach(val => {
            csv += `"${val}"`;
            csv += ',';
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'MessageLogs.csv';
        anchor.click();
      }
    },
    downloadAptReportList(newValue) {
      if (newValue.length) {
        let csv = '';
        csv += `,,Date: ${this.$route.query.startdate} to ${this.$route.query.enddate}\n`;
        csv += 'Region,App entry,Total orders,Total Delivered,Avg APT,ABT ,ADT ,App use %, DC use %\n';
        const regions = ['East', 'North', 'South'];
        let indiaAppEntry = [];
        let indiaTotalOrder = [];
        let indiaTotalDelivered = [];
        let indiaAppUse = [];
        let indiaDcUse = [];
        regions.forEach(region => {
          const regionData = newValue.filter(row => row.storeRegion === region);
          const appEntry = regionData.reduce((acc, row) => acc + row.orderAppEntry, 0);
          const totalOrders = regionData.reduce((acc, row) => acc + row.totalOrders, 0);
          const totalIsDelivered = regionData.reduce((acc, row) => acc + row.isDelivered, 0);

          const totalAverageApt = this.totalAverageTimeConverter(
            regionData.reduce((acc, row) => acc + row.apt, 0),
            regionData.length
          );
          const totalAverageAbt = this.totalAverageTimeConverter(
            regionData.reduce((acc, row) => acc + row.abt, 0),
            regionData.length
          );
          const totalAverageAdt = this.totalAverageTimeConverter(
            regionData.reduce((acc, row) => acc + row.adt, 0),
            regionData.length
          );

          const avgDcUse = ((totalIsDelivered / totalOrders) * 100).toFixed(2);
          const avgAppUse = ((appEntry / totalOrders) * 100).toFixed(2);
          indiaAppEntry.push(appEntry);
          indiaTotalOrder.push(totalOrders);
          indiaTotalDelivered.push(totalIsDelivered);
          indiaAppUse.push(avgAppUse);
          indiaDcUse.push(avgDcUse);
          csv += `${region},${appEntry},${totalOrders},${totalIsDelivered},${totalAverageApt},${totalAverageAbt},${totalAverageAdt},${avgAppUse},${avgDcUse}\n`;
        });

        //  +++++++++++++++++++++++++++++++ India Calculation +++++++++++++++++++++++++++++++++++++++++++++++++++++++
        indiaAppEntry = indiaAppEntry.reduce((acc, curr) => acc + Number(curr), 0);
        indiaTotalOrder = indiaTotalOrder.reduce((acc, curr) => acc + Number(curr), 0);
        indiaTotalDelivered = indiaTotalDelivered.reduce((acc, curr) => acc + Number(curr), 0);
        indiaAppUse = ((indiaAppEntry / indiaTotalOrder) * 100).toFixed(2);
        indiaDcUse = ((indiaTotalDelivered / indiaTotalOrder) * 100).toFixed(2);
        // +++++++++++++++++++++++++++++++++ India store-wise +++++++++++++++++++++++
        const regionLength = newValue.filter(
          row => row.storeRegion !== null && row.apt !== null && row.abt !== null && row.adt !== null
        ).length;
        const indiaTotalAptSecondsByStore = regions.reduce((acc, region) => {
          const regionData = newValue.filter(row => row.storeRegion === region && row.apt !== null);
          return acc + regionData.reduce((acc, row) => acc + row.apt, 0);
        }, 0);
        const indiaTotalAbtSecondsByStore = regions.reduce((acc, region) => {
          const regionData = newValue.filter(row => row.storeRegion === region && row.abt !== null);
          return acc + regionData.reduce((acc, row) => acc + row.abt, 0);
        }, 0);
        const indiaTotalAverageAdtByStore = regions.reduce((acc, region) => {
          const regionData = newValue.filter(row => row.storeRegion === region && row.adt !== null);
          return acc + regionData.reduce((acc, row) => acc + row.adt, 0);
        }, 0);
        const indiaApt = this.totalAverageTimeConverter(indiaTotalAptSecondsByStore, regionLength);
        const indiaAbt = this.totalAverageTimeConverter(indiaTotalAbtSecondsByStore, regionLength);
        const indiaAdt = this.totalAverageTimeConverter(indiaTotalAverageAdtByStore, regionLength);
        csv += `India,${indiaAppEntry},${indiaTotalOrder},${indiaTotalDelivered},${indiaApt},${indiaAbt},${indiaAdt},${indiaAppUse},${indiaDcUse}`;
        csv += '\n\n';

        csv += ',ASM Wise Report\n';
        csv += `Region,ASM Name,App entry,total orders,total delivered,Avg APT, Avg Abt,Avg Adt,App use %, DC use %\n`;

        regions.forEach(region => {
          const regionData = newValue.filter(row => row.storeRegion === region);
          const asmList = [...new Set(regionData.map(row => row.asm))]; // Get unique ASM names within the region
          asmList.forEach(asm => {
            const asmData = regionData.filter(row => row.asm === asm);
            const appEntry = asmData.reduce((acc, row) => acc + row.orderAppEntry, 0);
            const totalOrders = asmData.reduce((acc, row) => acc + row.totalOrders, 0);
            const totalIsDelivered = asmData.reduce((acc, row) => acc + row.isDelivered, 0);
            const totalAbtSeconds = asmData.reduce((acc, row) => acc + row.abt, 0);
            const totalAdtSeconds = asmData.reduce((acc, row) => acc + row.adt, 0);
            const totalAverageApt = this.totalAverageTimeConverter(
              asmData.reduce((acc, row) => acc + row.apt, 0),
              asmData.length
            );
            const totalAverageAbt = this.totalAverageTimeConverter(totalAbtSeconds, asmData.length);
            const totalAverageAdt = this.totalAverageTimeConverter(totalAdtSeconds, asmData.length);
            const avgAppUse = ((appEntry / totalOrders) * 100).toFixed(2);
            const avgDcUse = ((totalIsDelivered / totalOrders) * 100).toFixed(2);
            csv += `${region},${asm ||
              '-'},${appEntry},${totalOrders},${totalIsDelivered},${totalAverageApt},${totalAverageAbt},${totalAverageAdt},${avgAppUse},${avgDcUse}\n`;
          });
        });
        // =======================================================================================================================
        // csv += 'Asm Wise\n';
        // csv += 'ASM,App entry,total orders,total delivered,Avg APT, Avg Abt,Avg Adt,App use %, DC use %\n';
        // const asmList = [...new Set(newValue.map(row => row.asm))];

        // asmList.forEach(asm => {
        //   const asmData = newValue.filter(row => row.asm === asm);
        //   const appEntry = asmData.reduce((acc, row) => acc + row.orderAppEntry, 0);
        //   const totalOrders = asmData.reduce((acc, row) => acc + row.totalOrders, 0);
        //   const totalIsDelivered = asmData.reduce((acc, row) => acc + row.isDelivered, 0);
        //   const totalAbtSeconds = asmData.reduce((acc, row) => acc + row.abt, 0);
        //   const totalAdtSeconds = asmData.reduce((acc, row) => acc + row.adt, 0);
        //   const totalAverageApt = this.totalAverageTimeConverter(
        //     asmData.reduce((acc, row) => acc + row.apt, 0),
        //     asmData.length
        //   );
        //   const totalAverageAbt = this.totalAverageTimeConverter(totalAbtSeconds, asmData.length);
        //   const totalAverageAdt = this.totalAverageTimeConverter(totalAdtSeconds, asmData.length);
        //   const avgAppUse = (asmData.reduce((acc, row) => acc + row.appUasgePercentage, 0) / asmData.length).toFixed(2);
        //   const avgDcUse = (asmData.reduce((acc, row) => acc + row.dcUsagePercentage, 0) / asmData.length).toFixed(2);
        //   csv += `${asm},${appEntry},${totalOrders},${totalIsDelivered},${totalAverageApt},${totalAverageAbt},${totalAverageAdt},${avgAppUse},${avgDcUse}\n`;
        // });

        // ========================================================================
        csv += '\n\n';
        csv +=
          'Store id,Store Name,Cluster,Region,Format,APT,ABT,ADT,Order App Entry,Total Orders,Is Delivered,App use %,Dc use %,ASM\n';
        newValue.forEach(row => {
          Object.values(row).forEach((val, index) => {
            if (index !== 5 && index !== 6 && index !== 7 && index !== 11 && index !== 12) {
              csv += `"${val}"`;
              csv += ',';
            } else if (index === 11 || index === 12) {
              csv += `"${val.toFixed(1)}"`;
              csv += ',';
            } else {
              csv += `"${this.milliSecondsToHours(val)}"`;
              csv += ',';
            }
          });
          csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'APT-ABT-ADT Report.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('order', [
      'list',
      'deleteOne',
      'upload',
      'sendAcknowledgement',
      'download',
      'downloadMessageRecord',
      'downloadAptReport',
      'getStoreAvailability',
      'setStorePinCode',
      'ecommOrderList'
    ]),
    ...mapActions('store', ['listStore', 'listStoreDetail']),
    onFetchOffline() {
      this.isOnlineData = false;
      this.list({
        query: {
          ...this.$route.query
        }
      });
    },

    ifCallCenterExecutive() {
      const newUser = this.user.roles.map(role => role.name);
      const CallCenter = newUser.filter(role => role.includes('CALL_CENTER_EXECUTIVE'));
      if (CallCenter.length > 0) {
        return true;
      }
      return false;
    },

    limit(e) {
      return e.slice(0, 6);
    },
    async onAdd() {
      // More that one store
      if (this.user?.storeids?.length > 1) {
        this.storeOptions = this.user.storeids;
        this.$refs['order-Modal'].show();
      } else {
        // single store
        this.selectedStoreId = this.user?.storeids[0];

        const storeAB = this.storeListDetail.filter(store => store.storeid === this.selectedStoreId)[0];
        if (storeAB?.isOnline) {
          this.$refs['order-Modal'].show();
        } else {
          this.$router.push(`/order/new/${null}`);
        }
      }
    },
    handleCancel() {
      this.$refs['order-Modal'].hide();
    },
    proceed() {
      if (this.selectedStoreObj.isOnline) {
        const storeid = this.selectedStoreId?.toLowerCase();
        const pincode = this.pinCode;

        this.getStoreAvailability({ storeid, pincode, router });
        this.setStorePinCode({ storeid, pincode });
        // this.$refs['order-Modal'].hide();
      } else {
        this.$router.push(`/order/new/${null}`);
      }
    },
    onEdit({ row }) {
      if (row.orderIncrementId !== undefined && row.orderIncrementId !== '') {
        router.push(`/orderFormBoxV2/${row.orderId}`);
      } else {
        router.push(`/order/${row.orderId}`);
      }
    },

    calculateAverageTime(timeArray) {
      const totalSeconds = timeArray.reduce((acc, time) => {
        const [hours, minutes, seconds] = time.split(':').map(Number);
        return acc + hours * 3600 + minutes * 60 + seconds;
      }, 0);
      const averageSeconds = totalSeconds / timeArray.length;
      const hours = Math.floor(averageSeconds / 3600);
      const minutes = Math.floor((averageSeconds % 3600) / 60);
      const seconds = Math.floor(averageSeconds % 60);
      const timeReturn = t => {
        return String(t).padStart(2, '0');
      };
      return `${timeReturn(hours)}:${timeReturn(minutes)}:${timeReturn(seconds)}`;
    },
    totalAverageTimeConverter(totalSeconds, length) {
      const averageSeconds = totalSeconds / length;
      const seconds = Math.floor((averageSeconds / 1000) % 60);
      const minutes = Math.floor((averageSeconds / (1000 * 60)) % 60);
      const hours = Math.floor(averageSeconds / (1000 * 60 * 60));
      const timeReturn = t => {
        return String(t).padStart(2, '0');
      };
      return `${timeReturn(hours)}:${timeReturn(minutes)}:${timeReturn(seconds)}`;
    },
    milliSecondsToHours(totalSeconds) {
      if (totalSeconds < 0) {
        return '-';
      }
      const averageSeconds = totalSeconds;
      const seconds = Math.floor((averageSeconds / 1000) % 60);
      const minutes = Math.floor((averageSeconds / (1000 * 60)) % 60);
      const hours = Math.floor(averageSeconds / (1000 * 60 * 60));
      const timeReturn = t => {
        return String(t).padStart(2, '0');
      };
      return `${timeReturn(hours)}:${timeReturn(minutes)}:${timeReturn(seconds)}`;
    },
    async onShowUpload() {
      let footer =
        '<a href="https://spencer-template.s3.ap-south-1.amazonaws.com/Orders.csv" >Download sample upload file</a>';
      if (this.user.role === User.userRole.administrator) {
        footer =
          '<a href="https://spencer-template.s3.ap-south-1.amazonaws.com/OrdersAdmin.csv" >Download sample upload file</a>';
      }
      const { value: orderFile } = await Vue.swal({
        title: 'Upload file for orders..',
        input: 'file',
        inputAttributes: {
          'aria-label': 'Upload your orders file in csv format'
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Upload',
        showCancelButton: true,
        cancelButtonText: 'Close',
        footer
      });
      if (orderFile) {
        const reader = new FileReader();
        reader.readAsText(orderFile);
        reader.onload = e => {
          this.upload({ data: e.target.result, router });
        };
      }
    },

    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query, page_size: 5000, page: 1 } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            page_size: 5000,
            page: 1,
            startdate: moment()
              .subtract(1, 'months')
              .format('MMM YYYY')
          }
        });
      }
    },
    onExportMessageCSV() {
      this.downloadMessageRecord({ query: {} });
    },

    onAptReport() {
      const currentDate = new Date().setHours(0, 0, 0, 0);
      const selectedStartDate = new Date(this.$route.query.startdate).setHours(0, 0, 0, 0);
      const selectedEndDate = new Date(this.$route.query.enddate).setHours(0, 0, 0, 0);

      if (selectedStartDate === currentDate || selectedEndDate === currentDate) {
        Vue.swal({
          title: 'Wrong Date Selected',
          text: 'Please select date other than today.',
          icon: 'error',
          confirmButtonColor: '#3085d6'
        });
        return;
      }

      this.downloadAptReport({
        query: {
          startdate: this.$route.query.startdate,
          enddate: this.$route.query.enddate
        }
      });
    },
    onDelete({ row }) {
      if (!this.user.storeids.includes(row.storeid)) {
        Vue.swal({
          title: 'Sorry, you can not delete an order of other store..',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Okay'
        });
      } else {
        Vue.swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this.",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',

          preConfirm: () => {
            this.deleteOne({
              id: row.orderId,
              sort: true
            });
          }
        });
      }
    },
    onAcknowledge({ row }) {
      if (row.isAcknowledged) {
        Vue.swal({
          title: 'Are you sure?',
          text: 'The user has been already acknowledged. Do you want to send messgae again ?',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Send message again!',

          preConfirm: () => {
            this.sendAcknowledgement({
              id: row.orderId,
              mobile: row.customerMobile,
              type: 'ORDER_ACKNOWLEDGEMENT',
              query: this.$route.query,
              router
            });
          }
        });
      } else {
        Vue.swal({
          title: 'Are you sure?',
          text: 'Do you want to do send acknowledge sms for this order ?',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirm',

          preConfirm: () => {
            this.sendAcknowledgement({
              id: row.orderId,
              mobile: row.customerMobile,
              type: 'ORDER_ACKNOWLEDGEMENT',
              query: this.$route.query,
              router
            });
          }
        });
      }
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
