<template>
  <div class="page-class page-user-list">
    <h1 class="page-title">{{ title }}</h1>
    <store-box
      :columns="columns"
      :rows="storeListDetail"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="true"
      :showToolbar="true"
      :showDownload="true"
      addText="Create new Store"
      downloadText="Download List"
      emptyText="No Store found. Please add new Store."
      @add="onAdd"
      @edit="onEdit"
      @delete="onDelete"
      @download="onExportCSV"
    >
      <template v-slot:emailAddressFormatted="slotProps">
        <span class="span-text span-block">
          {{ slotProps.row.email }}
        </span>
        <template v-if="slotProps.row.confirmedAtFormatted">
          <span class="text-success span-help-text" :title="slotProps.row.confirmedAt">
            ({{ slotProps.row.confirmedAtFormatted }})
          </span>
        </template>
        <template v-if="slotProps.row.confirmedAtFormatted === null">
          <span class="text-warning span-help-text"> (Not confirmed) </span>
        </template>
      </template>
      <template v-slot:lastLoginAtFormatted="slotProps">
        <template v-if="slotProps.row.lastLoginAtFormatted">
          <span class="text-success">{{ slotProps.row.lastLoginAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.lastLoginAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
      <template v-slot:blockedAtFormatted="slotProps">
        <template v-if="slotProps.row.blockedAtFormatted">
          <span class="text-success">{{ slotProps.row.blockedAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.blockedAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
    </store-box>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import StoreBox from '@/components/StoreBox.vue';
import router from '@/router';
import Vue from 'vue';

export default {
  name: 'Store',
  components: { StoreBox },
  metaInfo() {
    return {
      title: 'Store',
      meta: [
        {
          name: 'description',
          content: `Manage Store`
        }
      ]
    };
  },
  mounted() {
    this.listStoreDetail({ router });
  },
  data() {
    return {
      title: 'Store List',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'index',
          width: '3%'
        },
        {
          type: 'string',
          headerText: 'StoreId',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Store Name',
          class: { 'text-center': true },
          textKey: 'storeName',
          width: '15%'
        },
        {
          type: 'string',
          headerText: 'Store Type',
          class: { 'text-center': true },
          textKey: 'typeName',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Format',
          class: { 'text-center': true },
          textKey: 'format',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Cluster',
          class: { 'text-center': true },
          textKey: 'cluster',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Region',
          class: { 'text-center': true },
          textKey: 'region',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Location',
          class: { 'text-center': true },
          textKey: 'locations',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Pincode Mapped',
          class: { 'text-center': true },
          textKey: 'pincodes',
          width: '15%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '15%',
          functions: {
            edit: true,
            delete: false
          }
        }
      ]
    };
  },
  computed: {
    ...mapState('store', ['loading', 'baseUrl', 'storeListDetail'])
  },
  methods: {
    ...mapActions('store', ['listStoreDetail', 'deleteOne']),
    onAdd() {
      router.push('/store/new');
    },
    onEdit({ row }) {
      router.push(`/store/${row.id}`);
    },
    onDelete({ row }) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',

        preConfirm: () => {
          this.deleteOne({
            type: 'store',
            id: row.id
          });
        }
      });
    },
    onExportCSV() {
      if (this.stores !== null && this.stores.length > 0) {
        let csv = `StoreId,Store Name,Store Type,Format,Cluster,Region,Pincode Mapped\n`;
        this.stores.forEach(row => {
          const pincodes = row.pincodes.split(',');
          csv += `${row.storeid},${row.storeName},${row.typeName},${row.format},${row.cluster},${row.region},"""${pincodes}"""\n`;
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'StoresList.csv';
        anchor.click();
      } else {
        Vue.swal({
          title: 'No data to export',
          type: 'warning'
        });
      }
    }
  },
  watch: {
    storeListDetail(newValue) {
      this.stores = newValue;
    }
  }
};
</script>
