<template>
  <div class="page-class page-user-list">
    <h1 class="page-title">{{ title }}</h1>
    <pos-report-box
      :columns="columns"
      :rows="posList"
      :baseUrl="baseUrl"
      :loading="loading"
      :showDownload="true"
      :showToolbar="true"
      :showPagination="true"
      :searchItems="searchItems"
      :filterOptions="filterOptions"
      :pagination="pagination"
      downloadText="Download Orders Report"
      emptyText="No orders found."
      @download="onExportCSV"
    >
      <template v-slot:emailAddressFormatted="slotProps">
        <span class="span-text span-block">
          {{ slotProps.row.email }}
        </span>
        <template v-if="slotProps.row.confirmedAtFormatted">
          <span class="text-success span-help-text" :title="slotProps.row.confirmedAt">
            ({{ slotProps.row.confirmedAtFormatted }})
          </span>
        </template>
        <template v-if="slotProps.row.confirmedAtFormatted === null">
          <span class="text-warning span-help-text"> (Not confirmed) </span>
        </template>
      </template>
      <template v-slot:lastLoginAtFormatted="slotProps">
        <template v-if="slotProps.row.lastLoginAtFormatted">
          <span class="text-success">{{ slotProps.row.lastLoginAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.lastLoginAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
      <template v-slot:blockedAtFormatted="slotProps">
        <template v-if="slotProps.row.blockedAtFormatted">
          <span class="text-success">{{ slotProps.row.blockedAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.blockedAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
    </pos-report-box>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import PosReportBox from '../../components/PosReportBox.vue';

export default {
  name: 'OrderList',
  components: {
    PosReportBox
  },
  metaInfo() {
    return {
      title: 'Orders Report',
      meta: [
        {
          name: 'description',
          content: `Manage Report`
        }
      ]
    };
  },
  mounted() {
    if (_.isEmpty(this.$route.query)) {
      this.list({
        query: {
          ...this.$route.query,
          startdate: moment().format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.list({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.list({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'POS  Details',
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { 'text-center': true },
          textKey: 'rowNum',
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'Customer Mobile',
          class: { 'text-center': true },
          textKey: 'customerMobile',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Bill Number',
          class: { 'text-center': true },
          textKey: 'billNumber',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Till Number',
          class: { 'text-center': true },
          textKey: 'tillNumber',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Bill Date',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'HSN Code',
          class: { 'text-center': true },
          textKey: 'articleCode',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Article Description',
          class: { 'text-center': true },
          textKey: 'articleDescription',
          width: '10%'
        },
        {
          type: 'string',
          headerText: 'Item Code',
          class: { 'text-center': true },
          textKey: 'itemCode',
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'Quantity',
          class: { 'text-center': true },
          htmlKey: 'quantity',
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'UOM ',
          class: { 'text-center': true },
          textKey: 'UOM',
          width: '4%'
        },
        // {
        //   type: 'string',
        //   headerText: 'Category',
        //   class: { 'text-center': true },
        //   textKey: 'itemCategory',
        //   width: '6%'
        // },
        {
          type: 'string',
          headerText: 'Bill Amount',
          class: { 'text-center': true },
          textKey: 'amount',
          width: '6%'
        },
        {
          type: 'string',
          headerText: 'Store Name',
          class: { 'text-center': true },
          textKey: 'storeName',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Store Code',
          class: { 'text-center': true },
          textKey: 'storeid',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'OTP Generated Time',
          class: { 'text-center': true },
          textKey: 'otpGeneratedTime',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'OTP Feed Time',
          class: { 'text-center': true },
          textKey: 'otpFeedTime',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Delivered',
          class: { 'text-center': true },
          textKey: 'delivered',
          width: '7%'
        }
      ],
      searchItems: ['Customer Mobile', 'Store Id'],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'Source-SINGLE_FORM_ENTRY', text: 'Source-SINGLE_FORM_ENTRY' },
        { value: 'Source-BULK_CSV_UPLOAD', text: 'Source-BULK_CSV_UPLOAD' },
        { value: 'Source-CATALOGUE_ENTRY', text: 'Source-CATALOGUE_ENTRY' },
        { value: 'Source-ORDERING_APP_ENTRY', text: 'Source-ORDERING_APP_ENTRY' },
        { value: 'Source-DIRECT_POS_ENTRY', text: 'Source-DIRECT_POS_ENTRY' }
      ]
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('orderPosMis', ['pagination', 'downloadPosMis', 'loading', 'baseUrl', 'posList'])
  },
  watch: {
    downloadPosMis(newValue) {
      let distance;
      if (newValue.length) {
        let csv =
          'Row Num,Customer Mobile,Bill Number,Till Number,Bill Date,HSN Code,Article Description,Item Code,Quantity,UOM,Category,Bill Amount,Coupon,Store Name,Store Code,OTP Generated Time, OTP Feed Time,Delivered,Order Created Location, Order Delivered Location, Distance  \n';
        newValue.forEach(row => {
          const createdLocation =
            row.orderCreateLocation.lat && row.orderCreateLocation.long
              ? `https://maps.google.com/?q=${row.orderCreateLocation.lat},${row.orderCreateLocation.long}`
              : null;

          const deliverLocation =
            row.orderDeliveredLocation.lat && row.orderDeliveredLocation.long
              ? `https://maps.google.com/?q=${row.orderDeliveredLocation.lat},${row.orderDeliveredLocation.long}`
              : null;

          // get distance from created location to delivered location in km
          function toRad(Value) {
            return (Value * Math.PI) / 180;
          }
          if (
            row.orderCreateLocation.lat != null &&
            row.orderCreateLocation.long != null &&
            row.orderDeliveredLocation.lat != null &&
            row.orderDeliveredLocation.long != null
          ) {
            const R = 6371; // Radius of the earth in km
            const dLat = toRad(row.orderDeliveredLocation.lat - row.orderCreateLocation.lat); // Javascript functions in radians
            const dLon = toRad(row.orderDeliveredLocation.long - row.orderCreateLocation.long);
            const lat1 = toRad(row.orderCreateLocation.lat);
            const lat2 = toRad(row.orderDeliveredLocation.lat);

            const a =
              Math.sin(dLat / 2) * Math.sin(dLat / 2) +
              Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);

            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const d = R * c; // Distance in km
            distance = d.toFixed(2);
          } else {
            distance = '-';
          }

          const totalDistance = distance === 0.0 ? `${0}` : distance === undefined ? '-' : `${distance}`; //eslint-disable-line
          const formattedDistance = totalDistance != '-' ? `${totalDistance} km` : totalDistance; //eslint-disable-line

          csv += `"${row.rowNum}","${row.customerMobile}","${row.billNumber}","${row.tillNumber}","${row.createdAt}","${row.posItems.hsnCode}","${row.articleDescription}","${row.posItems.itemCode}","${row.quantity}","${row.UOM}","${row.itemCategory}","${row.amount}","${row.coupon}","${row.storeName}","${row.storeid}","${row.otpGeneratedTime}","${row.otpFeedTime}","${row.delivered}","${createdLocation}","${deliverLocation}","${formattedDistance}" \n`;
          // Object.values(row).forEach((val, index) => {
          //   if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].includes(index)) {
          //     csv += `"${val}"`;
          //     csv += ',';
          //   }
          //   // if ([0].includes(index)) {
          //   //   Object.values(row).forEach(i => {
          //   //     if ([0].value === [0]) {
          //   //       csv += `"${i[0]}"`;
          //   //       csv += ',';
          //   //     }
          //   //   });
          //   // csv += `"${val}"`;
          //   // csv += ',';
          //   // }
          //   // csv += `"${val}"`;
          //   // csv += '\n';
          // });
          // csv += '\n';
        });
        const anchor = document.createElement('a');
        anchor.href = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
        anchor.target = '_blank';
        anchor.download = 'POSReport.csv';
        anchor.click();
      }
    }
  },
  methods: {
    ...mapActions('orderPosMis', ['list', 'download']),
    onExportCSV() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'startdate')) {
        this.download({ query: { ...this.$route.query, page_size: 5000, page: 1 } });
      } else {
        this.download({
          query: {
            ...this.$route.query,
            page_size: 5000,
            page: 1,
            startdate: moment().subtract(1, 'day').format('MMM YYYY')
          }
        });
      }
    },
    posData() {
      return this.posList.map(i => ({
        ...i,
        rowNum: i.indexOf(i) + 1
      }));
    }
  }
};
</script>
